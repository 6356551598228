import React, { useEffect, useState, useRef } from 'react';
/**외부 */
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';

/**customized mui */
import {IntroText, IntroBox, TitleBox, Title, ContentPaper} from '../../styles/svcPrvAgr/SvcPrvAgrStyle';

/**mui */
import {Paper, FormControlLabel} from '@mui/material';
import {List,  ListItemButton, ListItemText, MenuItem} from '@mui/material';
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {Checkbox, Divider, Alert, Button, Stack, Typography} from '@mui/material';

/**mui icon */
import { ExpandLess, ExpandMore } from '@mui/icons-material';

/**내부 */
import * as crm4u from '../../services/apis/crm4u/svcApi';
import '../../styles/svcPrvAgr/SvcPrvAgr.css';
import '../../styles/base.css';
import * as confStore from '../../stores/confStore';
import { userState } from '../../stores/UserState';
import { alertState, loadingState } from '../../stores/ComponentState';

function SvcPrvAgrInfo() { 

    const userInfo = useRecoilValue(userState);
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    const svcPrvAgr = confStore.prvAgr[userInfo.dealer_cd].versions.find(version=> version.ver_no === '0.1');
    const sigCanvas = useRef(null);
    const { register,
            handleSubmit,
            setValue,
            getValues
        } = useForm({
        defaultValue: {
            insertType : null,
            client_nm : null,
            phone_no1 : null,
            phone_no2 : null,
            phone_no3 : null,
            dealer_cd : null,
            seq : null,
            prv_agr_ver_no : null,
            hiddenpath : null,
            hiddenimg : null,
            all_agree_yn : null,
            agree_yn_01 : null,
            agree_yn_02 : null,
            agree_yn_03 : null,
            agree_yn_04 : null,
            agree_yn_05 : null,
            agree_yn_06 : null,
            agree_yn_07 : null,
            agree_yn_08 : null,
            agree_yn_09 : null,
        }
    }); 

    const [checkboxes, setCheckboxes] = useState({});
    const [dropdowns, setDropdowns] = useState({});
    // const [signatureData, setSignatureData] = useState('');
    // const [isSigned, setIsSigned] = useState(false);
    
    // 서명 초기화
    const clearCanvas = () => {
        sigCanvas.current.clear();
    };

    // 서명 저장
    const onSubmit = async (fieldValue) => {
        if(!window.confirm("개인정보동의서를 저장하시겠습니까?")) return;

        setLoadingInfo({isOpen:true});

        try {
            // 1. 필수 동의 항목 체크 검사
            const checkChecked = checkRequired();
            if(!checkChecked){
                alert('필수 동의 항목을 체크해주세요.');
                // Promise.reject('필수 동의 항목을 체크해주세요.');
                return false;
            }
            
            // 2. 서명 입력 검사
            const sigChecked = checkSignature();
            if(!sigChecked){
                alert('서명을 입력해주세요.');
                // Promise.reject('서명을 입력해주세요.');
                return false;
            }

            // 3. 서명 값 set
            setValue('hiddenimg',saveSignatureAsURL())
            setValue('hiddenpath',saveSignatureAsJSON())

            // 4. 체크 값 set
            let allAgreeYn = 'Y';
            Object.keys(checkboxes).forEach(k => {
                if(checkboxes[k].saveColumnNum){
                    let varString = 'agree_yn_' + checkboxes[k].saveColumnNum;
                    let agreeYn = checkboxes[k].checked? 'Y' : 'N';
                    setValue(varString, agreeYn);
                    if(agreeYn === 'N'){
                        allAgreeYn = 'N';
                    }
                }
            })
            setValue('all_agree_yn', allAgreeYn);

            // 5. 체크 및 서명 값 저장
            await crm4u.insertSvcPrvAgreement(fieldValue);

            console.log(fieldValue);
        } catch (err) {
            setAlertInfo({message :err, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    };

    // 필수값 검증
    const checkRequired = () => {
        let checkClear = true;
        const updatedCheckboxes = { ...checkboxes};
        Object.keys(updatedCheckboxes).forEach(k => {
            if(updatedCheckboxes[k].required === true && updatedCheckboxes[k].checked !== true){
                checkClear = false;
            }
        })
        return checkClear;
    } 

    const onError = (errors, e) =>  {
        alert("입력 값을 확인하세요.");
     }

    // 서명 빈값 검증
    const checkSignature = () => {
        return !sigCanvas.current.isEmpty();
    }

    // 서명 저장 - dataURL (Base64 인코딩 문자열)
    const saveSignatureAsURL = () => {
        const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        return dataURL;
    }

    // 서명 저장 - JSON (경로기반, 기존은 픽셀기반)
    const saveSignatureAsJSON = () => {
        const points = sigCanvas.current.toData();
        const sigDataFromImage = { lines: points };
        const json = JSON.stringify(sigDataFromImage);
        return json;
    };

    // 서명URL -> 파일 변환
    const convertDataUrlToFile = (name) => {
        const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const decodedURL = dataURL.replace(/^data:image\/\w+;base64,/, '');
        console.log(decodedURL);
        const buf = Buffer.from(decodedURL, 'base64');
        console.log(buf);
        const blob = new Blob([buf], { type: 'image/png' });
        console.log(blob);
        return new File([blob], `${name}.png`, { type: 'image/png' });
    };

    // 모두 동의 버튼 제어
    const allCheck = () => (event) => {
        const isChecked = event.target.checked;
        setCheckboxes(() => {
            // 본인 체크박스 값 set
            const updatedCheckboxes = { ...checkboxes};
            Object.keys(updatedCheckboxes).forEach(k => {
                if(k !== 0){
                    updatedCheckboxes[k] = { ...updatedCheckboxes[k], checked: isChecked}
                }
            })
            updatedCheckboxes[0] = {checked: isChecked };
            return updatedCheckboxes;
        })
    }

    const handleCheck = (key, parentId) => (event) => {
        const isChecked = event.target.checked;
        setCheckboxes(prevState => {
            // 이벤트 발생 체크박스 상태 set
            const updatedCheckboxes = {
                ...prevState,
                [key]: { ...prevState[key], checked: isChecked, parentId : parentId }
            };

            // 체크 해제 이벤트일 경우 모두 동의 체크 해제
            if(!isChecked){
                updatedCheckboxes[0] = {checked: isChecked}
            }

            // 자식 체크박스인 경우 // 부모 컨트롤
            if (updatedCheckboxes[key].parentId) {
                const parentKey = updatedCheckboxes[key].parentId;
                const allSiblingsChecked = Object.keys(updatedCheckboxes)
                .filter(k => updatedCheckboxes[k].parentId === parentKey)
                .every(k => updatedCheckboxes[k].checked);
                updatedCheckboxes[parentKey] = { ...updatedCheckboxes[parentKey], checked: allSiblingsChecked };
            }
            // 부모 체크박스인 경우 //  자식 컨트롤
            else {
                Object.keys(updatedCheckboxes).forEach(k => {
                    if (updatedCheckboxes[k].parentId === key) {
                    updatedCheckboxes[k] = { ...updatedCheckboxes[k], checked: updatedCheckboxes[key].checked };
                    }
                });
            }

            // 전부 동의 체크 했을 경우 모두 동의 체크
            let checkedCheckboxesCnt = 0;
            const numberOfKeys = Object.keys(checkboxes).length;
            Object.keys(updatedCheckboxes).forEach(k => {
                if(k !== 0 && updatedCheckboxes[k].checked){
                    checkedCheckboxesCnt += 1;
                }
            })
            if(checkedCheckboxesCnt === numberOfKeys-1) {
                updatedCheckboxes[0] = {checked: true }
            };
      
            return updatedCheckboxes;
        });
    }

    // 초기 checkboxes 설정
    useEffect(() => {
        if(svcPrvAgr){
            const initialCheckboxes = {};
            const setInitialCheckboxes = (items) => {
                const setData = (items) => {
                    items.forEach(item => {
                        if(item.id && item.checkbox) {
                            initialCheckboxes[item.id] = {
                                checked : false,
                                parentId : item.parentId ? item.parentId : '',
                                required : item.required,
                                saveColumnNum : item.saveColumnNum ? item.saveColumnNum : ''
                            };
                            if(item.children){
                                setData(item.children);
                            }
                        }
                    })
                }
                setData(items);
                initialCheckboxes[0] = {checked : false}; // 모두 동의 상태에 추가
            }
            setInitialCheckboxes(svcPrvAgr.items);
            setCheckboxes(initialCheckboxes); 
        }
    }, [svcPrvAgr]);  

    // 드롭다운 상태를 관리하기 위한 토글 함수
    const toggleDropdown = (index) => {
        setDropdowns(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    }; 

    useEffect(() => {
        console.log(checkboxes);
    }, [checkboxes]);

   // 동의 항목을 재귀적으로 렌더링하는 함수
   const renderItems = (items, parentIndex = '') => {
        return items.map((item, index) => {
            const itemIndex = `${parentIndex}${index}`;
            const parentId = item.parentId? item.parentId : '';
            const isOpen = dropdowns[itemIndex] || false;
            const paddingLNum  = 2+(item.depth*1-1)*5;
            const requiredValue = {
                Y : '[필수]',
                N : '[선택]',
                YN : '[필수/선택]'
            };

            return (
                <div key={itemIndex}>
                    {itemIndex !== '0' && <Divider/>}
                    <ListItemButton sx={{ paddingLeft : paddingLNum,  display: 'flex', paddingTop: '10px'}}
                        selected={isOpen === true}
                    >
                        {item.checkbox ? <FormControlLabel
                                            key={item.id}
                                            class="listItemContent5"
                                            control={
                                                <Checkbox
                                                    checked={checkboxes[item.id]?.checked || false}
                                                    onChange={handleCheck(item.id, parentId)}
                                                />
                                            }
                                            />
                                        : <div class="listItemContent5"/>}
                        <ListItemText class="listItemContent10">
                        {item.checkbox ? typeof item.required === 'boolean' ? item.required ? requiredValue.Y
                                                                                            : requiredValue.N
                                                                            : requiredValue.YN
                                       : ''}
                        </ListItemText>
                        <ListItemText  class="listItemContent80">
                        {item.id}. {item.title}
                        </ListItemText>
                        {item.toggle && isOpen ? <ExpandLess sx={{fontSize:'20px'}} class="listItemContent5" onClick={() => toggleDropdown(itemIndex)}/>
                                               : <ExpandMore sx={{fontSize:'20px'}} class="listItemContent5" onClick={() => toggleDropdown(itemIndex)}/>}
                    </ListItemButton>
                    {isOpen && (
                        <div>
                            {item.content && (
                                <ListItemButton sx={{ display: 'flex', flexDirection: 'column', paddingLeft : paddingLNum, border: '1rem solid #f9f9f9'}}>
                                    <ListItemText sx={{ fontSize: '13px', marginTop: '15px'}}>
                                        {item.content.top_message}
                                    </ListItemText>
                                    <TableContainer sx={{marginTop: '15px'}} component={Paper}>
                                        <Table sx={{ minWidth: 650}} aria-label="simple table">
                                            <TableBody>
                                                {item.content.table.map((row, rowIndex) => (
                                                    <TableRow key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            cell.thYn === 'Y' ? (<TableCell sx={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}
                                                                                            key={cellIndex} 
                                                                                            colSpan={cell.colspan || 1} 
                                                                                            rowSpan={cell.rowspan || 1}
                                                                                            dangerouslySetInnerHTML={{__html: cell.text}}
                                                                                            /> )
                                                                              : (<TableCell sx={{ fontSize: '12px' }} 
                                                                                            key={cellIndex} 
                                                                                            colSpan={cell.colspan || 1} 
                                                                                            rowSpan={cell.rowspan || 1}
                                                                                            dangerouslySetInnerHTML={{__html: cell.text}}
                                                                                            />)
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {item.content.bottom_message ? <Alert sx={{ width: '100%', marginTop: '15px', marginBottom: '15px', fontSize: '13px' }} severity="info">{item.content.bottom_message}</Alert>
                                                                 : <div style={{marginBottom:'15px'}} />}
                                </ListItemButton>
                            )}
                            {item.children && item.children.length > 0 && renderItems(item.children, `${itemIndex}-`)}     
                        </div>
                    )}
                </div>
            );
        });
    };


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <input type="hidden" {...register("client_nm")}/>
                <input type="hidden" {...register("phone_no1")}/>
                <input type="hidden" {...register("phone_no2")}/>
                <input type="hidden" {...register("phone_no3")}/>
                <input type="hidden" {...register("dealer_cd")}/>
                <input type="hidden" {...register("seq")}/>
                <input type="hidden" {...register("prv_agr_ver_no")}/>
                <input type="hidden" {...register("insertType")}/>
                <TitleBox>
                    <Title gutterBottom>
                    {svcPrvAgr.title} {svcPrvAgr.ver_no}
                    </Title>
                </TitleBox>
                <IntroBox>
                    <IntroText> 
                    {svcPrvAgr.to}
                    </IntroText>
                    <IntroText>
                    {svcPrvAgr.message}
                    </IntroText>
                </IntroBox>
                <ContentPaper>
                    <MenuItem>
                        <Checkbox 
                            checked={checkboxes[0]?.checked || false}
                            onChange={allCheck()}
                        />
                        <ListItemText>
                            모두 동의합니다.
                        </ListItemText>
                    </MenuItem>
                </ContentPaper>
                <Paper>
                    <List>
                    {renderItems(svcPrvAgr.items)}
                    </List>
                </Paper>
                <div className="container">
                    <Typography className="textContainer">
                        서명을 입력해주세요.
                    </Typography>
                    <div className="canvasContainer">
                        <SignatureCanvas
                            ref={sigCanvas}
                            canvasProps={{
                                className: 'sigCanvas canvasStyle'
                            }}
                            clearOnResize={false}
                            backgroundColor="rgb(255, 255, 255)"
                        />
                    </div>
                    <Stack direction="row" spacing={2} className="btnContainer">
                        <Button type="button" onClick={clearCanvas} variant="outlined">초기화</Button>
                        <Button type="submit" variant="contained">저장</Button>
                    </Stack>
                </div>
            </form>
        </div>
    )
}

export default SvcPrvAgrInfo;