import { Box, Dialog, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";

function CPImageModal({open, setOpen, images}) {

    const [mainImg, setMainImg] = useState(images[0]);

    return (
        <Dialog open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>커티시카 이미지</DialogTitle>
        <IconButton aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{ position: 'absolute',
                            right: 8,
                            top: 8,
                        }}> 
                    <CloseIcon />
        </IconButton>

        <DialogContent dividers>
            <Box display={'flex'} gap={2} alignItems={'center'}>
                <Box height={450}>
                    <img src={mainImg} alt={'main'} style={{width: '600px', height: '450px'}}></img>
                </Box>
                <Box>
                    <ImageList sx={{ width: 300, height: 450, margin: 0, gridAutoRows: 'min-content' }} gap={0} cols={3} >
                        {images?.map((url, index) => (
                            <ImageListItem 
                                key={url} 
                                sx={{cursor: 'pointer'}}
                                onClick={() => setMainImg(url)}
                            >
                                <img
                                    style={{width: '100px', height: '60px'}}
                                    srcSet={`${url}`}
                                    src={`${url}`}
                                    alt={index}
                                    loading='lazy'
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
    )
}

export default CPImageModal;