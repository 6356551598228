import { useState } from 'react';

import { Box, Button, Card, CardContent, CardMedia, Checkbox, FormControlLabel, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Global } from '@emotion/react';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import * as utils from 'commons/utils.js';
import * as signOk from 'services/apis/signOk/signOkApi';
import * as svcApi from 'services/apis/crm4u/svcApi';
import { useSetRecoilState } from 'recoil';
import { alertState, loadingState } from 'stores/ComponentState';
import CPImageModal from 'components/CPImageModal';
import CPSwipeableImage from 'components/CPSwipeableImage';

function CrtsyContractInfo({ templateInfo, eformInfo, images }) {
    
    const setAlertInfo = useSetRecoilState(alertState);
    const setLoadingInfo = useSetRecoilState(loadingState);
    const {crtsy_check_drawing, crtsy_check_photo} = images;
    const thumbnail = crtsy_check_photo?.[0];
    const [hasCheckPhoto] = useState(crtsy_check_photo?.length > 0);
    
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false)
    const [viewUrl, setViewUrl] = useState(eformInfo?.view_url);
    
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg")); //width 1200px 기준

    const contractEformDiv = {
        CED00010 : '대여',
        CED00020 : '반납'
    }

    const text = (k, v) => {
        return {
            'key': k??'',
            'value': v??''
        }
    }

    const image = (k, v) => {
        return {
            'key': k??'',
            'image': v??''
        }
    }

    const InfoBox = styled(Box)`
        background-color: #EEECEC;
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 20px;
    `;

    const InfoTitle = styled(Typography)`
        font-weight: bold;
        color: #767676;
        margin-bottom: 3px;
    `;
    InfoTitle.defaultProps = {
        variant: "body2"
    };

    const InfoText = styled(Typography)`
        font-weight: bold;
        color: #333333;
        margin-bottom: 5px;
    `;
    InfoText.defaultProps = {
        variant: "body2"
    }

    const basicInfo = {
        '차종' : eformInfo?.class_nm,
        '담당 어드바이저' : eformInfo?.emp_nm,
        '차량 번호' : eformInfo?.vehicle_no,
        '연료' : eformInfo?.fuel_type_nm,
        '대여 시작일' : eformInfo?.courtesy_start_rsv_dt,
        '대여 종료일' : eformInfo?.courtesy_end_rsv_dt,
        '대여 주행거리' : utils.setComma(eformInfo?.mileage_from??0) + ' km',
        '대여 연료량' : eformInfo?.fuel_from + ' %',
    }

    if(eformInfo?.courtesy_eform_div === 'CED00020') {
        basicInfo['반납 주행거리'] = utils.setComma(eformInfo?.mileage_to??0) + ' km';
        basicInfo['반납 연료량'] = eformInfo?.fuel_to + ' %';
    }

    const clientInfo = {
        '차종' : eformInfo?.in_car_class_nm,
        '고객명' : eformInfo?.client_nm,
        '차량 번호' : eformInfo?.in_car_no,
        '운전자명' : eformInfo?.driver_nm,
        '연락처' : eformInfo?.client_phone,
        '주소' : eformInfo?.client_addr,
    }

    const getSignerItems = () => {
        const today = new Date();
        const year = today.getFullYear().toString().slice(2);
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        

        const items = [
            text('COURTESY_EFORM_CONT_NO', eformInfo?.courtesy_eform_cont_no),

            //커티시카 정보
            text('CLASS_NM', eformInfo?.class_nm),
            text('VEHICLE_NO', eformInfo?.vehicle_no),
            text('EMP_NM', eformInfo?.emp_nm),
            text('COURTESY_START_RSV_DT', eformInfo?.courtesy_start_rsv_dt),
            text('COURTESY_END_RSV_DT', eformInfo?.courtesy_end_rsv_dt),
            text('FUEL_TYPE_NM', eformInfo?.fuel_type_nm),

            //고객 정보
            text('IN_CAR_CLASS_NM', eformInfo?.in_car_class_nm),
            text('IN_CAR_NO', eformInfo?.in_car_no),
            text('CLIENT_PHONE', eformInfo?.client_phone),
            text('CLIENT_ADDR', eformInfo?.client_addr),
            text('CLIENT_NM', eformInfo?.client_nm),
            text('DRIVER_NM', eformInfo?.driver_nm),
            
            //신청 사유
            text('COURTESY_CAR_PURPOSE_NM', eformInfo?.courtesy_car_purpose_nm),
            text('COURTESY_CAR_RESN_DETAIL', eformInfo?.courtesy_car_resn_detail?.replaceAll("<br/>","\n")),

            //서명 정보
            text('EFORM_DIV', contractEformDiv[eformInfo?.courtesy_eform_div]),
            text('MANAGER_NM', eformInfo?.manager_nm),
            text('TODAY_YY', year),
            text('TODAY_DD', day),
            text('TODAY_MM', month)
        ]

        //대여 정보
        items.push(text('RESERVE_MILEAGE_FROM', utils.setComma(eformInfo?.mileage_from??0) + ' km'));
        items.push(text('RESERVE_FUEL_FROM', (eformInfo?.fuel_from??0) + ' %'));
        items.push(text('RESERVE_COURTESY_START_RSV_DT', eformInfo?.courtesy_start_rsv_dt));

        //반납 정보
        if(eformInfo?.courtesy_eform_div === 'CED00020') {
            items.push(text('RETURN_MILEAGE_TO', utils.setComma(eformInfo?.mileage_to??0) + ' km'));
            items.push(text('RETURN_FUEL_TO', (eformInfo?.fuel_to??0) + ' %'));
            // items.push(text('RETURN_COURTESY_START_RSV_DT', eformInfo?.courtesy_start_rsv_dt));
            items.push(text('RETURN_COURTESY_END_RSV_DT', eformInfo?.courtesy_end_rsv_dt));
        }

        return items;
    }
    
    
    const onSubmit = async () => {
        try {
            setLoadingInfo({isOpen:true});
            if(!window.confirm("계약서를 작성하시겠습니까?")) return;

            // 템플릿 정보
            const template = {
                "templateId": templateInfo?.var3,
                "templateTitle": templateInfo?.var4,
            }

            // 서명자 정보
            await signOk.getSignOkToken(templateInfo?.var1, templateInfo?.var2)
            const { signerId } = await signOk.getSignOkTemplate(templateInfo?.var3);
            const signer = [{
                "id": signerId[0],
                "name": eformInfo?.client_nm,
                "contact": eformInfo?.client_phone,
                "items": getSignerItems()
            }]

            // 이미지 (외관점검)
            const drawingImgUrl = crtsy_check_drawing?.[0]??'';
            const path = '/upload' + drawingImgUrl.split('/upload')[1];
            const visualInsepection = await utils.getBase64FromUrl(path);
            const templateItems = [image('VISUAL_INSPECTION', visualInsepection?.split('base64,')[1])];

            //1. 전자계약서 문서 생성
            const document = await signOk.createSignOkTemplate(template, signer, templateItems); 
            
            //2. 커티시카 전자계약 데이터 업데이트
            await svcApi.svcUpdateCourtesyCarEform({
                courtesy_eform_seq: eformInfo?.courtesy_eform_seq, 
                crtsy_eform_prog_status: 'CSPS00020', 
                courtesy_reserve_seq: eformInfo?.courtesy_eform_seq, 
                dealer_cd: eformInfo?.dealer_cd,
                view_url: document?.result?.signer?.[0]?.view_url, 
                document_id: document?.result?.id, 
                result_code: document?.code, 
                result_message: document?.message,
            });

            window.open(document?.result?.signer?.[0]?.view_url, '_blank');
            setViewUrl(document?.result?.signer?.[0]?.view_url);
        } catch(err) {
            setAlertInfo({message :err, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
        
    }

    return (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        minHeight: `calc(50%)`,
                    }
                }}
            />
            <Card>
                {hasCheckPhoto ?
                    <CardMedia
                        sx={{ height: 300, position: 'relative' }}
                        image={thumbnail}
                    >
                            <Button 
                                startIcon={<ImageOutlinedIcon />} 
                                sx={{
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 10,
                                    color: 'white', 
                                    backgroundColor: '#1A1B2150',
                                    '&:hover': {
                                        backgroundColor: '#1A1B2150'
                                    }
                                }}
                                onClick={() => setOpen(true)}
                            >
                                이미지 보기
                            </Button>
                    </CardMedia>
                :
                    <CardMedia
                        sx={{ height: 300, position: 'relative', backgroundColor: '#e5e5e5', backgroundSize:'contain' }}
                        image={'/images/no_image.jpeg'}
                    >
                        {
                            eformInfo?.file_del_yn === 'Y' &&
                            <Typography 
                                variant='caption'
                                fontWeight={'bold'} 
                                sx={{
                                    position: 'absolute',
                                    color: '#616161',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    left: '50%;',
                                    transform: 'translate(-50%, -50%)',
                                    bottom: 0,
                                    width: '90%'
                                }}
                            >
                                계약서 작성 후 한 달이 지나 이미지가 만료되었습니다.<br/>
                                차량 이미지가 필요하신 경우 담당 어드바이저에게 문의해 주세요.
                            </Typography>
                        }
                    </CardMedia>
                }
                <CardContent>
                    <Typography variant='h3' fontWeight={'bold'}>
                        {`${eformInfo?.client_nm}님 커티시카 ${contractEformDiv[eformInfo?.courtesy_eform_div]??""} 계약 정보`}
                    </Typography>
                </CardContent>
                <CardContent sx={{paddingBottom: 0}}>
                    <Box>
                        <Typography variant='body2' fontWeight={'bold'} marginBottom={1}>
                            대차 정보
                        </Typography>
                        <InfoBox>
                            <Grid container spacing={2}>
                                {Object.entries(basicInfo).map(([title, text], index) => (
                                <Grid item xs={6} key={index}>
                                    <InfoTitle>{title}</InfoTitle>
                                    <InfoText>{text || "-"}</InfoText>
                                </Grid>
                                ))}
                            </Grid>
                        </InfoBox>
                    </Box>
                    <Box>
                        <Typography variant='body2' fontWeight={'bold'} marginBottom={1}>
                            고객 정보
                        </Typography>
                        <InfoBox>
                            <Grid container spacing={2}>
                                {Object.entries(clientInfo).map(([title, text], index) => (
                                <Grid item xs={6} key={index}>
                                    <InfoTitle>{title}</InfoTitle>
                                    <InfoText>{text || "-"}</InfoText>
                                </Grid>
                                ))}
                            </Grid>
                        </InfoBox>
                    </Box>
                </CardContent>
                <CardContent sx={{paddingTop: 0}}>
                    {viewUrl ? 
                        <>
                        <Button 
                            fullWidth
                            variant='contained' 
                            size='large' 
                            type='submit'
                            onClick={() => window.open(viewUrl, '_blank')}
                        >
                            계약서 보기
                        </Button>
                        </>
                    :
                        <>
                        <Box textAlign={'center'}>
                            <Typography variant='caption' display={'block'} >
                                모든 정보를 확인하셨다면 '계약서 작성' 버튼을 클릭해 주세요.
                            </Typography>
                            <Typography variant='caption' display={'block'} >
                                고객님의 카카오톡으로 전자계약서가 전달됩니다.
                            </Typography>
                            <FormControlLabel 
                                control={
                                    <Checkbox size='small' onChange={() => setChecked((prev) => !prev)} />
                                }
                                label={
                                    <Typography variant='caption' fontWeight={'bold'}>
                                        모든 정보를 확인하셨습니까?
                                    </Typography>
                                } 
                            />
                        </Box>
                        <Button 
                            disabled={!checked}
                            fullWidth
                            variant='contained' 
                            size='large' 
                            type='submit'
                            sx={{marginTop: 2}}
                            onClick={onSubmit}
                        >
                            계약서 작성
                        </Button>
                        </>
                    }
                </CardContent>
            </Card>
            {
                isDesktop ?
                <CPImageModal open={open} setOpen={setOpen} images={crtsy_check_photo}/>
                :
                <CPSwipeableImage open={open} setOpen={setOpen} images={crtsy_check_photo}/>
            }
        </>
    )
}

export default CrtsyContractInfo;