import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';

/* 외부 */
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';

/* mui */
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import{ TitleChip, ContentPaper, WarningTextBox } from '../../../styles/ewMng/EwFormStyle';
import { FormControl, RadioGroup, Radio, IconButton, Stack, Button, Checkbox, TextField, Typography } from '@mui/material';
import { FormControlLabel, Slider, InputAdornment } from '@mui/material';
//mui icon
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';
import RestoreIcon from '@mui/icons-material/Restore';

/* 내부 */
import 'styles/base.css';
import 'styles/ewMng/EwForm.css';
import{ HelperTextBox } from 'styles/ewMng/EwFormStyle';
import * as utils from 'commons/utils';
import * as svcApi from 'services/apis/crm4u/svcApi';
import { userState } from 'stores/UserState';
import { alertState, loadingState } from 'stores/ComponentState';
import CPTextField from 'components/comm/CPTextField';
import CPDatePicker from 'components/comm/CPDatePicker';
import CPSelectTag from 'components/comm/CPSelectTag';
import CPToggleButtonGroup from 'components/comm/CPToggleButtonGroup';
import CPCrtSearchModal from 'components/CPCrtSearchModal';
import CPSvcClnSearchModal from 'components/CPSvcClnSearchModal';
import CPDaumPostCodeModal from 'components/CPDaumPostCodeModal';
import CPToggleButtonGroup2 from 'components/comm/CPToggleButtonGroup2';

const CrtsyFormInfoStep = forwardRef(({ changeValid, onStepValidation, ...props }) => {

    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    const location = useLocation();
    const navigate = useNavigate();
    const xsVal = 6;
    const smVal = 4;
    const mdVal = 4;

    const defaultValues = {
        courtesy_eform_seq: '',
        courtesy_eform_div : props?.courtesyEformDivData, // 대여 or 반납
        courtesy_eform_cont_no : '', // 대차 전자계약서 번호
        courtesy_reserve_seq : '', // 대차seq
        showroom_no : userInfo.showroom_no, // 대차서비스센터
        courtesy_car_no : '', // 커티시카
        courtesy_car_info : '', // 커티시카 정보
        class_nm : '', // 커티시카 차종
        emp_id : '', // 대차담당자
        courtesy_start_rsv_time : '', // 대차시작일자
        courtesy_end_rsv_time : '', // 대차종료일자
        courtesy_start_rsv_gubun : '', // 대차시작일자(오전/오후)
        courtesy_end_rsv_gubun : '', // 대차종료일자(오전/오후)
        courtesy_car_gubun : '', // 대차구분
        courtesy_car_purpose : 'CPP00040', // 대차목적
        courtesy_car_resn_detail : '', // 대차목적상세
        client_no : '', // 고객no
        client_nm : '', // 고객명
        client_phone : '', // 휴대전화
        client_addr1 : '', // 주소1
        client_addr2 : '', // 주소2
        client_postal_code : '', // 주소3
        driver_nm : '', // 운전자명
        wip_no : '', // 정비wip번호
        in_car_no : '', // 수리차량번호
        in_car_model_no : '', // 수리차량모델
        in_car_class_no : '', // 수리차량차종
        mileage_from : '0', // 대여주행거리
        mileage_to : '0', // 반납주행거리
        fuel_from : 0, // 대여연료량
        fuel_to : 0, // 반납연료량
        courtesy_car_prog_status : '' // 커티시카 상태
    };

    const { register,
            handleSubmit,
            setError,
            clearErrors,
            setValue,
            getValues,
            reset,
            watch,
            control,
            formState: { errors, isDirty, dirtyFields}
    } = useForm({defaultValues : defaultValues});
    
    // selection
    const rsvGubunList = [{cd_nm: '오전',detail_cd_id: 'am'}, {cd_nm: '오후', detail_cd_id: 'pm'}];
    const fuelSteps = [{value:0, label:'E'},
                       {value:10, label:'10%'},
                       {value:20, label:'20%'},
                       {value:30, label:'30%'},
                       {value:40, label:'40%'},
                       {value:50, label:'50%'},
                       {value:60, label:'60%'},
                       {value:70, label:'70%'},
                       {value:80, label:'80%'},
                       {value:90, label:'90%'},
                       {value:100, label:'F'},]; 

    // 1차 state selection
    // const [courtesyEformDivList, setCourtesyEformDivList] = useState([]);
    const [showRoomList, setShowRoomList] = useState([]);
    const [courtesyCarGubun, setCourtesyCarGubun] = useState([]);
    const [courtesyCarPurpose, setCourtesyCarPurpose] = useState([]);
    const [carModelList, setCarModelList] = useState([]);
    
    // 2차 state selection
    const [empList, setEmpList] = useState([]);
    const [courtesyCarList, setCourtesyCarList] = useState([]);
    const [carClassList, setCarClassList] = useState([]);
    
    // value
    const [tmpCourtesyCar, setTmpCourtesyCar] = useState({courtesy_car_no: '', courtesy_car_info : ''}); // cpo이관된 커티시카 임시 저장
    const [isoVin, setIsoVin] = useState('');
    const [fuelTypeNm, setFuelTypeNm] = useState('');
    const [selectAuth, setSelectAuth] = useState(() => utils.isAuthorized(userInfo.authList, ['AUT00450'])); // 커티시카 관리만 전체 선택 가능하도록
    const [fetchedData, setFetchedData] = useState({});
    const [savedTmpData, setSavedTmpData] = useState({}); // 저장된 임시 정보
    const [savedData, setSavedData] = useState({}); // 저장된 임시 정보

    // flag
    const [isLoading, setIsLoading] = useState(true);
    const [skipEffect, setSkipEffect] = useState(false); // useEffect 실행여부 제어 플래그 (true : effect 실행 안함)
    const [isNewReserve, setIsNewReserve] = useState(props?.courtesyEformDivData === 'CED00010' ? true : false ); // 대차예약 신규 여부 (true : 신규 예약 / false : 기존 예약)
    const [isNewEform, setIsNewEform] = useState(true); // 계약서 신규 여부 (true : 신규 계약서 / false : 기존 계약서)
    const [isSelected, setIsSelected] = useState(false); // 대차예약 선택됐는지 여부 (true : 선택됨 / false : 선택안됨) -> 대차 예약 모달 on/off 역할
    const [isCpoTransferred, setIsCpoTransferred] = useState(false); // cpo이관여부 (true : 이관 / false : 운영중)
    const [isCourtesyChkboxClicked, setIsCourtesyChkboxClicked] = useState(false); // 커티시카 차량 변경 체크박스 선택 여부
    const [isReserveDtValid, setIsReserveDtValid] = useState(true); // 대차날짜 유효성 여부
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false); // 등록/수정 성공 여부
    const [isUpdatePossible, setIsUpdatePossible] = useState(false); // 수정 가능 여부 (계약서 생성 후에 수정할 경우)
    const [courtesyEformContNoTxt, setCourtesyEformContNoTxt] = useState(''); // eform_cont_no의 텍스트 (보여주는 값)

    // modal
    const [isCrtModalOpen, setIsCrtModalOpen] = useState(false);
    const [isClnModalOpen, setIsClnModalOpen] = useState(false);
    const [isAddrModalOpen, setIsAddrModalOpen] = useState(false);
    const [clnModalInfo, setClnModalInfo] = useState('');
    const [crtModalInfo, setCrtModalInfo] = useState('');
    
    const fieldNames = ["showroom_no","emp_id","courtesy_car_no","courtesy_start_rsv_time","courtesy_end_rsv_time","courtesy_start_rsv_gubun","courtesy_end_rsv_gubun",
        "courtesy_car_gubun","courtesy_car_purpose","courtesy_car_resn_detail","client_no","client_nm","client_phone","client_addr1","client_addr2","client_postal_code",
        "driver_nm","wip_no","in_car_no","in_car_model_no","in_car_class_no","mileage_from","mileage_to","fuel_from","fuel_to"];
    // change state
    const watchedValues = watch(fieldNames); // 폼 변경 감지
    // const courtesyEformContNo = watch("courtesy_eform_cont_no") || '';
    const showroomNo = watch("showroom_no");
    const inCarModelNo = watch("in_car_model_no");
    const courtesyCarNo = watch("courtesy_car_no");
    const courtesyStartRsvTime = watch("courtesy_start_rsv_time");
    const courtesyEndRsvTime = watch('courtesy_end_rsv_time');
    const courtesyStartRsvGubun = watch('courtesy_start_rsv_gubun');
    const courtesyEndRsvGubun = watch('courtesy_end_rsv_gubun');
    const courtesyEformDiv = props?.courtesyEformDivData;

    // 고객조회 모달 오픈 시 필요한 props 세팅
    const onClnSearchModal = useCallback(async (props) => {
        if(props.show) {
            props = {
                ...props,
                // showRoomList: showRoomList,
                // deptList: dept.deptList,
                selectAuth: true,
                client_nm : getValues('client_nm') || ''
            }
            setClnModalInfo(props, setIsClnModalOpen(props.show));
        }else {
            setIsClnModalOpen(props.show);
        }
    }, [userInfo.showroom_no, showRoomList]);
  
    // 주소검색 모달 오픈
    const onAddrSearchModal = (show) =>{
        setIsAddrModalOpen(show);
    };

    // 대차예약 검색 모달 오픈
    const onCrtSearchModal = useCallback(async (props) => {
        if(props.show) {
            props = {
                ...props, 
                showRoomList: showRoomList,
                selectAuth: selectAuth,
                isSelected: isSelected,
                courtesyEformDiv : courtesyEformDiv
            }
            setCrtModalInfo(props, setIsCrtModalOpen(props.show));
        }else {
            setIsCrtModalOpen(props.show);
        }
    },[isSelected, selectAuth, showRoomList])
   
    const resetCourtesyCarInfo = useCallback(() => {
        setValue('courtesy_car_no','');
        setValue('courtesy_car_info','');
    }, [setValue]);

    // 초기 세팅 : 서비스센터, 대차구분, 대차목적, 수리차량모델
    useEffect(() => {
        // formData에 데이터가 있을 경우 (계약서 작성했을 경우)
        if(props?.contractData?.formData) {
            setIsNewReserve(false);
            setIsSelected(true); 
            setSavedData(props.contractData.formData)
            setSavedTmpData(props.contractData.formData)
            setCourtesyEformContNoTxt(props.contractData.formData.courtesy_eform_cont_no);
        } 

        // 코드 가져오기
        const formData = new FormData();
        svcApi.svcApiGetCode(formData).then((res) => {
            setShowRoomList(res.showRoomList); // 서비스센터
            setCourtesyCarGubun(res.cdMap.COURTESYCAR_DIV); // 대차구분
            setCourtesyCarPurpose(res.cdMap.COURTESYCAR_PURPOSE); // 대차목적
            setCarModelList(res.carModelList); // 수리차량모델
            
            // 권한이 없을 경우 커티시카 세팅
            if(!selectAuth) {
                svcApi.svcApiSelectBoxCourtesyCarInfo({"showroom_no" : userInfo.showroom_no}).then((res) => {
                    setCourtesyCarList(res.carList || []);
                })
            }

            setIsLoading(false); // 로딩완료
        }).catch((err) => {
            setAlertInfo({message: err, isOpen: true, severity: "error"})
            setIsLoading(false); // 로딩완료
        })
    }, [selectAuth, setAlertInfo, setValue, userInfo.showroom_no]);

    // 서비스센터 세팅 시 반납이면 대차예약조회 모달 열기
    useEffect(() => {
        // formData에 데이터가 있으면 formData를 현재 form에 set하기
        if(props?.contractData?.formData) {
            reset(props?.contractData?.formData);
            setIsSubmitSuccessful(true); // 성공적으로 submit 
        } else {
            if(courtesyEformDiv === 'CED00020') {
                if(!isNewReserve && !isSelected) { // 선택되지 않은 경우
                    onCrtSearchModal({show:true})
                }
            }
        }
    }, [showRoomList, isSelected, onCrtSearchModal])

    // 커티시카 세팅 시 fin, 유종 초기화
    useEffect(() => {
        setValue('iso_vin','');
        setValue('fuel_type_nm','');
    }, [courtesyCarList, setValue])
  
    // 전시장 선택 시 그에 맞는 담당자, 커티시카 목록 세팅하기
    useEffect(() => {
        if(skipEffect) return;
        setIsLoading(true);
        if(showroomNo) {
            // 기존 값 초기화
            if(isNewReserve) {
                setValue("emp_id","");
                resetCourtesyCarInfo();
            }

            // 대차담당자
            svcApi.getEmpList({showroom_no: showroomNo, size: 99}).then((res2) => {
                setEmpList(res2.empList);
            }).catch((err2) => {
                setAlertInfo({message: err2, isOpen: true, severity: "error"})
                setIsLoading(false);
            })

            // 커티시카
            // 신규대차이거나, 기존대차 수정이지만 cpo매각 상태가 아니거나
            if(isNewReserve || (!isNewReserve && getValues('courtesy_car_prog_status') !== 'CPS00020')) {
                svcApi.svcApiSelectBoxCourtesyCarInfo({"showroom_no": showroomNo}).then((res3) => {
                    setCourtesyCarList(res3.carList);
                }).catch((err3) => {
                    setAlertInfo({message: err3, isOpen: true, severity: "error"})
                    setIsLoading(false);
                })
            }
        }
        setIsLoading(false);
    }, [showroomNo, setAlertInfo, setValue, skipEffect, getValues, isNewReserve, resetCourtesyCarInfo]); 

    // cpo 이관된 차량 선택했을 시 커티시카 textfield에 값 추가
    useEffect(() => {
        // cpo이관일 경우
        if(isCpoTransferred){
            // 체크
            if(isCourtesyChkboxClicked) {
                // 선택된 커티시카 정보 초기화
                resetCourtesyCarInfo();

                // 전시장에 맞는 커티시카 가져오기
                svcApi.svcApiSelectBoxCourtesyCarInfo({"showroom_no": showroomNo}).then((res3) => {
                    setCourtesyCarList(res3.carList);
                }).catch((err3) => {
                    setAlertInfo({message: err3, isOpen: true, severity: "error"})
                    setIsLoading(false);
                })
            } 
            // 체크 해제
            else {
                setValue('courtesy_car_no', tmpCourtesyCar.courtesy_car_no);
                const courtesyCarInfo = tmpCourtesyCar.courtesy_car_info;
                if(courtesyCarInfo) {
                    document.getElementById('courtesy_car_info').value = courtesyCarInfo;
                }
            }
        }
    }, [isCourtesyChkboxClicked, isCpoTransferred, setValue, getValues, 
        tmpCourtesyCar.courtesy_car_info, tmpCourtesyCar.courtesy_car_no,
        setAlertInfo, showroomNo, resetCourtesyCarInfo]); 

    // 커티시카 차량 선택 시 그에 맞는 커티시카FIN(iso_vin), 유종 값 가져오기
    useEffect(() => {
        if(skipEffect) return;
        setIsLoading(true); // 로딩
        if(courtesyCarNo) {
            svcApi.svcApiCourtesyCarInfo(courtesyCarNo).then((res) => {
                if(res.courtesyCarInfo.fuel_type_nm === "" || res.courtesyCarInfo.fuel_type_nm === null) {
                    alert("유종이 등록되지 않은 커티시카입니다. CRM에서 유종 정보를 입력하세요.")
                    setValue('courtesy_car_no','');
                } else {
                    let iso_vin = res.courtesyCarInfo.iso_vin || "입력된 정보 없음";
                    let fuel_type_nm = res.courtesyCarInfo.fuel_type_nm || "입력된 정보 없음";
                    setIsoVin(iso_vin);
                    setFuelTypeNm(fuel_type_nm);
                }
            }).catch((err) => {
                setAlertInfo({message: err, isOpen: true, severity: "error"})
                setIsLoading(false);
            })
        } else {
            setIsoVin('');
            setFuelTypeNm('');
        }
        setIsLoading(false); // 로딩완료
    },[courtesyCarNo, setAlertInfo, skipEffect, getValues]);

    // 수리차량모델 선택 시 그에 맞는 수리차량차종 목록 세팅하기
    useEffect(() => {
        if(skipEffect) return;
        setIsLoading(true); // 로딩 
        if(inCarModelNo) {
            // 기존값 초기화
            if(isNewReserve) {
                setValue("in_car_class_no","");
            }

            svcApi.svcApiGetCarType(inCarModelNo).then((res4) => {
                setCarClassList(res4);
            }).catch((err2) => {
                setAlertInfo({message: err2, isOpen: true, severity: "error"})
                setIsLoading(false);
            })
        }
        setIsLoading(false); // 로딩완료
    }, [inCarModelNo, setAlertInfo, setValue, skipEffect, isNewReserve]); 

    // 대여기간, 커티시카 선택 시 api 호출
    useEffect(() => {
        
        if(!(courtesyCarNo && courtesyStartRsvTime && courtesyEndRsvTime && courtesyStartRsvGubun && courtesyEndRsvGubun)) return;
        setIsReserveDtValid(true);
        const isValidDate = (date) => dayjs(date).isValid();
        if(isValidDate(courtesyStartRsvTime) && isValidDate(courtesyEndRsvTime) && courtesyCarNo) {
            const startTime = dayjs(courtesyStartRsvTime).format('YYYY-MM-DD');
            const endTime = dayjs(courtesyEndRsvTime).format('YYYY-MM-DD');
            // 대차예약 조회 하는 api
            const checkBody = {
                showroom_no : showroomNo,
                courtesy_car_no : courtesyCarNo,
                courtesy_start_rsv_time : startTime,
                courtesy_start_rsv_gubun : getValues('courtesy_start_rsv_gubun'),
                courtesy_end_rsv_time : endTime,
                courtesy_end_rsv_gubun : getValues('courtesy_end_rsv_gubun'),
                isNewReserve : isNewReserve,
                courtesy_reserve_seq : isNewReserve?'':getValues('courtesy_reserve_seq')
            }
            svcApi.svcApiCheckCourtesyCarReserveTime(checkBody).then((res) => {
                const duplCnt = Number(res.duplCnt)
                const isAfterRegister = res.isAfterRegister
                if(duplCnt > 0 || isAfterRegister === 'false') {
                    if(duplCnt > 0) {
                        setIsReserveDtValid(false);
                        setAlertInfo({message: '중복되는 커티시카 예약이 있습니다.', isOpen: true, severity: "error"})
                        return;
                    }
                    if(isAfterRegister === 'false') {
                        setIsReserveDtValid(false);
                        setAlertInfo({message: '커티시카 등록일 이전 날짜입니다.', isOpen: true, severity: "error"})
                        return;
                    }
                } else if (duplCnt === 0 && isAfterRegister === 'true') {
                    setIsReserveDtValid(true);
                    setAlertInfo({message: '커티시카 예약 가능합니다.', isOpen: true, severity: "success"})
                    setTimeout(() => {
                        setAlertInfo((prev) => ({ ...prev, isOpen: false }));
                    }, 1500);
                    clearErrors("courtesy_start_rsv_time");
                    clearErrors("courtesy_end_rsv_time");
                } else {
                    setAlertInfo({message: '페이지에 오류가 발생했습니다. 관리자에게 문의하세요', isOpen: true, severity: "error"})
                }
            })
        }
    }, [courtesyCarNo, courtesyStartRsvTime, courtesyEndRsvTime, courtesyStartRsvGubun, courtesyEndRsvGubun])

    // 날짜 유효성 오류. 커티시카랑 날짜 초기화하기
    useEffect(() => {
        if(!isReserveDtValid) {
            if(courtesyEformDiv === 'CED00010') {
                setValue('courtesy_start_rsv_time','');
                setValue('courtesy_start_rsv_gubun','');
                setError('courtesy_start_rsv_time', {
                    type:"manual",
                    message:"다시 입력해주세요."
                })
            }
            setValue('courtesy_end_rsv_time','');
            setValue('courtesy_end_rsv_gubun','');
            setError('courtesy_end_rsv_time', {
                type:"manual",
                message:"다시 입력해주세요."
            })
        }
    }, [isReserveDtValid, setValue])
    
    // 데이터 변경되면 valid 아님 (next 안보임)
    useEffect(() => {
        const watchedValuesObject = getWatchedValuesObject(fieldNames, watchedValues);
        const differences = getDifferences(fieldNames, savedTmpData, watchedValuesObject);
        if (Object.keys(differences).length > 0) {
            // console.log("차이가 있는 필드:", differences);

            if(!isNewEform) {
                setIsUpdatePossible(true);
            }
            
            setIsSubmitSuccessful(false); // 성공적으로 submit 하지 않았다고 하기
    
            // savedTmpData를 업데이트하여 무한 루프 방지
            const updatedSavedTmpData = { ...savedTmpData }; // 기존 savedTmpData 복사
            fieldNames.forEach((fieldName) => {
                if (watchedValuesObject.hasOwnProperty(fieldName)) {
                    updatedSavedTmpData[fieldName] = watchedValuesObject[fieldName]; // watchedValuesObject의 값을 savedTmpData에 업데이트
                }
            });
            setSavedTmpData(updatedSavedTmpData); // 업데이트된 savedTmpData 설정
        }
    }, [watchedValues])

    // 도착주행거리 > 출발주행거리
    const handleMileageToChange = (mileageToValue) => {
        if(mileageToValue === '') {
            setValue('mileage_to',getValues('mileage_from'));
        } else {
            const value = mileageToValue?mileageToValue.replace(/,/g,''):0;
            const mileageTo = parseFloat(value);
            const mileageFromStr = getValues('mileage_from').replace(/,/g,'');
            const mileageFrom = parseFloat(mileageFromStr);
            if(mileageTo < mileageFrom) {
                setValue('mileage_to',getValues('mileage_from'));
                alert('도착 주행거리는 출발주행거리보다 커야 합니다.');
                return false;
            }
        }
        return true;
    }
        
    // 배열을 객체로 변환
    const getWatchedValuesObject = (fieldNames, watchedValues) => {
        return fieldNames.reduce((acc, fieldName, index) => {
            acc[fieldName] = watchedValues[index];
            return acc;
        }, {});
    };

    // 특정 필드 값 비교
    const getDifferences = (fieldNames, savedTmpData, watchedValuesObject) => {
        // 둘 다 빈 객체인 경우
        if (Object.keys(savedTmpData).length === 0 && Object.keys(watchedValuesObject).length === 0) {
            return {}; // 둘 다 빈 객체이므로 차이가 없음
        }

        // 둘 중 하나라도 빈 객체인 경우
        if (Object.keys(savedTmpData).length === 0) {
            return { ...watchedValuesObject };
        }
        if (Object.keys(watchedValuesObject).length === 0) {
            return { ...savedTmpData };
        }

        return fieldNames.reduce((acc, fieldName) => {
            // fieldNames에 선언된 필드에 대해서만 비교
            if (savedTmpData.hasOwnProperty(fieldName) && !_.isEqual(savedTmpData[fieldName], watchedValuesObject[fieldName])) {
                acc[fieldName] = {
                    oldValue: savedTmpData[fieldName],
                    newValue: watchedValuesObject[fieldName]
                };
            }
            return acc;
        }, {});
    };

    // cpo 이관 커티시카 차량 변경 체크박스 선택
    const changeCourtesyCar = (e) => {
        setIsCourtesyChkboxClicked(e.target.checked);
    }

    // 대차예약조회 모달에서 클릭 시 호출하는 메소드
    const inputCrtInfo = async (selRow) => {
        setIsLoading(true);
        setSkipEffect(true);
        setIsNewReserve(false);
        setIsSelected(true);

        const modalFetchedData = selRow;

        // 날짜 및 숫자 포멧팅
        if(modalFetchedData?.courtesy_start_rsv_time) {
            modalFetchedData.courtesy_start_rsv_time = dayjs(modalFetchedData.courtesy_start_rsv_time);
        }
        if(modalFetchedData?.courtesy_end_rsv_time) {
            modalFetchedData.courtesy_end_rsv_time = dayjs(modalFetchedData.courtesy_end_rsv_time);
        }
        if(modalFetchedData?.mileage_from) {
            modalFetchedData.mileage_from = utils.setComma(modalFetchedData.mileage_from)
        }
        if(modalFetchedData?.mileage_to) {
            modalFetchedData.mileage_to = utils.setComma(modalFetchedData.mileage_to);
        }
        if(modalFetchedData?.fuel_from) {
            modalFetchedData.fuel_from = Number(modalFetchedData.fuel_from);
        }
        if(modalFetchedData?.fuel_to) {
            modalFetchedData.fuel_to = Number(modalFetchedData.fuel_to);
        }
        modalFetchedData.courtesy_eform_div = props?.courtesyEformDivData;
        modalFetchedData.courtesy_car_resn_detail = modalFetchedData.courtesy_car_resn_detail?.replaceAll("<br/>","\n");

        reset(modalFetchedData);
        setFetchedData(modalFetchedData);
        
        // 커티시카 정보 tmp에 담아두기
        setTmpCourtesyCar({courtesy_car_no : modalFetchedData.courtesy_car_no, courtesy_car_info : modalFetchedData.courtesy_car_info});
        
        // cpo 이관 여부
        if(modalFetchedData.courtesy_car_prog_status === 'CPS00020') {
            setIsCpoTransferred(true);
        } else { 
            setIsCpoTransferred(false);
        }
        setCourtesyEformContNoTxt('');

        setIsLoading(false);
        setSkipEffect(false);

        await onCrtSearchModal({ show: false });
    }

    // 고객정보 모달에서 클릭 시 호출하는 메소드
    const inputClnInfo = (selRow) => {
        const clientPostalCode = selRow.home_postal_code? selRow.home_postal_code : selRow.comp_postal_no;
        const clientAddr1 = selRow.home_addr1? selRow.home_addr1 : selRow.comp_addr1;
        const clientAddr2 = selRow.home_addr2? selRow.home_addr2 : selRow.comp_addr2;
        setValue('client_nm', selRow.client_nm);
        setValue('driver_nm', selRow.client_nm);
        setValue('client_phone', selRow.phone_no);
        setValue('client_postal_code', clientPostalCode);
        setValue('client_addr1', clientAddr1);
        setValue('client_addr2', clientAddr2);
        setValue('in_car_no', selRow.car_no);
        clearErrors('client_nm');
        clearErrors('driver_nm');
        clearErrors('client_phone');
        clearErrors('client_postal_code');
        clearErrors('client_addr1');
        clearErrors('client_addr2');
        clearErrors('in_car_no');
    };

    // 주소 모달에서 클릭 시 호출하는 메소드
    const inputAddrInfo = (selRow) => {
        setValue('client_postal_code', selRow.zonecode);
        setValue('client_addr1', selRow.address);
        clearErrors('client_postal_code')
        clearErrors('client_addr1')
    };

    const clearValue = () => {
        if(!window.confirm("입력하신 커티시카 예약 및 계약서가 모두 초기화됩니다. 계속하시겠습니까?")) return false;

        // (1) 초기화 할 dataSEt 선택
        let resetData = defaultValues;
        setSavedTmpData(resetData);

        // (1) value 초기화
        setTmpCourtesyCar({courtesy_car_no: '', courtesy_car_info : ''});
        setIsoVin('');
        setFuelTypeNm('');
        setCourtesyEformContNoTxt('');
        
        // (2) flag 초기화
        setIsLoading(false);
        setSkipEffect(false);
        setIsReserveDtValid(true); // 대차날짜 유효성 여부
        setIsSelected(false);
        setIsNewReserve(props?.courtesyEformDivData === 'CED00010' ? true : false); 

        if(courtesyEformDiv === 'CED00020' && !props?.contractData?.formData) {
            onCrtSearchModal({show:true})
        }
        
        setIsCpoTransferred(false);
        setIsCourtesyChkboxClicked(false);
        setIsSubmitSuccessful(false); // 등록/수정 성공 여부
        setIsUpdatePossible(false); // 수정 가능 여부

        // (3) 2차 selection 초기화
        // 커티시카
        svcApi.svcApiSelectBoxCourtesyCarInfo({"showroom_no" : userInfo.showroom_no}).then((res) => {
            setCourtesyCarList(res.carList || []);
        })
        // 대차담당자
        svcApi.getEmpList({showroom_no: userInfo.showroom_no, size: 99}).then((res2) => {
            setEmpList(res2.empList);
        }).catch((err2) => {
            setAlertInfo({message: err2, isOpen: true, severity: "error"})
            setIsLoading(false);
        })
        // 수리차량차종
        setCarClassList([]);

        // (4) 초기화
        reset(resetData); 
        onStepValidation('');
    }

    // 초기정보
    const initValue = () => {
        if(!isNewReserve) {
            // (1) 초기화할 dataSet 선택
            let resetData = {};
            if(!props?.contractData?.formData) { // 계약서가 작성되지 않음
                // fetchedData.courtesy_eform_div = props?.courtesyEformDivData;
                resetData = fetchedData;
            } else { // 계약서가 작성됨
                resetData = savedData;
            }
            setSavedTmpData(resetData);
            
            // (2) value 초기화
            // 커티시카 정보 tmp에 담아두기
            setTmpCourtesyCar({courtesy_car_no : resetData.courtesy_car_no, courtesy_car_info : resetData.courtesy_car_info});
            // cpo 이관 여부
            if(resetData.courtesy_car_prog_status === 'CPS00020') {
                setIsCpoTransferred(true);
            } else { 
                setIsCpoTransferred(false);
            }

            // (2) flag 초기화
            setIsLoading(false);
            setSkipEffect(false); // useEffect 실행여부 제어 플래그 (true : effect 실행 안함)
            setIsReserveDtValid(true); // 대차날짜 유효
            setIsSelected(true); // 선택됨

            if(!props?.contractData?.formData) { // 계약서가 작성되지 않음
                setIsUpdatePossible(false); // 수정 불가능
                setIsSubmitSuccessful(false); // submit 하지 않음
                setCourtesyEformContNoTxt('');
            } else { // 계약서가 작성됨
                setIsUpdatePossible(true); // 수정 가능
                setIsSubmitSuccessful(true); // 성공적으로 submit 
                setCourtesyEformContNoTxt(savedData.courtesy_eform_cont_no);
            }

            // (3) 초기화
            reset(resetData);
        }
    }

    // 등록/수정
    const onSubmit = async (data, event) => {
        event.preventDefault();

        setLoadingInfo({isOpen:true});
        
        try {
            // step1 : validation
            if(courtesyEformDiv === 'CED00020'){
                if(!handleMileageToChange(data.mileage_to)) {
                    return false; 
                }
            }
            if(!isReserveDtValid) {
                setAlertInfo({message :'기간을 다시 확인해주세요.', isOpen:true, severity: "error"});
                return false; // 날짜 유효성
            }
            if(isSubmitSuccessful) {
                setAlertInfo({message :'수정 사항이 없습니다.', isOpen:true, severity: "error"});
                return false;
            }

            // step3 : 커티시카 계약서 등록/수정
            let trxDiv = 'insert';
            if(isUpdatePossible) {
                if(!window.confirm("계약서 정보가 변경되었습니다. 수정하시겠습니까?")) {
                    if(!window.confirm("새로운 계약서를 생성하시겠습니까?")) {
                        return false;
                    } else {
                        trxDiv = 'insert';
                    }
                } else {
                    trxDiv = 'update';
                }
            } else {
                if(!window.confirm("새로운 계약서를 생성하시겠습니까?")) return false;
            }

            // step2 : API 호출로 DB 저장
            let resultEform = "";
            const saveBody = getValues();
            saveBody.courtesy_start_rsv_time = dayjs(saveBody.courtesy_start_rsv_time).format('YYYY-MM-DD')
            saveBody.courtesy_end_rsv_time = dayjs(saveBody.courtesy_end_rsv_time).format('YYYY-MM-DD')
            saveBody.mileage_from = utils.onlyNumber(saveBody.mileage_from)
            saveBody.mileage_to = utils.onlyNumber(saveBody.mileage_to) 
            if(trxDiv === 'insert') { // 계약서 등록/수정
                saveBody.courtesy_eform_seq = '';
            }
            const response = await svcApi.svcApiRegisterReserveCourtesyCar({...saveBody, isNewReserve: isNewReserve});
            if(response) {
                if(response.trxResult === 'success') {
                    setAlertInfo({isOpen: false})
                    resultEform = response.courtesyEformDto;
                    
                    // step3 : DB저장하고 보내온 값을 상위 페이지에 보내서 저장
                    setSkipEffect(true);

                    const updatedData = {
                        ...data,
                        courtesy_eform_seq : resultEform.courtesy_eform_seq,
                        courtesy_reserve_seq : resultEform.courtesy_reserve_seq,
                        courtesy_eform_cont_no : resultEform.courtesy_eform_cont_no
                    }
                    setSavedData(updatedData); 
                    setSavedTmpData(updatedData);
                    setCourtesyEformContNoTxt(updatedData.courtesy_eform_cont_no);
                    alert('커티시카 예약을 성공적으로 저장했습니다.');
                    
                    // // step4 : 플래그 값 설정
                    setIsUpdatePossible(true);
                    setIsSubmitSuccessful(true);
                    onStepValidation(updatedData);
                    
                } else if (response.trxResult === 'check') {
                    setAlertInfo({message: '중복되는 커티시카 예약이 있습니다.', isOpen: true, severity: "error"})
                    return false;
                } else if (response.trxResult === 'registTimeFail') {
                    setAlertInfo({message: '커티시카 등록일 이전 날짜입니다.', isOpen: true, severity: "error"})
                    return false;
                } else if (response.trxResult === 'update') {
                    setAlertInfo({message: '커티시카 대차정보 수정에 실패하였습니다.', isOpen: true, severity: "error"})
                    return false;
                } else if (response.trxResult === 'insert') {
                    setAlertInfo({message: '커티시카 대차정보 등록에 실패하였습니다.', isOpen: true, severity: "error"})
                    return false;
                } else if (response.trxResult === 'eformFail') {
                    setAlertInfo({message: '커티시카 전자계약서 생성에 실패하였습니다.', isOpen: true, severity: "error"})
                    return false;
                } else if (response.trxResult === 'eformSelectFail') {
                    setAlertInfo({message: '커티시카 전자계약서 정보 조회에 실패하였습니다.', isOpen: true, severity: "error"})
                    return false;
                } else if (response.trxResult === 'attachCopyFail') {
                    setAlertInfo({message: '커티시카 전자계약서 첨부파일 복제에 실패하였습니다.', isOpen: true, severity: "error"})
                    return false;
                }
            }
            return true;
        } catch (err) {
            setAlertInfo({message :err, isOpen:true, severity: "error"});
        } finally {
            setLoadingInfo({isOpen:false});
            setSkipEffect(false);
        }
    }

    // 성공여부가 변경되었을 경우
    useEffect(() => {
        // submit 성공했을 경우
        if(isSubmitSuccessful) {
            reset(savedData); // 업데이트된 값으로 폼 입력
            // 신규 예약에서 예약 등록하고 계약서도 등록한 경우
            if (isNewReserve) {
                setIsNewReserve(false); // 기존 예약으로 변경
                setIsSelected(true); // 선택됐음으로 변경
            }
        }
        setIsNewEform(!isSubmitSuccessful);
        changeValid(isSubmitSuccessful);
    }, [isSubmitSuccessful])

    const onError = (errors, e) =>  {
        alert("입력 값을 확인하세요.");
    }

    // 로딩중에는 폼을 렌더링하지 않음
    if(isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <TextField
                    style={{'display':'none'}}
                    {...register("courtesy_eform_div")}
                />
                <Typography
                    style={{'fontSize': '22px', 'fontWeight': '500', margin: '20px 0 25px 5px'}}
                >
                    커티시카 {courtesyEformDiv==='CED00010'?'대여':'반납'} 전자계약서 {courtesyEformContNoTxt && `(${courtesyEformContNoTxt})`}
                </Typography>
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="커티시카 예약 정보" icon={<CreateIcon/>} />
                        <div className='subTitle-btn'>
                            <Stack spacing={2} direction="row">
                                <Button 
                                    variant="outlined" size='small' startIcon={<ClearIcon />}
                                    onClick={() => clearValue()}
                                >
                                    초기화
                                </Button>
                                {(isSelected) && (
                                <Button 
                                    variant="outlined" size='small' startIcon={<RestoreIcon />}
                                    onClick={() => initValue()}
                                >
                                    {courtesyEformContNoTxt} 초기정보
                                </Button>
                                )}
                                {(!isSelected && courtesyEformDiv === 'CED00010') && (
                                <Button 
                                    variant="outlined" size='small' startIcon={<SearchIcon />}
                                    onClick={() => onCrtSearchModal({show:true})}
                                >
                                    대차예약 조회
                                </Button>
                                )}
                            </Stack>
                        </div>
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }} >
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                                name="showroom_no" 
                                control={control}
                                data={showRoomList}
                                value="showroom_no"
                                text="showroom_nm" 
                                label="서비스센터"
                                className='required'
                                defaultMessage={"선택"}
                                readOnly={!selectAuth}
                                rules={{required: "서비스센터를 선택하세요."}}
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.showroom_no && <WarningTextBox>{errors?.showroom_no?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                                name="emp_id"
                                control={control}
                                data={empList}
                                value="emp_id" 
                                text="emp_nm" 
                                label="대차담당자"
                                className='required'
                                defaultMessage={"선택"}
                                rules={{required: "대차담당자를 선택하세요."}}
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.emp_id && <WarningTextBox>{errors?.emp_id?.message}</WarningTextBox>}
                        </Grid>
                        {/*만료되지 않은 커티시카일 경우 */}
                        {!isCpoTransferred &&
                            <Grid xs={12} sm={12} md={12}>
                                <CPSelectTag 
                                    name="courtesy_car_no" 
                                    control={control}
                                    data={courtesyCarList}
                                    value="sel_cd"
                                    text="sel_nm" 
                                    label="커티시카"
                                    fullWidth
                                    className='required'
                                    defaultMessage={"선택"}
                                    rules={{required: "커티시카를 선택하세요."}}
                                    disabled={courtesyEformDiv === 'CED00020'}
                                />
                                {errors?.courtesy_car_no && <WarningTextBox>{errors?.courtesy_car_no?.message}</WarningTextBox>}
                            </Grid>        
                        }
                        {/*만료된 커티시카일 경우 */}
                        {isCpoTransferred &&
                            <Grid xs={12} sm={12} md={12}>
                                {!isCourtesyChkboxClicked && 
                                    <CPTextField
                                        label="커티시카" 
                                        id="courtesy_car_info" 
                                        disabled={true}
                                    />
                                }
                                {isCourtesyChkboxClicked && 
                                    <>
                                    <CPSelectTag 
                                        name="courtesy_car_no" 
                                        control={control}
                                        data={courtesyCarList}
                                        value="sel_cd"
                                        text="sel_nm" 
                                        label="커티시카"
                                        fullWidth
                                        className='required'
                                        defaultMessage={"선택"}
                                        rules={{required: "커티시카를 선택하세요."}}
                                        disabled={courtesyEformDiv === 'CED00020'}
                                    />
                                    {errors?.courtesy_car_no && <WarningTextBox>{errors?.courtesy_car_no?.message}</WarningTextBox>}
                                    </>
                                }
                                <FormControlLabel control={<Checkbox size="small" />} name="courtesy_car_no_chkbox" label="커티시카 변경(CPO 이관 차량)" onChange={(e) => changeCourtesyCar(e)}/>
                                {!isCourtesyChkboxClicked && <HelperTextBox sx={{color: "#d32f2f", margin: "-5px 0px 0px -5px !important", width: "100% !important"}}>{"해당 예약의 커티시카는 CPO 이관처리된 차량입니다. 커티시카 변경을 원하실 경우 위 체크박스를 클릭해주세요. 커티시카 변경 후에는 CPO 이관된 커티시카를 재선택할 수 없습니다."}</HelperTextBox> }
                                {isCourtesyChkboxClicked && <HelperTextBox sx={{color: "#1976d2", margin: "-5px 0px 0px -5px !important", width: "100% !important"}}>{"커티시카 변경을 선택하셨습니다. 기존 CPO 이관처리된 차량으로 변경을 원하실 경우 위 체크박스를 클릭해주세요."}</HelperTextBox> }
                            </Grid>
                        }    
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                label="커티시카FIN"
                                id="iso_vin"
                                value={isoVin}
                                disabled={true}
                            />
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                label="유종"
                                id="fuel_type_nm"
                                value={fuelTypeNm}
                                disabled={true}
                            />
                        </Grid>
                        <Grid container xs={12} >
                            <Grid xs={xsVal} sm={smVal} md={mdVal}>
                                <CPDatePicker
                                    name="courtesy_start_rsv_time"
                                    label="대여시작일" 
                                    control={control} 
                                    onAccept={(value) => {
                                        if(courtesyStartRsvTime && courtesyEndRsvTime && courtesyEndRsvTime < value){
                                            setValue("courtesy_end_rsv_time",value);
                                        };
                                        clearErrors('courtesy_start_rsv_time');
                                    }}
                                    className='required'
                                    rules={{required: "대여시작일을 선택하세요."}}
                                    disabled={courtesyEformDiv === 'CED00020'}
                                />
                                {errors?.courtesy_start_rsv_time && <WarningTextBox>{errors?.courtesy_start_rsv_time?.message}</WarningTextBox>}                              
                            </Grid>
                            <Grid xs={6} sm={2} md={2}>
                                <CPToggleButtonGroup2 
                                    name="courtesy_start_rsv_gubun"
                                    control={control}
                                    label="대여시작일구분"
                                    value={getValues("courtesy_start_rsv_gubun")}
                                    className="required"
                                    data={rsvGubunList}
                                    rules={{required: "구분을 선택하세요."}}
                                    onChange={(newValue) => {
                                        if(courtesyStartRsvTime && courtesyEndRsvTime && dayjs(courtesyStartRsvTime).format('YYYY-MM-DD') === dayjs(courtesyEndRsvTime).format('YYYY-MM-DD')){
                                            if(newValue === 'pm' && courtesyEndRsvGubun === 'am'){
                                                setValue('courtesy_end_rsv_gubun','pm');
                                            }
                                        }
                                        setValue("courtesy_start_rsv_gubun", newValue)
                                    }}
                                    disabled={courtesyEformDiv === 'CED00020'}
                                    fullWidth
                                />
                                {errors?.courtesy_start_rsv_gubun && <WarningTextBox>{errors?.courtesy_start_rsv_gubun?.message}</WarningTextBox>}                              
                            </Grid>
                            <Grid xs={xsVal} sm={smVal} md={mdVal}>
                                <CPDatePicker
                                    label="대여종료일" 
                                    name="courtesy_end_rsv_time"
                                    control={control} 
                                    minDate={courtesyStartRsvTime}
                                    className='required'
                                    rules={{required: "대여종료일을 선택하세요."}}
                                    onAccept={() => {
                                        clearErrors('courtesy_end_rsv_time');
                                    }}
                                />
                                {errors?.courtesy_end_rsv_time && <WarningTextBox>{errors?.courtesy_end_rsv_time?.message}</WarningTextBox>}                              
                            </Grid>
                            <Grid xs={6} sm={2} md={2}>
                                <CPToggleButtonGroup2
                                    label="대여종료일구분"
                                    name="courtesy_end_rsv_gubun"
                                    control={control}
                                    value={getValues("courtesy_end_rsv_gubun")}
                                    className="required"
                                    data={rsvGubunList}
                                    rules={{required: "구분을 선택하세요."}}
                                    onChange={(newValue) => {
                                        if(courtesyStartRsvTime && courtesyEndRsvTime && dayjs(courtesyStartRsvTime).format('YYYY-MM-DD') === dayjs(courtesyEndRsvTime).format('YYYY-MM-DD')){
                                            if(newValue === 'am' && courtesyStartRsvGubun === 'pm'){
                                                setValue('courtesy_start_rsv_gubun','am');
                                            }
                                        }
                                        setValue("courtesy_end_rsv_gubun", newValue);
                                    }}
                                    fullWidth
                                />
                                {errors?.courtesy_end_rsv_gubun && <WarningTextBox>{errors?.courtesy_end_rsv_gubun?.message}</WarningTextBox>}                              
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                            <CPToggleButtonGroup 
                                name="courtesy_car_gubun"
                                control={control}
                                label="대차구분"
                                value={getValues("courtesy_car_gubun")}
                                className="required"
                                data={courtesyCarGubun}
                                rules={{required: "대차구분을 선택하세요."}}
                                onChange={(newValue) => setValue("courtesy_car_gubun",newValue)}
                                fullWidth
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.courtesy_car_gubun && <WarningTextBox>{errors?.courtesy_car_gubun?.message}</WarningTextBox>}                              
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                            <CPToggleButtonGroup 
                                name="courtesy_car_purpose"
                                control={control}
                                label="대차목적"
                                value={getValues("courtesy_car_purpose")}
                                className="required"
                                data={courtesyCarPurpose}
                                rules={{required: "대차목적을 선택하세요."}}
                                onChange={(newValue) => setValue("courtesy_car_purpose",newValue)}
                                fullWidth
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.courtesy_car_purpose && <WarningTextBox>{errors?.courtesy_car_purpose?.message}</WarningTextBox>}                              
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                            <CPTextField
                                control={control}
                                name="courtesy_car_resn_detail"
                                multiline
                                rows={3}
                                variant="filled"
                                label="대차목적상세"
                                disabled={courtesyEformDiv === 'CED00020'}
                                maxLength="1000"
                            />
                        </Grid>
                    </Grid>
                </ContentPaper>
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="고객정보" icon={<CreateIcon/>}/>   
                        <span style={{marginLeft: '5px'}}>
                            <Typography style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}>
                                ※ 입력하신 고객 연락처로 <b>전자계약서 정보 확인 알림톡</b>이 발송됩니다.
                            </Typography>
                        </span>
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="client_nm"
                                rules={{required: "고객명을 선택하세요."}}
                                label="고객명"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                maxLength="16"
                                endAdornment={{onClick : () =>onClnSearchModal({show:true}), icon: <SearchIcon />}}
                                inputProps={{maxLength:16}}
                            />
                            {errors?.client_nm && <WarningTextBox>{errors?.client_nm?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="client_phone"
                                rules={{
                                    required: "전화번호를 입력하세요",
                                    pattern: {value: utils.regExp("phone2"), message: "전화번호 형식을 확인하세요. (하이픈 필수)"},
                                    onChange: (e) => {setValue("client_phone", e.target.value)}}}
                                maxLength="13"
                                label="고객연락처"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.client_phone && <WarningTextBox>{errors?.client_phone?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="driver_nm"
                                rules={{required:"운전자명을 입력하세요."}}
                                maxLength="16"
                                label="운전자명"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.driver_nm && <WarningTextBox>{errors?.driver_nm?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="client_postal_code"
                                rules={{
                                    required:"주소를 입력하세요.",
                                    onChange:(e) => setValue("client_postal_code", e.target.value)}}
                                label="우편번호"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                readOnly={true}
                                endAdornment={{onClick: () => onAddrSearchModal(true), icon: <SearchIcon />}}
                            />
                            {errors?.client_postal_code && <WarningTextBox>{errors?.client_postal_code?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={12} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="client_addr1"
                                rules={{
                                    required:"주소를 입력하세요.",
                                    onChange:(e) => setValue("client_addr1", e.target.value)
                                }}
                                label="고객주소"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                readOnly={true}
                            />
                            {errors?.client_addr1 && <WarningTextBox>{errors?.client_addr1?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                            <CPTextField
                                control={control}
                                name="client_addr2"
                                rules={{required:"주소를 전부 입력하세요."}}
                                label="고객주소상세"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                maxLength="35"
                            />
                            {errors?.client_addr2 && <WarningTextBox>{errors?.client_addr2?.message}</WarningTextBox>}
                        </Grid>
                    </Grid>
                    <div className='subTitle' style={{marginTop: '20px'}}>
                        <TitleChip label="고객수리차량" icon={<CreateIcon/>}/>   
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }} >
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                                name="in_car_model_no" 
                                control={control}
                                data={carModelList}
                                value="model_no"
                                text="model_nm" 
                                label="수리차량모델"
                                className='required'
                                defaultMessage={"선택"}
                                rules={{required: "수리차량모델을 선택하세요."}}
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.in_car_model_no && <WarningTextBox>{errors?.in_car_model_no?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPSelectTag 
                                name="in_car_class_no" 
                                control={control}
                                data={carClassList}
                                value="class_no"
                                text="class_nm" 
                                label="수리차량차종"
                                className='required'
                                defaultMessage={"선택"}
                                rules={{required: "수리차량차종을 선택하세요."}}
                                disabled={courtesyEformDiv === 'CED00020'}
                            />
                            {errors?.in_car_class_no && <WarningTextBox>{errors?.in_car_class_no?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="in_car_no"
                                rules={{required:"수리차량번호를 입력하세요."}}
                                label="수리차량번호"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                maxLength="16"
                            />
                            {errors?.in_car_no && <WarningTextBox>{errors?.in_car_no?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={xsVal} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="wip_no"
                                rules={{
                                    required:"WIP번호를 입력하세요.",
                                    onChange: (e) => {setValue("wip_no", utils.onlyNumber(e.target.value))}}}
                                label="WIP번호"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                maxLength="10"
                            />
                            {errors?.wip_no && <WarningTextBox>{errors?.wip_no?.message}</WarningTextBox>}
                        </Grid>
                    </Grid>
                </ContentPaper>
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="대여 차량 확인사항" icon={<CreateIcon/>}/>   
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }}  alignItems="center">
                        <Grid xs={12} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="mileage_from"
                                rules={{
                                    required:courtesyEformDiv === 'CED00020'?false:"출발주행거리를 입력하세요.",
                                    onChange:(e) => setValue("mileage_from", utils.setComma(e.target.value))
                                }}
                                label="출발주행거리"
                                className="required"
                                disabled={courtesyEformDiv === 'CED00020'}
                                endAdornment={{text:'km'}}
                            />
                            {errors?.mileage_from && <WarningTextBox>{errors?.mileage_from?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={12} sm={8} md={8}>
                            <Controller
                                name="fuel_from"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Slider
                                        value={value}
                                        onChange={(e, newValue) => onChange(newValue)}
                                        valueLabelDisplay="auto"
                                        step={10}
                                        min={0}
                                        max={100}
                                        marks={fuelSteps}
                                        disabled={courtesyEformDiv === 'CED00020'}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </ContentPaper>
                {(courtesyEformDiv === 'CED00020') &&
                <ContentPaper elevation={3}>
                    <div className='subTitle'>
                        <TitleChip label="반납 차량 확인사항" icon={<CreateIcon/>}/>   
                    </div>
                    <Grid container spacing={{ xs: 2, md: 3 }}  alignItems="center">
                        <Grid xs={12} sm={smVal} md={mdVal}>
                            <CPTextField
                                control={control}
                                name="mileage_to"
                                rules={{
                                    required:courtesyEformDiv === 'CED00010'?false:"도착주행거리를 입력하세요.",
                                    onChange:(e) => setValue("mileage_to", utils.setComma(e.target.value)),
                                    onBlur:(e) => handleMileageToChange(e.target.value)
                                }}
                                label="도착주행거리"
                                className={courtesyEformDiv === 'CED00010'?"":"required"}
                                disabled={courtesyEformDiv === 'CED00010'}
                                endAdornment={{text:'km'}}
                            />
                            {errors?.mileage_to && <WarningTextBox>{errors?.mileage_to?.message}</WarningTextBox>}
                        </Grid>
                        <Grid xs={12} sm={8} md={8}>
                            <Controller
                                name="fuel_to"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Slider
                                        value={value}
                                        onChange={(e, newValue) => onChange(newValue)}
                                        valueLabelDisplay="auto"
                                        step={10}
                                        min={0}
                                        max={100}
                                        marks={fuelSteps}
                                        disabled={courtesyEformDiv === 'CED00010'}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </ContentPaper>
                }
                {isCrtModalOpen && <CPCrtSearchModal {...crtModalInfo} onCrtSearchModal={onCrtSearchModal} inputCrtInfo={inputCrtInfo}/>}
                {isClnModalOpen && <CPSvcClnSearchModal {...clnModalInfo} onClnSearchModal={onClnSearchModal} inputClnInfo={inputClnInfo} />}
                {isAddrModalOpen && <CPDaumPostCodeModal onAddrSearchModal={onAddrSearchModal} inputAddrInfo={inputAddrInfo} />}
                <div>
                    <Button 
                        fullWidth 
                        variant="contained" 
                        size="large" 
                        type="submit"
                        disabled={isSubmitSuccessful}
                    >
                        {!isSubmitSuccessful && "커티시카 예약 저장"}
                        {isSubmitSuccessful && "커티시카 예약 완료"}
                    </Button>
                </div>
            </form>
        </div> 
    ) 
});
export default CrtsyFormInfoStep;