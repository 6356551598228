import React, {useState, useEffect} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';

const CustomToggleButton = styled(ToggleButton)({
    color: '#1976d2',
    borderColor: '#1976d2',
    minWidth : "fit-content",

    "&.Mui-selected": {
        backgroundColor: "#1976d2",
        color: "#fff",
        "&.Mui-disabled": {
            backgroundColor: "#0000001f",
            color: "#00000042",
          },
      },
    "&:hover": {
        backgroundColor: "#1976d21a",
        "&.Mui-selected": {
            backgroundColor:"#1565c0",
        }
      },

});

function CPToggleButtonGroup(props) {
    const {label, value, data, onChange, className, control, name, rules, readOnly, fullWidth, disabled} = props;

    const handleChange = (newValue) => {
        if(newValue !== null && onChange !== null) {
            onChange(newValue);
        }
    }

    return (
        <div>
            {control && (
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({field}) => (
                        <ToggleButtonGroup
                            {...field}
                            exclusive
                            size="small"
                            aria-label={label}
                            fullWidth={fullWidth}
                            disabled={Object.is(disabled,true)?true:false}
                            >
                            {data?.map((item, index) => (
                                <CustomToggleButton
                                    key={index}
                                    value={item.detail_cd_id}
                                    onClick={() => {
                                        if(!readOnly){
                                            field.onChange(item.detail_cd_id);
                                            handleChange(item.detail_cd_id);
                                        }
                                    }}
                                >
                                    {item.cd_nm}
                                </CustomToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                    />
            )}
        </div>
    );
}

export default CPToggleButtonGroup;