import React, { useState, useRef, useEffect } from 'react';

/* 외부 */
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/* 내부 */
import 'styles/base.css';
import 'styles/ewMng/EwForm.css';
import * as svcApi from 'services/apis/crm4u/svcApi';
import { userState } from 'stores/UserState';
import { alertState, loadingState } from 'stores/ComponentState';
import CPHorizontalLinearStepper from 'components/comm/CPHorizontalLinearStepper';
import CrtsyFormInfoStep from 'pages/contract/crtsy/CrtsyFormInfoStep';
import CrtsyFormUploadStep from 'pages/contract/crtsy/CrtsyFormUploadStep';
import CrtsyFormCheckStep from 'pages/contract/crtsy//CrtsyFormCheckStep';
import CPManualBtn from 'components/comm/CPManualBtn';

function CrtsyForm() {
    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    const location = useLocation();
    const navigate = useNavigate();

    const [contractData, setContractData] = useState({}); // 계약서 정보
    const courtesyEformDiv = location?.pathname === '/crtsy/write/reserve'?'CED00010':'CED00020'; // 전자계약서 구분

    const [steps, setSteps] = useState([
        {label : '계약서정보', optioinal : false, nextOnSubmit : true, isValid : false},
        {label : '외관사진', optional : false, nextOnSubmit : false, isValid : false},
        {label : '외관점검', optional: false, nextOnSubmit : true, isValid : false}
    ]);

    // const formInfoRef = useRef(null); // 자식js 함수 호출하도록

    // 각 step 완료 시 처리할 작업 설정
    const handleStepComplete = async (stepIndex) => {
        switch(stepIndex) {
            case 0:
                return true;
            case 1:
                return true;
            case 2:
                return true;
            default:
                return true;
        }
    }

    // 전체 완료 시 전자계약서로 보내기
    const handleFinalSubmit = async () => {
        if(!window.confirm(contractData.formData.courtesy_eform_cont_no + "번 전자계약서 정보 확인 알림톡을 전송하시겠습니까?")) return;
        setLoadingInfo({isOpen:true});
        try {
            const requestBody = contractData.formData;
            // 모든 데이터가 있는지 최종 확인
            if (!requestBody) {
                setLoadingInfo({isOpen:false});
                throw new Error("모든 단계를 완료해주세요.");
            }

            // API 호출 등 최종 처리
            svcApi.svcApiCreateSignok(requestBody).then((res) => {
                // 완료 시 응답 결과에 따른 조치 구현
                if(res.trxResultCode === '0000') {
                    alert(res.trxResult);
                    navigate('/home');
                } else {
                    alert(res.trxResult);
                }
            }).catch((err) => {
                setAlertInfo({message: err, isOpen: true})
            }).finally(() => {
                setLoadingInfo({isOpen:false});
            })

        } catch (err) {
            setAlertInfo({message: err.message, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    return (
        <div>
            <CPHorizontalLinearStepper 
                steps = {steps}
                onStepComplete = {handleStepComplete}
                children = {(activeStep) => (
                    <div>
                        {activeStep === 0 && 
                            <CrtsyFormInfoStep 
                                contractData={contractData}
                                courtesyEformDivData={courtesyEformDiv}
                                // ref={formInfoRef} // 자식js 함수 호출하도록
                                onStepValidation={(formData) => {
                                    setContractData({ formData: formData});
                                    return true;
                                }}
                                changeValid={(isValid) => {
                                    setSteps((prevSteps) => {
                                        const newSteps = [...prevSteps];
                                        newSteps[activeStep].isValid = isValid;
                                        return newSteps;
                                    });
                                }}
                            />
                        }
                        {activeStep === 1 &&
                            <CrtsyFormUploadStep 
                                contractData={contractData}
                            />
                        }
                        {activeStep === 2 && 
                            <CrtsyFormCheckStep
                                contractData={contractData}
                                changeValid={(isValid) => {
                                    setSteps((prevSteps) => {
                                        const newSteps = [...prevSteps];
                                        newSteps[activeStep].isValid = isValid;
                                        return newSteps;
                                    });
                                }}
                            />
                        }
                    </div>
                )}
                finalPage = {false}
                // finalMsg = {contractData?.formData?.courtesy_eform_cont_no +' 전자계약서 전송'}
                onFinalSubmit = {handleFinalSubmit}
                resetable = {false}
            />
            {/* <CPManualBtn url={'https://dev.kcc.co.kr/wiki/pages/viewpage.action?pageId=45299965'}/> */}
        </div>   
    )
}

export default CrtsyForm;