import React, { useEffect, useState, useRef } from 'react';

/* 외부 */
import CanvasDraw from 'react-canvas-draw';
import { useSetRecoilState } from 'recoil';

/* mui */
import{ TitleChip, ContentPaper} from 'styles/ewMng/EwFormStyle';
import { Button, Stack, Typography } from '@mui/material';
/*  mui icon */
import CreateIcon from '@mui/icons-material/Create';

/* 내부 */
import { alertState, loadingState } from 'stores/ComponentState';
import * as svcApi from 'services/apis/crm4u/svcApi';
import crtsyCheckImage from 'assets/images/crtsy/crtsyCheckImage.png';


function CrtsyFormCheckStep({ changeValid, ...props }) {

    // common util
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    
    // ref
    const imgRef = useRef(null);
    const canvasRef = useRef(null);
    const crtsyCheck = 'crtsy_check_drawing';

    // image related
    const [combinedData, setCombinedData] = useState(null); // 합성된 이미지 URL
    const [savedFileCnt, setSavedFileCnt] = useState(0); // 저장된 이미지 파일 개수

    // flag
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false); // 등록/수정 성공 여부

    // info에서 받아온 정보
    const courtesyEformSeq = props?.contractData?.formData.courtesy_eform_seq || '';
    const courtesyEformContNo = props?.contractData?.formData.courtesy_eform_cont_no || '';
    const courtesyEformDiv = props?.contractData?.formData.courtesy_eform_div || '';

    // 초기 진입시
    useEffect(() => {
        setLoadingInfo({isOpen:true});

        if(props?.contractData?.formData){
            const formData = new FormData();
            formData.append('courtesyEformSeq', courtesyEformSeq);
            formData.append('crtsyCheck', crtsyCheck);
            svcApi.svcApiGetCourtesyCarAttachment(formData)
                .then((res) => {
                    setSavedFileCnt(res.file_count)
                    if(res.file_count > 0) {
                        const processedImages = res.fileInfoList
                            .map(processImageFile)
                            .filter(Boolean);
                        imgRef.current.src = processedImages[0].img; // 이미지 교체
                        setIsSubmitSuccessful(true)
                    }
                })
                .catch((error) => {
                    console.log('이미지 로딩 중 에러 : ' + error);
                })
                .finally(() => {
                    setLoadingInfo({isOpen:false});
                });
        } else {
            setAlertInfo({message: "전자계약서 정보를 찾을 수 없습니다. 관리자에게 문의하세요.", isOpen: true})
            setLoadingInfo({isOpen:false});
        };
    }, [])

    // 합성된 이미지 준비됐을 경우 (onSubmit에서 합성함)
    useEffect(() => {
        if(combinedData) {
            saveCombinedImage(combinedData);
        }
    }, [combinedData])

    // submit이 성공적으로 됐을 경우 valid 처리
    useEffect(() => {
        if(isSubmitSuccessful) {
            changeValid(true);

            // 저장된 외관점검 불러오기
            const formData = new FormData();
            formData.append('courtesyEformSeq', courtesyEformSeq);
            formData.append('crtsyCheck', crtsyCheck);
            svcApi.svcApiGetCourtesyCarAttachment(formData)
                .then((res) => {
                    setSavedFileCnt(res.file_count)
                    if(res.file_count > 0) {
                        const processedImages = res.fileInfoList
                            .map(processImageFile)
                            .filter(Boolean);
                        imgRef.current.src = processedImages[0].img; // 이미지 교체
                        setIsSubmitSuccessful(true)
                    }
                })
                .catch((error) => {
                    console.log('이미지 로딩 중 에러 : ' + error);
                })
                .finally(() => {
                    setLoadingInfo({isOpen:false});
                });
        } else {
            changeValid(false)
        }
    }, [isSubmitSuccessful])

    // 합성된 이미지 저장하기
    const saveCombinedImage = async (imageData) => {
        try{
            const base64Response = await fetch(imageData);
            const blob = await base64Response.blob();
            const formData = new FormData();
            formData.append('files', blob, courtesyEformSeq+'_check.png');
            formData.append('courtesyEformSeq', courtesyEformSeq);
            formData.append('crtsyCheck', crtsyCheck);
            const response = await svcApi.svcApiUploadCourtesyCarAttachment(formData);
            if (response.trxResultCode === "0000") { 
                alert("외관사진 업로드에 성공했습니다.");
                setIsSubmitSuccessful(true);
            }
        } catch (error) {

        }
    }

    // 이미지 처리 함수
    const processImageFile = (file) => {
        if (!file.upload_file_nm) return null;
        
        const pathParts = file.upload_file_nm.split('/upload/');
        const imagePath = pathParts[1];
        const imageUrl = `${process.env.REACT_APP_CRM_UPLOAD_URL}/upload/${imagePath}`;

        return {
            img: imageUrl,
            title: file.org_file_nm,
            fileNo: file.file_no
        };
    }

    // 드로잉 빈값 검증
    const isDrawingPresent = () => {
        const drawingData = canvasRef.current.getSaveData(); // 드로잉 JSON 데이터 가져오기
        try {
          const parsedData = JSON.parse(drawingData);
          return parsedData.lines && parsedData.lines.length > 0; // 드로잉 라인이 있는지 확인
        } catch (error) {
          console.error("드로잉 데이터 파싱 중 오류 발생:", error);
          return false;
        }
    }

    // 드로잉 초기화
    const clearCanvas = () => {
        if(savedFileCnt > 0) {
            if(!window.confirm('초기화시 기존 저장된 외관점검 내용이 사라집니다. 계속하시겠습니까?')) return;
            setSavedFileCnt(0);
            imgRef.current.src = crtsyCheckImage;
            setIsSubmitSuccessful(false);
        } else {
            if(!window.confirm('입력하신 외관점검을 초기화 하시겠습니까?')) return;
            canvasRef.current.clear();
        }
    };

    // 드로잉 한 획 취소
    const undoLastStroke = () => {
        canvasRef.current.undo();
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if(!window.confirm("커티시카 외관점검을 저장하시겠습니까?")) return;
        
        setLoadingInfo({isOpen:true});

        try {
            // 1. 드로잉 입력 검사
            if(!isDrawingPresent()){
                alert('외관점검을 입력해주세요.');
                return false;
            }

            // 2. 이미지와 드로잉 합성
            // 드로잉
            const canvas = canvasRef.current.canvasContainer.children[1];
            const canvasDataURL = canvas.toDataURL('image/png');

            // 이미지
            const imgDataURL = imgRef.current.src;

            // 합성 캔버스
            const combinedCanvas = document.createElement('canvas');
            const ctx = combinedCanvas.getContext('2d');
            
            // 기존 이미지 크기 설정 (이미지 및 캔버스 크기 설정)
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            combinedCanvas.width = canvasWidth;
            combinedCanvas.height = canvasHeight;

            // 합성
            const imgElement = new Image();
            // imgElement.crossOrigin = 'Anonymous'; // CORS 속성 추가
            imgElement.src = imgDataURL;
            imgElement.onload = () => {
                ctx.drawImage(imgElement, 0, 0, canvasWidth, canvasHeight);
                const canvasImage = new Image();
                // canvasImage.crossOrigin = 'Anonymous'; // CORS 속성 추가
                canvasImage.src = canvasDataURL;
                canvasImage.onload = () => {
                    ctx.drawImage(canvasImage, 0, 0, canvasWidth, canvasHeight);
                    const combinedDataURL = combinedCanvas.toDataURL('image/png');
                    setCombinedData(combinedDataURL);
                }
            }
        } catch (err) {
            setAlertInfo({message :err, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    return (
        <div>
            <form onSubmit={onSubmit}>
            <Typography
                style={{'fontSize': '22px', 'fontWeight': '500', margin: '20px 0 25px 5px'}}
            >
                커티시카 {courtesyEformDiv==='CED00010'?'대여':'반납'} 전자계약서 {courtesyEformContNo && `(${courtesyEformContNo})`}
            </Typography>
                <ContentPaper elevation={3}>
                    <div className='subTitle' style={{display:'flex', flexDirection: 'column', alignItems : 'flex-start'}}>
                        <TitleChip label="커티시카 외관점검" icon={<CreateIcon/>}/>
                        <span style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}>(V : 스크래치 / X : 파손 / O : 찌그러짐 / □ : 변색)</span>
                        {savedFileCnt > 0 && <span style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#1976d2'}}>※ 저장된 외관점검 내용입니다. 다시 그리길 원하신다면 초기화 버튼을 눌러주세요.</span>}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            alignItems: 'center',
                            position: 'relative',
                            minHeight: '150px'
                        }}
                    >
                        <img 
                            ref={imgRef}
                            src={crtsyCheckImage} 
                            alt='커티시카외관점검이미지'
                            style={{
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                zIndex: 1
                            }}
                        />
                        <div 
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                minHeight: "150px",
                                top: 0,
                                left: 0,
                                zIndex: 2,
                                touchAction: 'none' // 터치 스크롤 비활성화
                            }}
                            onTouchStart={(e) => {
                                if (e.touches.length > 1) {
                                    e.preventDefault();
                                }
                            }}
                            onTouchMove={(e) =>  {
                                if (e.touches.length > 1) {
                                    e.preventDefault();
                                }
                            }}
                        >
                            {savedFileCnt === 0 &&
                                <CanvasDraw
                                    ref={canvasRef}
                                    style = {{ 
                                        backgroundColor: 'rgba(255, 255, 255, 0)' ,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    canvasStyle={{
                                        touchAction: 'none'
                                    }}
                                    canvasWidth={window.innerWidth * window.devicePixelRatio} // 픽셀 밀도에 맞춤
                                    canvasHeight={window.innerHeight * window.devicePixelRatio}
                                    brushColor="red"
                                    brushRadius={1}
                                    lazyRadius={1}
                                />
                            }
                        </div>
                    </div>
                </ContentPaper>
                <Stack 
                    direction="row"
                    spacing={2} 
                    style={{
                        marginTop: '15px',
                        marginBottom: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Button type="button" onClick={clearCanvas} variant="outlined">초기화</Button>
                    {(savedFileCnt === 0 && !isSubmitSuccessful) && <Button type="button" onClick={undoLastStroke} variant="outlined">한 획 취소</Button>}
                    {(savedFileCnt === 0 && !isSubmitSuccessful) && <Button type="submit" variant="contained">외관점검 저장</Button> }
                </Stack>
            </form>
        </div>
    )
}

export default CrtsyFormCheckStep;