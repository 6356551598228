import { useState } from "react";
import CrtsyClientVerification from "./CrtsyClientVerification";
import CrtsyContractInfo from "./CrtsyContractInfo";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: 'Pretendard GOV'
    },
    components : {
        MuiTypography : {
            styleOverrides: {
                h1: {
                    fontWeight: 'bold',
                    '@media (min-width:0px)': {
                        fontSize:'24px',
                    },
                    '@media (min-width:400px)': {
                        fontSize:'28px',
                    },
                    '@media (min-width:600px)': {
                        fontSize:'32px',
                    },
                    '@media (min-width:800px)': {
                        fontSize:'36px',
                    },        
                    '@media (min-width:1200px)': {
                        fontSize:'40px',
                    },
                },
                h2: {
                    fontWeight: 'bold',
                    '@media (min-width:0px)': {
                        fontSize:'20px',
                    },
                    '@media (min-width:400px)': {
                        fontSize:'24px',
                    },
                    '@media (min-width:600px)': {
                        fontSize:'28px',
                    },
                    '@media (min-width:800px)': {
                        fontSize:'32px',
                    },        
                },
                h3: {
                    fontWeight: '600',
                    '@media (min-width:0px)': {
                        fontSize:'16px',
                    },
                    '@media (min-width:400px)': {
                        fontSize:'20px',
                    },
                    '@media (min-width:600px)': {
                        fontSize:'24px',
                    },
                    '@media (min-width:800px)': {
                        fontSize:'28px',
                    },        
                },
                body1: {
                    'fontWeight': 500,
                    '@media (min-width:0px)': {
                        fontSize:'16px',
                    },
                    '@media (min-width:400px)': {
                        fontSize:'18px',
                    },
                    '@media (min-width:600px)': {
                        fontSize:'20px',
                    },
                    '@media (min-width:800px)': {
                        fontSize:'22px',
                    },        
                },
                body2: {
                    '@media (min-width:0px)': {
                        fontSize:'12px',
                    },
                    '@media (min-width:400px)': {
                        fontSize:'14px',
                    },
                    '@media (min-width:600px)': {
                        fontSize:'16px',
                    },
                    '@media (min-width:800px)': {
                        fontSize:'18px',
                    },        
                },
                caption: {
                    color: '#0009',
                    '@media (min-width:0px)': {
                        fontSize:'10px',
                    },
                    '@media (min-width:300px)': {
                        fontSize:'12px',
                    },
                    '@media (min-width:900px)': {
                        fontSize:'14px',
                    },
                }
            }
        },
    }
});

function CrtsyClientStep() {
    const [step, setStep] = useState(1);
    const [crtsyContractInfo, setCrtsyContractInfo] = useState();

    const nextStep = (crtsyContractInfo) => {
        if(crtsyContractInfo) setCrtsyContractInfo(crtsyContractInfo);
        setStep(prev => prev + 1);
    }
    
    return (
        <ThemeProvider theme={theme}>
            {step === 1 && <CrtsyClientVerification nextStep={nextStep} />}
            {step === 2 && <CrtsyContractInfo templateInfo={crtsyContractInfo.templateInfo} eformInfo={crtsyContractInfo.eformInfo} images={crtsyContractInfo.images} nextStep={nextStep} />}
        </ThemeProvider>
    );
}

export default CrtsyClientStep;