import React, { useEffect, useState, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.locale('ko');

    function CPDatePicker(props) {
        const [open, setOpen] = useState(false);

        const handleDatePicker = (value) => {
            if(!props.readOnly) if(!Object.is(props.disabled,true)) setOpen(value);
        };

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            {props.control && (
                <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                render={({ field }) => (
                        <DatePicker {...field}
                                    label={props.label}
                                    open={open}
                                    onOpen={() => handleDatePicker(true)}
                                    onClose={() => handleDatePicker(false)}
                                    onChange={(e) => {field.onChange(e); props.onChange && props.onChange(e)}}
                                    onAccept={(e) => {props.onAccept && props.onAccept(e)}}
                                    format="YYYY-MM-DD"
                                    minDate={props.minDate}
                                    maxDate={props.maxDate}
                                    className={Object.is(props.disabled,true)?"":props.className}
                                    readOnly={props.readOnly}
                                    disabled={Object.is(props.disabled,true)?true:false}
                                    slotProps={{ textField: { InputLabelProps: { shrink: true }, 
                                                            helperText: props.helperText, 
                                                            required:props.required, 
                                                            size:'small',
                                                            error: false,
                                                            InputProps: { 
                                                                endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EventIcon/>
                                                                </InputAdornment>
                                                                )},
                                                            onClick: () => handleDatePicker(true)
                                                            },
                                            }}
                                    sx={{
                                    width: '100%'
                                    }}
                        />
                )}
                />
            )}
            {!props.control && (
                <DatePicker 
                    label={props.label}
                    open={open}
                    onOpen={() => handleDatePicker(true)}
                    onClose={() => handleDatePicker(false)}
                    onChange={(e) => {props.onChange && props.onChange(e)}}
                    onAccept={(e) => {props.onAccept && props.onAccept(e)}}
                    format="YYYY-MM-DD"
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    readOnly={props.readOnly}
                    disabled={Object.is(props.disabled,true)?true:false}
                    sx={{
                        ...props.sx,
                        '& .MuiInputBase-input': {
                            cursor: 'pointer'
                        },
                        '& .Mui-readOnly': {
                            backgroundColor: 'white'
                        }
                    }}
                    className={Object.is(props.disabled,true)?"":props.className}
                    slotProps={{ textField: {
                                                InputLabelProps: { shrink: true }, 
                                                size:'small',
                                                error: false,
                                                fullWidth: true,
                                                readOnly: true,
                                                onClick: () => handleDatePicker(true)
                                            },
                                actionBar: {
                                                actions: ['clear', 'accept'],
                                           }
                            }}
                />
            )
            }
            </LocalizationProvider>
        );
}

export default CPDatePicker;