import { Box, SwipeableDrawer } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function CPSwipeableImage({open, setOpen, images}) {
    return (
        <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                transitionDuration={300}
                disablePortal 
                sx={{
                    position: 'relative',
                }}
            >
                <Box width={'100%'} height={'50px'} position={'sticky'} top={0} textAlign={'right'} padding={2} backgroundColor={'#fff'}>
                    <CloseIcon 
                        sx={{cursor: 'pointer'}}
                        onClick={() => setOpen(false)}
                    />
                </Box>
                {images?.map((item, index) => (
                    <img key={item} src={item} alt={index} style={{marginTop:'20px'}} loading='lazy' />
                ))}
            </SwipeableDrawer>
    )
}

export default CPSwipeableImage;