import React from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const CPTextField = ({ rules, control, name, maxLength, label, className, required, readOnly, disabled, inputProps, endAdornment, ...rest }) => {

    return (
        <>
            {control && (
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({field}) => (
                        <TextField
                            {...field}
                            InputLabelProps={{ shrink: true}}
                            size="small"
                            fullWidth
                            label={label}
                            required={required}
                            disabled={Object.is(disabled,true)?true:false}
                            className={Object.is(disabled,true)?"":className}
                            inputProps={{
                                maxLength: maxLength,
                                ...inputProps
                            }} // 실제 input 요소에 대한 속성
                            {...rest}
                            InputProps={{
                                ...rest.InputProps, // 기존 InputProps를 유지
                                endAdornment: endAdornment ? (
                                    endAdornment.onClick ? (
                                        <InputAdornment position="end">
                                            <IconButton onClick={endAdornment.onClick}>
                                                {endAdornment.icon || <SearchIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ) : (
                                        <InputAdornment position="end">
                                            {endAdornment.text}
                                        </InputAdornment>
                                    )
                                ) : null,
                                readOnly: readOnly ? true : undefined, // readOnly가 있을 때만 true로 설정
                            }}
                        />
                    )}
                />
            )}
            {!control && (
                <TextField 
                    {...rest}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    name={name}
                    label={label}
                    required={required}
                    disabled={Object.is(disabled,true)?true:false}
                    className={Object.is(disabled,true)?"":className}
                    inputProps={
                        {maxLength: maxLength,
                        ...inputProps}} // 실제 input 요소에 대한 속성} // 실제 input 요소에 대한 속성
                    InputProps={{
                        ...rest.InputProps, // 기존 InputProps를 유지
                        endAdornment: endAdornment ? (
                            endAdornment.onClick ? (
                                <InputAdornment position="end">
                                    <IconButton onClick={endAdornment.onClick}>
                                        {endAdornment.icon || <SearchIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    {endAdornment.text}
                                </InputAdornment>
                            )
                        ) : null,
                        readOnly: readOnly ? true : undefined, // readOnly가 있을 때만 true로 설정
                    }}
                />
            )}
        </>
    );
}

export default CPTextField;