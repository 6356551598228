import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';


export const ProductInfoStack = styled(Stack)((props)=>({
    // [props.theme.breakpoints.up('md')]:{
    //     flexDirection : 'row'
    // }
    
}));

export const HelperTextBox = styled(Box)(({theme})=>({
    fontSize:'0.7rem', 
    paddingLeft:'10px',
    width: '100%',
   // [theme.breakpoints.up('md')]:{width:'50%'}
    
}));
export const WarningTextBox = styled(Box)(()=>({
    fontSize:'0.7rem', 
    paddingLeft:'10px',
    width: '100%',
    color: '#d32f2f',
    margin: '0 !important'
    
}));

export const TitleChip = styled(Chip)(()=>({
    fontSize:'1rem',
    fontWeight:600,
    color : '#777777',
    backgroundColor : '#0000'

}));

export const ContentPaper = styled(Paper)((props)=>({
    padding:'15px 15px 30px 15px',
    marginBottom: '25px'
}));

export const ContentPaper1 = styled(Paper)(({theme})=>({
    padding:'15px 15px 30px 15px',
    marginBottom: '25px',
    [theme.breakpoints.up('md')]:{padding:'15px 15px 28px 15px'}

}));

export const InfoCheckIcon = styled(DoneIcon)(()=>({
    fontSize:'1rem',
    color : '#ff5722',
    marginRight: '2px'

}));