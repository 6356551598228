import React, { useState, useEffect, useRef, useCallback } from 'react';
/**외부 */
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { DataGrid, gridClasses  } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; 
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
/**내부 */
import * as crm4u from '../services/apis/crm4u/crmApi';
import CPSelectTag from './comm/CPSelectTag';
import { alertState } from '../stores/ComponentState';
import CPCircularProgress from '../components/comm/CPCircularProgress';
import * as utils from '../commons/utils';
import CPPaging from './comm/CPPaging';
import{  HelperTextBox } from '../styles/ewMng/EwFormStyle';
import { userState } from '../stores/UserState';

function CPSvcClnSearchModal(props) {
    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState);
    const init = useRef(true);

    const { register,
            setValue,
            getValues,
            watch,
            control,
            } = useForm({ defaultValues: {
                    client_nm: props.client_nm || '', 
                    phone_no : ''
    }});

    // flag
    const [loading, setLoading] = useState(false);
    const [validSearchInfo, setValidSearchInfo] = useState(false);

    // searching value set
    const [searchParam, setSearchParam] = useState({client_nm: '', 
                                                    phone_no: ''
                                                });

    // table grid
    const [result, setResult] = useState([]);
	const [totalCount, setTotalCount] = useState(0); // 결과 카운트
	const [itemsCountPerPage, setItemsCountPerPage] = useState(10); // 한 페이지에 보여줄 행 수
    const [page, setPage] = useState(1); // 현재 페이지

    // table data
    const columns = [{ field: 'client_nm', headerName: '고객명', sortable:false, width:80}
                    ,{ field: 'car_no', headerName: '차량번호', sortable:false, width:100}
                    ,{ field: 'phone_no', headerName: '전화번호', sortable:false, width:150}
                    ,{ field: 'email', headerName: 'EMAIL', sortable:false, width:100}
                    ,{ field: 'sales_consultant', headerName: '담당SC', sortable:false, width:100}];

    // 초기세팅
    useEffect(() => {
        if(watch("client_nm").trim() === "" && watch("phone_no").trim() === ""){
            
        } else {
            init.current = false;
            setSearchParam(getValues());
        }
    }, [])

    // 검색조건 세팅 후 조회
    const getClientList = useCallback((pageNum) => {
        setLoading(true);
        setPage(pageNum);
        crm4u.getClientList({...searchParam, pageNum: pageNum})
            .then((res)=>{
                setResult(res.clientList);
                setTotalCount(res.totalCount);
            })
            .catch((err)=>{
                setAlertInfo({message :err, isOpen:true});
            })
            .finally(()=>{
                setLoading(false);
            });
    }, [searchParam, setAlertInfo]);

    //조회 버튼 클릭 -> 검색조건 세팅
    const clickSearchBtn = () => {
        init.current = false;

        if(watch("client_nm").trim() === "" && watch("phone_no").trim() === ""){
            setValidSearchInfo(true);
        } else {
            setSearchParam(getValues());
        }
    };

    //검색조건 세팅 후 조회
    useEffect(() => {
        if(init.current === false) getClientList(1);
    }, [searchParam, getClientList]);

    const tableRowEvents = (e) =>{
        setRowData(e);
    };

    const setRowData = (e) => {
        props.inputClnInfo(e.row);
        props.onClnSearchModal(false);  
    }

    return (
        // view &&
        // <>
            <Dialog open={true}
                    onClose={() => props.onClnSearchModal(false)}
                    maxWidth="md">
                <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>고객 조회 
                    {/* <span style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}> (영업사원(SC)의 경우 본인의 고객만 조회 가능합니다.)</span> */}
                </DialogTitle>

                <IconButton aria-label="close"
                            onClick={() => props.onClnSearchModal(false)}
                            sx={{ position: 'absolute',
                                  right: 8,
                                  top: 8,
                                }}> 
                            <CloseIcon />
                </IconButton>
    
                <DialogContent dividers>
                    <div className='subTitle'>
                        <div className='subTitle-btn'>
                            <Button size='small' variant="contained" onClick={clickSearchBtn} disabled={loading}>조회{loading && <CPCircularProgress/>}</Button>
                        </div>
                    </div>                 
                    <Stack direction="row" spacing={2} sx={{mb: 2, width:'100%'}} justifyContent="space-around">
                        <TextField 
                            label="고객명" 
                            size='small' 
                            fullWidth 
                            {...register("client_nm")}
                            inputProps={{ maxLength: 20}}
                            onKeyDown={e => e.key === "Enter" ? clickSearchBtn() : setValidSearchInfo(false)}/>
                        <TextField 
                            label="휴대폰번호" 
                            size='small' 
                            fullWidth 
                            inputProps={{ maxLength: 13}} 
                            {...register("phone_no")}
                            onChange={(e) => {setValue("phone_no", utils.onlyPhoneNumber(e.target.value))}} 
                            onKeyDown={e => e.key === "Enter" ? clickSearchBtn() : setValidSearchInfo(false)}/>
                    </Stack>
                    {validSearchInfo &&<HelperTextBox sx={{color: "#d32f2f", margin: "-5px 0px 10px 0 !important", width: "100% !important"}}>{"고객명, 전화번호 중 하나를 입력해 주세요."}</HelperTextBox>}    
                    <DataGrid
                        disableColumnMenu
                        autoHeight
                        rows={ result }
                        getRowId={(row) => row.num}
                        getRowHeight={() => 'auto'}
                        columns={ columns }
                        onRowClick={(e) => tableRowEvents(e)}
                        sx={{ '--DataGrid-overlayHeight': '100px',
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                          },
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                            },
                        '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                        },
                        '& .MuiDataGrid-row': {
                            minHeight: '52px !important'
                        }
                        }}
                        slots={{
                            noRowsOverlay: () => (
                                <Box sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>조회 결과가 없습니다.</Box>
                            )
                        }}
                        hideFooter
                        
                    />
                    <CPPaging page={page} totalItemsCount={totalCount} func={getClientList} itemsCountPerPage={itemsCountPerPage}/>
                </DialogContent>
            </Dialog>
        // </>
      );
};

export default CPSvcClnSearchModal;