import { useState, useEffect } from 'react';

/**외부 */
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**내부 */
import CPPaging from 'components/comm/CPPaging';
import CPSearchResult from 'components/comm/CPSearchResult';
import CPSearchCondition from 'components/comm/CPSearchCondition';
import { userState } from 'stores/UserState';
import { alertState, loadingState } from 'stores/ComponentState';
import * as crm4u from 'services/apis/crm4u/crmApi';
import * as svcApi from 'services/apis/crm4u/svcApi';
import * as signOk from 'services/apis/signOk/signOkApi';

/**mui */
import { Menu, MenuItem, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CPManualBtn from 'components/comm/CPManualBtn';

function CrtsyList() {
    const userInfo = useRecoilValue(userState);
    const setAlertInfo = useSetRecoilState(alertState);
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    
    //검색조건
    const [crtsySignokProgStatus, setCrtsySignokProgStatus] = useState([]);
    const [serviceCenterList, setServiceCenterList] = useState([]);
    const [courtesyCarList, setCourtesyCarList] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [searchParam, setSearchParam] = useState({});

    const onServiceCenterChange = (e) => {
        if(e.target.value === '') {
            setCourtesyCarList([]);
            return;
        }

        svcApi.svcApiSelectBoxCourtesyCarInfo({"showroom_no": e.target.value}).then((res) => {
            setCourtesyCarList(res?.carList);
        })
    }

    const conditions = [{ type: 'select', label: '상태', name: 'crtsy_eform_prog_status', value: 'detail_cd_id', text: 'cd_nm', data: crtsySignokProgStatus, defaultMessage: '전체' },
                        { type: 'text', label: '계약서 번호', name: 'courtesy_eform_cont_no'},
                        { type: 'text', label: '고객명', name: 'client_nm'},
                        { type: 'select', label: '서비스센터', name: 'showroom_no', value: 'showroom_no', text: 'showroom_nm', data: serviceCenterList, defaultMessage: '전체', onChange: onServiceCenterChange },
                        { type: 'select', label: '커티시카', name: 'courtesy_car_no', value: 'sel_cd', text: 'sel_nm', data: courtesyCarList, defaultMessage: '전체', breakPoints: {lg: 8, md: 8, sm: 12, xs: 12}},
                        { type: 'date-range',
                          items: [{ label: '대여일', name: 'courtesy_rsv_dt_s', start: true, link: 'courtesy_rsv_dt_e'}, 
                                  { label: '대여일', name: 'courtesy_rsv_dt_e', end: true, link: 'courtesy_rsv_dt_s'}
                                ]
                        }
                       ];

    //검색결과
    const [rows, setRows] = useState([]);
    const columns = [{ field: 'crtsy_eform_prog_status_nm' ,headerName: '상태', width: 110, align: 'left',
                        renderCell: (params) => signStatusComponent(params.row.crtsy_eform_prog_status, params.row.crtsy_eform_prog_status_nm)},
                    { field: 'courtesy_eform_cont_no', headerName: '계약서번호', minWidth: 110, flex: 1 },
                    { field: 'courtesy_car_info', headerName: '커티시카', minWidth: 330, flex: 1 },
                    { field: 'client_nm', headerName: '고객명', minWidth: 80, maxWidth: 160, flex: 1 },
                    { field: 'courtesy_start_rsv_dt', headerName: '대여시작일', minWidth: 140, flex: 1 },
                    { field: 'courtesy_end_rsv_dt', headerName: '대여종료일', minWidth: 140, flex: 1 },
                    { field: 'document_id', headerName: 'Action', minWidth: 80, flex: 1, renderCell: (row) => <ActionIcon row={row}/>}
                    ].filter(Boolean);

    
    //페이징
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [itemsCountPerPage] = useState(10);

    //Action 메뉴
    const [anchorActionEl, setAnchorActionEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(false);

    useEffect(() => {
        const init = async() => {
            try {
                setLoadingInfo({isOpen:true});

                let statusList = await crm4u.getCodeList({dealer_cd: userInfo.dealer_cd, grp_cd_id: 'CRTSY_EFORM_PROG_STATUS'});
                statusList = statusList?.filter((obj) => obj.detail_cd_id !== 'CSPS00060');
                setCrtsySignokProgStatus(statusList);

                const showRoomList = await crm4u.getShowRoomList();
                const serviceCenterList = showRoomList?.showRoomList?.filter((showRoom) => showRoom?.place_div === 'PLD00020');
                setServiceCenterList(serviceCenterList);
                
                setIsClicked(true);
            } catch (err) {
                setIsClicked(false);
                setAlertInfo({message :err, isOpen:true});    
            } finally {
                setLoadingInfo({isOpen:false});
            }
        }

        init();
    }, []);

    useEffect(() => {
        if(isClicked) {
            getCourtesyEformList(1);
            setIsClicked(false);
        }
    }, [searchParam, isClicked]);
    
    const handleClick = (searchParam) => {
        setIsClicked(true);
        setSearchParam(searchParam);
    }

    const getCourtesyEformList = async (page) => {
        setLoadingInfo({isOpen:true});
        try {
            const courtesyCarList = await svcApi.svcApiGetCourtesyEform({...searchParam, 
                                                    dealer_cd: userInfo.dealer_cd, 
                                                    writer: userInfo.emp_id, 
                                                    pageNum: page, 
                                                    size: 10});

            setPage(page);
            setTotalCount(courtesyCarList?.totalCount??0);
            setRows(courtesyCarList?.courtesyCarEformList??[{}]);
        } catch (error) {
            setAlertInfo({message :error, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    const deleteDocument = () => {
        if(window.confirm('계약서를 삭제하시겠습니까?')) {
            setLoadingInfo({isOpen:true})

            const seq = selectedRow?.row?.courtesy_eform_seq;
            svcApi.svcApiDeleteCourtesyEform({courtesy_eform_seq: seq})
                .then((res) => {
                    alert('계약서 삭제를 완료하였습니다.');
                    setAnchorActionEl(null);
                    getCourtesyEformList(1);
                })
                .catch((err) => {
                    setAlertInfo({message :err, isOpen:true})
                })
                .finally(() => {
                    setLoadingInfo({isOpen:false})
                });
        }
    }

    const ActionIcon = ({row}) => {
        return (
            <div>
                <MoreHorizIcon
                onClick={(e) => {
                    setSelectedRow(row);
                    setAnchorActionEl(e.currentTarget);
                }}
                >
                </MoreHorizIcon>
            </div>
        );
    }

    const ActionMenuList = () => {
        const isSigning = selectedRow?.row?.crtsy_eform_prog_status === 'CSPS00010' || selectedRow?.row?.crtsy_eform_prog_status === 'CSPS00020' || selectedRow?.row?.crtsy_eform_prog_status === 'CSPS00070';

        return (
            <Menu
              anchorEl={anchorActionEl}
              open={Boolean(anchorActionEl)}
              onClose={() => setAnchorActionEl(null)}
            >
                <MenuItem onClick={() => openDocument(selectedRow)}>계약서 보기</MenuItem>
                {isSigning && <MenuItem onClick={() => deleteDocument(selectedRow)}>계약서 삭제</MenuItem>}
            </Menu>
        )
    }

    const openDocument = (row) => {
        setLoadingInfo({isOpen:true});
        
        if(!row?.row?.document_id) {
            if(row?.row?.crtsy_eform_prog_status === 'CSPS00010') {
                setAlertInfo({message :'고객의 서명을 기다리고 있습니다.', isOpen:true});
            } else {
                setAlertInfo({message :'계약서가 존재하지 않습니다.', isOpen:true});
            }
            setLoadingInfo({isOpen:false});
            return;
        }

        if(row?.row?.crtsy_eform_prog_status === 'CSPS00020') {
            window.open(row?.row?.view_url, '_blank');
            setLoadingInfo({isOpen:false})
            return;
        }

        const popup = window.open('', '_blank');
        signOk.getSignOkDocument(row?.row?.document_id)
            .then((res) => {
                popup.location.href = res.result.view_url
            })
            .catch((err) => {
                popup.close();
                setAlertInfo({message :err, isOpen:true})
            })
            .finally(() => {
                setLoadingInfo({isOpen:false})
            });
    }   

    const signStatusComponent = (code, name) => {

        const component = {
            CSPS00010:  <>
                            <CreateIcon fontSize='small' color='disabled'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'#00000042'}>{name}</Typography>
                        </>,
            CSPS00020:  <>
                            <HourglassEmptyRoundedIcon fontSize='small' color='primary'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'primary'}>{name}</Typography>
                        </>,
            CSPS00030:  <>
                            <CheckCircleRoundedIcon fontSize='small' color='warning'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'#ed6c02'}>{name}</Typography>
                        </>,
            CSPS00040:  <>
                            <CancelIcon fontSize='small' color='error'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'error'}>{name}</Typography>
                        </>,
            CSPS00050:  <>
                            <CancelIcon fontSize='small' color='error'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'error'}>{name}</Typography>
                        </>,
            CSPS00070:  <>
                            <CancelIcon fontSize='small' color='error'/>
                            <Typography pl={0.5} fontWeight={'bold'} color={'error'}>{name}</Typography>
                        </>
        }
        
        return component[code];
    }
    return (
        <>
            <CPSearchCondition onSearch={handleClick} conditions={conditions}/>
            <CPSearchResult columns={columns } rows={rows} rowId={'courtesy_eform_seq'} totalCount={totalCount} />
            <CPPaging page={page} totalItemsCount={totalCount} func={getCourtesyEformList} itemsCountPerPage={itemsCountPerPage}/>
            <CPManualBtn url={'https://dev.kcc.co.kr/wiki/pages/viewpage.action?pageId=45299642'}/>
            <ActionMenuList />
        </>
    )
}

export default CrtsyList;