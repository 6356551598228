import axios from 'axios';
import * as utils from '../../../commons/utils';

// axios 인터셉터 설정
const instance = axios.create({});

instance.interceptors.request.use(
    (config) => {
        config.params = {...config.params, type : "SVC"};
        return config;
    }
);

instance.interceptors.response.use(
    (response)  => {
        return response;
    },
    (error) => {
        if(error.response.status === 403) {
            
            const domain = sessionStorage.getItem('domain');

            utils.openPopup({
                url: `/${domain}?type=simple`,
                target: '_blank',
                width: '700',
                height: '700',
                align: 'center'
            });

            return Promise.reject(error);
        }
    }
);

/***
 * 서비스 개인정보동의서 2차 등록
*/
export function insertSvcPrvAgreement(requestParam) {
    return new Promise(function(resolve, reject){  
        instance({
            method:"post",
            url:"/apiInsertSvcPrvAgreement.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
    
        }).then((res)=>{
            if(res.data.resultCode === "0000") { 
                resolve(res.data.resultValue);
            }else {
                reject("서비스 개인정보동의에 실패하였습니다.");
            }
        }).catch((err)=>{
            reject("서비스 개인정보동의에 실패하였습니다.");
        });
    }); 
}

/**
 * 코드값 + 전시장 + 차량모델 조회
 */
export function svcApiGetCode(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
            method:"post",
            url:"/svcApiGetCode.do",
            data:requestParam
        }).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            reject("서비스 정보 조회에 실패하였습니다.");
        }); 
    })
}

/**
 * 사용자 조회
 */
export function getEmpList(requestParam) {
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetEmpList.do",
            data:requestParam,
            headers : {"Content-Type" : "application/json"}
        }).then((res)=>{
            resolve(res.data)
        }).catch((err)=>{
            reject("사용자 조회에 실패하였습니다.");
        });        
    });
}

/**
 * 커티시카 조회
 */
export function svcApiSelectBoxCourtesyCarInfo(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
            method: "post",
            url:"/svcApiSelectBoxCourtesyCarInfo.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
        }).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            reject("커티시카 조회에 실패하였습니다.");
        });
    })
}

/**
 * 커티시카 상세 조회
 */
export function svcApiCourtesyCarInfo(courtesy_car_no) {
    return new Promise(function(resolve,reject){
        instance({
            method: "post",
            url:"/svcApiCourtesyCarInfo.do",
            data: {"courtesy_car_no" : courtesy_car_no},
            headers : {"Content-Type" : "application/json"}
        }).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            reject("커티시카 상세 조회에 실패하였습니다.");
        });
    })
}

/**
 * 차종 조회 (판매 가능하지 않은 것도 조회)
 */
export function svcApiGetCarType(model_no) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/getSelectBoxCarTypeInfo.do",
           data: {"model_no" : model_no}
       }).then((res)=>{
           resolve(res.data.carType);
       }).catch((err)=>{
        reject("차종 조회에 실패하였습니다.");
       });
    })
 }

/**
 * 커티시카 대차 예약 목록 불러오기
 */
export function svcApiCourtesyCarReserve(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiCourtesyCarReserve.do",
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("대차예약 불러오기에 실패하였습니다.");
       });
    })
 }

/**
 * 커티시카 전자계약서 정보 가져오기
 */
export function svcApiGetCourtesyEform(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiGetCourtesyEform.do",
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("전자계약서 불러오기에 실패하였습니다.");
       });
    })
 }

/**
 * 대차 시간 유효성 검증
 */
export function svcApiCheckCourtesyCarReserveTime(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiCheckCourtesyCarReserveTime.do",
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("유효성 검증에 실패하였습니다.");
       });
    })
 }

/**
 * 커티시카 대차 예약 첨부파일 불러오기
 */
export function svcApiGetCourtesyCarAttachment(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiGetCourtesyCarAttachment.do",
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("첨부파일 불러오기에 실패하였습니다.");
       });
    })
 }

 /**
 * 커티시카 첨부파일 업로드하기 (사진, 외관점검)
 */
 export function svcApiUploadCourtesyCarAttachment(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url: '/svcApiUploadCourtesyCarAttachment.do',
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("첨부파일 업로드에 실패하였습니다.");
       });
    })
 }

 /**
 * 커티시카 첨부파일 삭제하기
 */
 export function svcApiDeleteCourtesyCarAttachment(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiDeleteCourtesyCarAttachment.do",
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("첨부파일 삭제에 실패하였습니다.");
       });
    })
 }

 /**
 * 커티시카 전자계약 검증
 */
export function svcApiCheckCourtesyEform({target, courtesy_eform_seq, client_phone}) {
    return new Promise(function(resolve,reject){
        axios({
            method: "post",
            url:`/svcApiCheckCourtesyEform.do?target=${target}`,
            data: { courtesy_eform_seq, client_phone }
        }).then((res)=>{
            if(res.data.resultCode === "1111") {
                reject("검증에 실패하였습니다.");
            } else {
                resolve(res.data);
            }
        }).catch((err)=>{
            reject("검증에 실패하였습니다.");
        });
    })
 }

 /**
 * 커티시카 계약서 업데이트
 */
 export function svcUpdateCourtesyCarEform({
        courtesy_eform_seq, 
        crtsy_eform_prog_status, 
        courtesy_reserve_seq, 
        view_url, 
        document_id, 
        result_code, 
        result_message,
        dealer_cd
    }) {
    return new Promise(function(resolve,reject){
        axios({
            method: "post",
            url:`/svcUpdateCourtesyCarEform.do`,
            data: { 
                courtesy_eform_seq, 
                crtsy_eform_prog_status, 
                courtesy_reserve_seq, 
                view_url, 
                document_id, 
                result_code, 
                result_message ,
                dealer_cd
            }
        }).then((res)=>{
            if(res.data.resultCode === "1111") {
                reject("계약서 생성에 실패하였습니다.");
            } else {
                resolve(res.data);
            }
        }).catch((err)=>{
            reject("계약서 생성에 실패하였습니다.");
        });
    })
 }

 /**
 * 커티시카 등록
 */
 export function svcApiRegisterReserveCourtesyCar(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiRegisterReserveCourtesyCar.do",
           data: requestParam
       }).then((res)=>{
            resolve(res.data);
       }).catch((err)=>{
            reject("커티시카 등록에 실패하였습니다.");
       });
    })
 }

 /**
  * 커티시카 전자계약서 생성 및 알림톡 전송
  */
 export function svcApiCreateSignok(requestParam) {
    return new Promise(function(resolve,reject){
        instance({
           method: "post",
           url:"/svcApiCreateSignok.do",
           data: requestParam
       }).then((res)=>{
           resolve(res.data);
       }).catch((err)=>{
        reject("커티시카 전자계약서 생성에 실패하였습니다.");
       });
    })
 }

 
/**
 * 커티시카 전자계약서 삭제
 */
export function svcApiDeleteCourtesyEform({courtesy_eform_seq}) {
    const errorMessage = "계약서 삭제에 실패하였습니다.";
    return new Promise(function(resolve, reject){

        if(!courtesy_eform_seq) reject(errorMessage);

        instance({
            method: "post",
            url:"/svcApiDeleteCourtesyEform.do",
            data: {courtesy_eform_seq}
        }).then((res) => {
            if(res.data.resultCode === "1111"){
                reject(errorMessage);
            }else{
                resolve(res.data);
            }
        }).catch((err)=>{
            reject(errorMessage);
        });
    });
 }