import React, { useEffect, useState, useRef } from 'react';

/* mui */
import * as svcApi from 'services/apis/crm4u/svcApi';
import{ TitleChip, ContentPaper, WarningTextBox } from '../../../styles/ewMng/EwFormStyle';
import { ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { Box, Modal, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import { regExp } from 'commons/utils';


function CrtsyFormUploadStep(props) {

    // flag
    const [isLoading, setIsLoading] = useState(true);

    const xsVal = 1;
    const smVal = 2;
    const mdVal = 4;

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));

    const crtsyCheck = 'crtsy_check_photo';
    
    // 이미지 파일
    const [imageFiles, setImageFiles] = useState([]);
    const [imageFileCnt, setImageFileCnt] = useState(0);
    const [imageLoadError, setImageLoadError] = useState({});

    // modal
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // image upload
    const fileInputRef = useRef(null);

    const courtesyEformSeq = props?.contractData?.formData.courtesy_eform_seq || '';
    const courtesyEformContNo = props?.contractData?.formData.courtesy_eform_cont_no || '';
    const courtesyEformDiv = props?.contractData?.formData.courtesy_eform_div || '';


    // 이미지 목록 보여주기
    useEffect(() => {
        setIsLoading(true);
        if(props?.contractData?.formData){
            const formData = new FormData();
            formData.append('courtesyEformSeq', courtesyEformSeq);
            formData.append('crtsyCheck', crtsyCheck);
            svcApi.svcApiGetCourtesyCarAttachment(formData)
                .then((res) => {
                    setImageFileCnt(res.file_count);
                    const processedImages = res.fileInfoList
                        .map(processImageFile)
                        .filter(Boolean);
                    setImageFiles(processedImages)
                })
                .catch((error) => {
                    console.log('이미지 로딩 중 에러 : ' + error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        };
    }, []);

    // 화면 비율에 맞는 cols 조정 함수
    const getCols = () => {
        if(isXs) return xsVal;
        if(isSm) return smVal;
        return mdVal;
    }

    // 이미지 처리 함수
    const processImageFile = (file) => {
        if (!file.upload_file_nm) return null;
        
        const pathParts = file.upload_file_nm.split('/upload/');
        const imagePath = pathParts[1];
        const imageUrl = `${process.env.REACT_APP_CRM_UPLOAD_URL}/upload/${imagePath}`;

        return {
            img: imageUrl,
            title: file.org_file_nm,
            fileNo: file.file_no
        };
    }

    // 상세 모달 열기 핸들러
    const handleOpenDetailModal = (image) => {
        setSelectedImage(image);
        setIsDetailModalOpen(true);
    };

    // 상세 모달 닫기 핸들러
    const handleCloseDetailModal = () => {
        setIsDetailModalOpen(false);
        setSelectedImage(null); 
    };

    // 이미지 삭제 핸들러
    const handleDeleteImage = (image) => {
        if (window.confirm('이미지를 삭제하시겠습니까?')) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('fileNo', image.fileNo);
            formData.append('courtesyEformSeq', courtesyEformSeq);
            formData.append('crtsyCheck', crtsyCheck);
            svcApi.svcApiDeleteCourtesyCarAttachment(formData).then((res) => {
                if(res.trxResultCode === "0000") {
                    setImageFileCnt(res.file_count);
                    const processedImages = res.fileInfoList
                        .map(processImageFile)
                        .filter(Boolean);
                        
                    handleCloseDetailModal();
                    setImageFiles(processedImages);
                    setIsLoading(false);
                } else {
                    alert("사진 삭제에 실패했습니다.")
                }
            });
        }
    };

    // 이미지 첨부창 열기 핸들러
    const handleAddImage = () => {
      fileInputRef.current.click();
    }

    // 첨부된 이미지 저장 핸들러
    const handleFileChange = async (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;
        try {
            const totalImageCnt = imageFileCnt + files.length;
            if(totalImageCnt > 20) {
                alert('최대 첨부 가능 이미지는 20개 입니다.');
                return false;
            }

            // 파일 크기 및 타입 체크
            for(let i=0; i< files.length; i++) {
                if(files[i].size > 5 * 1024 * 1024) {
                    alert(`${files[i].name} 파일은 5MB를 초과할 수 없습니다.`);
                    return false;
                }

                if(!['image/jpeg','image/png'].includes(files[i].type)) {
                    alert(`${files[i].name} 파일 형식은 jpg 또는 png만 허용됩니다.`);
                    return false;
                }
            }

            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
            formData.append('courtesyEformSeq', courtesyEformSeq);
            formData.append('crtsyCheck', crtsyCheck);
            svcApi.svcApiUploadCourtesyCarAttachment(formData).then((res) => {
                if(res.trxResultCode === "0000") {
                    setImageFileCnt(res.file_count);
                    const processedImages = res.fileInfoList
                        .map(processImageFile)
                        .filter(Boolean);
                    handleCloseDetailModal();
                    setImageFiles(processedImages);
                    setIsLoading(false);
                } else {
                    if(res.trxResultCode === "1111" && res.trxResult) {
                        alert(res.trxResult);
                    } else {
                        alert("사진 저장에 실패했습니다.")
                    }
                }
            });
        } catch (error) {
            console.error('업로드 에러:', error);
            alert('이미지 업로드 중 오류가 발생했습니다.'); 
        }
    };

    // 이미지 로드 실패시 처리
    const handleImageError = (fileNo) => {
        setImageLoadError((prev) => ({ ...prev, [fileNo] : true}));
    }

    // 대차예약 생성/선택하지 않고 오면 안됨
    if(!props?.contractData?.formData?.courtesy_eform_seq) {
        return <div>잘못된 접근입니다.</div>;
    }

    return(
        <div>
            <Typography
                style={{'fontSize': '22px', 'fontWeight': '500', margin: '20px 0 25px 5px'}}
            >
                커티시카 {courtesyEformDiv==='CED00010'?'대여':'반납'} 전자계약서 {courtesyEformContNo && `(${courtesyEformContNo})`}
            </Typography>
            <ContentPaper elevation={3}>
                <TitleChip label='커티시카 외관사진' icon={<CreateIcon/>}/>
                <Typography style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}>첨부된 파일 개수 총 {imageFileCnt}개</Typography>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    multiple
                    style={{ display: 'none' }}
                />
                <ImageList sx={{ height: '100%', width: '100%' }} cols={getCols()} rowHeight={164}>
                    <ImageListItem 
                        key='add'
                        sx={{ 
                            boxShadow:3,
                            m:1,
                            overflow:'hidden',
                            cursor: 'pointer',
                            borderRadius: 1,
                            '&:hover': {
                                boxShadow: 5,
                                transform: 'scale(1.02)',  // hover 시 살짝 확대
                                transition: 'all 0.3s ease-in-out'
                            },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 164
                        }}
                        onClick={() => handleAddImage()}
                    >
                        <AddIcon sx={{ fontSize: 40, color: 'grey.500'}} />
                    </ImageListItem>
                    {imageFiles.map((item) => (
                        <ImageListItem 
                            key={item.img}
                            sx={{ 
                                boxShadow:3,
                                m:1,
                                overflow:'hidden',
                                cursor: 'pointer',
                                borderRadius: 1,
                                '&:hover': {
                                    boxShadow: 5,
                                    transform: 'scale(1.02)',
                                    transition: 'all 0.3s ease-in-out'
                                }
                            }}
                            onClick={() => handleOpenDetailModal(item)}
                        >
                            <img
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                onError={() => handleImageError(item.fileNo)}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.title}
                            />
                            {imageLoadError[item.fileNo] && ( // 이미지 로드 실패 시 삭제 아이콘 표시
                                <IconButton
                                    onClick={() => {handleDeleteImage(item); return false;}}
                                    sx={{ color: 'red', position: 'absolute', top: 8, right: 8 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ImageListItem>
                    ))}
                </ImageList>

                <Modal
                    open={isDetailModalOpen}
                    onClose={handleCloseDetailModal}
                    aria-labelledby="image-preview-modal"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            maxWidth: '90vw',
                            maxHeight: '90vh',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            padding: 0,
                            lineHeight: 0
                        }}
                    >
                        <IconButton
                            onClick={() => handleDeleteImage(selectedImage)}
                            sx={{
                                position: 'absolute',
                                right: 60,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                                bgcolor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.9)',
                                    color: (theme) => theme.palette.error.main
                                }
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleCloseDetailModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                                zIndex: 1,
                                bgcolor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.9)'
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {selectedImage && (
                            <img
                                src={selectedImage.img}
                                alt={selectedImage.title}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '85vh',
                                    objectFit: 'contain',
                                    display: 'block',
                                    margin: 0
                                }}
                            />
                        )}
                    </Box>
                </Modal>
            </ContentPaper>
        </div>
    )
}
export default CrtsyFormUploadStep;