import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function HorizontalLinearStepper({
    steps,
    children, 
    resetable,
    finalPage,
    finalMsg,
    onFinalSubmit,
    onStepComplete
}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    // const [stepInfos, setStepInfos] = React.useState(steps);

    // skip했는지 여부 확인
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    // next 핸들러
    const handleNext = async () =>  {
        // 마지막 단계에서 finalPage가 false일 경우 handleFinalSubmit 호출
        if (activeStep === steps.length - 1 && !finalPage) {
            await handleFinalSubmit();
            return; // 더 이상 진행하지 않음
        }

        // 각 step 완료시 처리 함수 (성공 시 true, 실패 시 false)
        const isValid = await onStepComplete?.(activeStep);
        if(!isValid) return;

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    // back 핸들러
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // skip 핸들러
    const handleSkip = () => {
        if (steps[activeStep].optional) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    // 초기화 핸들러
    const handleReset = () => {
        setActiveStep(0);
    };

    // 최종 submit
    const handleFinalSubmit = () => {
        onFinalSubmit?.();
    }


    return (
        <Box sx={{ width: '100%' }}>
            {/* Stepper 상단 */}
            <Stepper activeStep={activeStep}>
                {steps.map((item, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (item.optional) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={item.label} {...stepProps}>
                            <StepLabel {...labelProps}>{item.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {/* 본문 콘텐츠 */}
            <Box sx={{ my: 2 }}>{children && children(activeStep)}</Box>

            {/* 하단 버튼 */}
            {activeStep === steps.length ? (
                finalPage ? (
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pt: 2 }}>
                        {resetable && <Button color="inherit" onClick={handleReset}>초기화</Button>}
                        <Button onClick={handleFinalSubmit} fullWidth variant="contained" size="large">{finalMsg}</Button>
                    </Box>
                </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )
            ) : (
              <React.Fragment>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                      >
                          Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {steps[activeStep].optional && (
                          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                              Skip
                          </Button>
                      )}
                      {(steps[activeStep].nextOnSubmit && steps[activeStep].isValid) && 
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? '완료' : 'Next'}
                        </Button>
                      }
                      {!steps[activeStep].nextOnSubmit &&
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? '완료' : 'Next'}
                        </Button>
                      }
                      {/* <Button onClick={handleNext} disabled={steps[activeStep].nextOnSubmit? steps[activeStep].isValid : true}> */}
                  </Box>
              </React.Fragment>
            )}
        </Box>
    );
}