import React, { useEffect, useState, useRef} from 'react';

/**외부 */
import { useNavigate, useLocation } from 'react-router-dom';
import {useForm} from 'react-hook-form'; 
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Box, Dialog, DialogTitle, DialogContent, Button, Stack, Checkbox, FormControlLabel} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
/**내부 */
import CPPaging from './comm/CPPaging';
import CPSelectTag from 'components/comm/CPSelectTag';
import CPDatePicker from 'components/comm/CPDatePicker';
import * as svcApi from 'services/apis/crm4u/svcApi';
import { alertState } from '../stores/ComponentState';
import CPCircularProgress from '../components/comm/CPCircularProgress';
import{ HelperTextBox } from '../styles/ewMng/EwFormStyle';
import { Typography } from '@mui/material';
import { userState } from '../stores/UserState';
import 'styles/crtsy/Crtsy.css';

function CPCrtSearchModal(props) {
    const userInfo = useRecoilValue(userState);
    const setAlertInfo = useSetRecoilState(alertState);
    const init = useRef(true);
    const navigate = useNavigate();

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    const defaultValues = {
        showroom_no: '',
        courtesy_car_no: '',
        courtesy_rsv_time_from: dayjs().day(1),
        courtesy_rsv_time_to: dayjs().day(1).add(6, 'day'),
        courtesy_eform_div: props.courtesyEformDiv,
    }

    const { setValue,
            getValues,
            watch,
            control,
            } = useForm({ defaultValues: defaultValues });

    // flag
    const [loading, setLoading] = useState(false);
    const [validSearchInfo, setValidSearchInfo] = useState(false); // true : invalid, false : valid

    // selection
    const [showRoomList, setShowRoomList] = useState([]);
    const [courtesyCarList, setCourtesyCarList] = useState([]);

    // selected searching value
    const courtesyRsvTimeFrom = watch("courtesy_rsv_time_from");
    const courtesyRsvTimeTo = watch("courtesy_rsv_time_to");
    
    // table grid
    const [result, setResult] = useState([]);
	const [totalCount, setTotalCount] = useState(0); //결과 행 수
	const [itemsCountPerPage, setItemsCountPerPage] = useState(10); //페이지당 행 수 
    const [page, setPage] = useState(1); //현재 페이지
    const [rawList, setRawList] = useState([]); // 원본 데이터 저장
    const [filteredList, setFilteredList] = useState([]); // 필터링된 데이터 저장
    const [includeCourtesyEform, setIncludeCourtesyEform] = useState(false);

    // table data
    const columns = [{ field: 'courtesy_car_info'   ,headerName: '커티시카', sortable:false, width:260},
                     { field: 'courtesy_start_rsv_time'   ,headerName: '대차시작일자', sortable:false, width:120},
                     { field: 'courtesy_end_rsv_time'  ,headerName: '대차종료일자', sortable:false, width:120},
                     { field: 'emp_nm'   ,headerName: '대차담당자', sortable:false, width:100},
                     { field: 'courtesy_car_gubun_nm'    ,headerName: '대차구분', sortable:false, width:110},
                     { field: 'client_nm' ,headerName: '고객명', sortable:false, width:100},
                     ... (includeCourtesyEform ? [
                     { field: 'courtesy_eform_cont_no',
                       headerName: '계약서번호',
                       sortable:false,
                       width:100,
                       valueGetter: (params) => params.row.courtesy_eform_cont_no ? params.row.courtesy_eform_cont_no : '없음'
                     }] : []),
                    ];

    // 초기 세팅 : 서비스센터
    useEffect(() => {
        setShowRoomList(props.showRoomList);
        setItemsCountPerPage(userInfo.size);
        if(!props.selectAuth) {
            setValue("showroom_no", userInfo.showroom_no);
            svcApi.svcApiSelectBoxCourtesyCarInfo({"showroom_no" : userInfo.showroom_no}).then((res) => {
                setCourtesyCarList(res.carList || []);
            })
        }
    }, [props.selectAuth, props.showRoomList, setValue, userInfo.showroom_no, userInfo.size]); 

    // 대차기간 검색조건 from 변경 시 to 도 6일 이후로 변경
    useEffect(() => {
        if(courtesyRsvTimeFrom) {
            const newDate = dayjs(courtesyRsvTimeFrom).add(6, 'day');
            setValue('courtesy_rsv_time_to',newDate);
        }
    }, [courtesyRsvTimeFrom, setValue])
    
    // 서비스센터 변경시 커티시카 세팅
    const changeShowRoom = (e) => {
        const showroomNo = e.target.value;
        
        setValue("courtesy_car_no","");

        svcApi.svcApiSelectBoxCourtesyCarInfo({'showroom_no' : showroomNo}).then((res) => {
            setCourtesyCarList(res.carList || []);
        });
    }
    
    // 검색조건 세팅 후 조회
    const getInfoList = async (pageNum) => {
        setLoading(true);
        setPage(pageNum);
        
        const searchBody = getValues();
        searchBody.courtesy_rsv_time_from = dayjs(searchBody.courtesy_rsv_time_from).format('YYYY-MM-DD')
        searchBody.courtesy_rsv_time_to = dayjs(searchBody.courtesy_rsv_time_to).format('YYYY-MM-DD')
        svcApi.svcApiCourtesyCarReserve({...searchBody, pageNum: pageNum, size: itemsCountPerPage}).then((res) => {
            if(res.courtesyList !== undefined) {
                // 원본 데이터 저장
                const courtesyListData = res.courtesyList;
                setRawList(courtesyListData);

                // 필터된 데이터 저장
                const filteredListData = courtesyListData.filter(item => item.courtesy_eform_yn !== 'Y');
                setFilteredList(filteredListData);

                // 계약서 작성 건도 보기 클릭되어 있으면 원본으로 보여주고 아니면 필터본으로 보여주기
                if(includeCourtesyEform) {
                    setResult(courtesyListData)
                } else {
                    setResult(filteredListData)
                }
                setTotalCount(res.totalCount)
                setItemsCountPerPage(res.size);
            }
        }).catch((err) => {
            setAlertInfo({message: err, isOpen: true});
        }).finally(()=>{
            setLoading(false);
            setIncludeCourtesyEform(false);
        });
    };
        
    // 조회 버튼 클릭 -> 검색조건 세팅
    const clickSearchBtn = () => {
        setAlertInfo({isOpen: false});
        init.current = false;

        if(watch("showroom_no").trim() === "" || watch("courtesy_rsv_time_from") === "" || watch("courtesy_rsv_time_to") === ""){
            setValidSearchInfo(true);
        } else {
            setValidSearchInfo(false);
            if(init.current === false) getInfoList(1);
        }
    };

    const tableRowEvents = (e) =>{
        if(e.row.courtesy_eform_yn === 'Y'){
            alert("계약서 작성이 시작된 건은 목록에서 삭제 후 작성이 가능합니다.")
            return false;
        } else if(e.row.fuel_type === '' || e.row.fuel_type === null) {
            alert("유종이 등록되지 않은 커티시카입니다. CRM에서 유종 정보를 입력하세요.")
            return false;
        } else {
            setRowData(e);
        }
    };

    const setRowData = async (e) => {
        props.inputCrtInfo(e.row);
    }

    const handleClose = () => {
        if(!props.isSelected && props.courtesyEformDiv === 'CED00020') {
            const confirmMsg = "데이터를 선택한 후에 닫을 수 있습니다. 목록으로 돌아가시겠습니까?";
            if(window.confirm(confirmMsg)) {
                navigate('/crtsy/select');
            }
        } else {
            props.onCrtSearchModal(false);
        }
    }

    const handleIncludeCourtesyEform = (checked) => {
        setIncludeCourtesyEform(checked);
        if(checked) {
            setResult(rawList);
        } else {
            setResult(filteredList);
        }
    }

    return (
    <>
        <Dialog open={true}
                onClose={handleClose}
                maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>커티시카 대차예약 조회 <span style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}>(전자계약서 작성이 시작된 건은 선택할 수 없습니다.)</span></DialogTitle>
            <IconButton aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute',
                              right: 8,
                              top: 8,
                            }}> 
                        <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <div className='subTitle'>
                    <div className='subTitle-btn'>
                        <Button size='small' variant="contained" onClick={clickSearchBtn} disabled={loading}>조회{loading && <CPCircularProgress/>}</Button>
                    </div>
                </div>                 
                
                <Stack 
                    direction={isXs ? "column" : "row"}
                    spacing={2} 
                    sx={{mb: 2, width:'100%'}} 
                    justifyContent="space-around">
                    <CPSelectTag 
                        control={control}
                        data={showRoomList}
                        name="showroom_no" 
                        value="showroom_no"
                        text="showroom_nm" 
                        label="서비스센터"
                        defaultMessage={"선택"}
                        className='required'
                        readOnly={!props.selectAuth}
                        onChange={(e) => changeShowRoom(e)}
                    />
                    <CPSelectTag 
                        control={control}
                        data={courtesyCarList}
                        name="courtesy_car_no" 
                        value="sel_cd"
                        text="sel_nm" 
                        label="커티시카"
                        fullWidth
                        defaultMessage="전체"
                    />
                    <CPDatePicker
                        control={control}
                        name="courtesy_rsv_time_from"
                        label="대차기간" 
                        className='required'
                        maxDate={courtesyRsvTimeTo || null}
                    />
                    <Typography>~</Typography>
                    <CPDatePicker
                        control={control}
                        name="courtesy_rsv_time_to"
                        label="대차기간" 
                        className='required'
                        minDate={courtesyRsvTimeFrom || null}
                    />
                </Stack>
                {validSearchInfo &&<HelperTextBox sx={{color: "#d32f2f", margin: "-5px 0px 10px 0 !important", width: "100% !important"}}>{"서비스센터와 대차기간을 모두 입력해 주세요."}</HelperTextBox>}    
                <DataGrid
                    disableColumnMenu
                    autoHeight
                    rows={ result }
                    getRowId={(row) => row.courtesy_reserve_seq}
                    getRowHeight={()=>'60px'}
                    getRowClassName={(params) => params.row.courtesy_eform_yn === 'Y' ? 'gray-background' : ''}
                    columns={ columns }
                    onRowClick={(e) => tableRowEvents(e)}
                    sx={{ '--DataGrid-overlayHeight': '100px',
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none'
                        },
                    '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                    }  
                    }}
                    slots={{
                        noRowsOverlay: () => (
                            <Box sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>조회 결과가 없습니다.</Box>
                        )
                    }}
                    hideFooter
                />        
                <FormControlLabel 
                    control={<Checkbox size="small" checked={includeCourtesyEform} />} 
                    label="계약서 작성 건 포함"
                    onChange={(e) => handleIncludeCourtesyEform(e.target.checked)}
                />
                <CPPaging page={page} totalItemsCount={totalCount} func={getInfoList} itemsCountPerPage={itemsCountPerPage}/>
            </DialogContent>
        </Dialog>
    </>
  );
};

export default CPCrtSearchModal;