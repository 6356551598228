import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

export const ContentPaper = styled(Paper)((props)=>({
    marginBottom: '10px'
}));

export const Title = styled(Typography)(()=>({
    fontSize:'2rem'
}));

export const TitleBox = styled(Box)(()=>({
    width:'100%',
    maxWidth: '500'
}));

export const IntroText = styled(Typography)(()=>({
    color: '#717171'
}));

export const IntroBox = styled(Box)(()=>({
    marginBottom: '25px'
}));

export const TitleChip = styled(Chip)(()=>({
    fontSize:'1rem',
    fontWeight:600,
    color : '#777777',
    backgroundColor : '#0000'

}));

export const WarningTextBox = styled(Box)(()=>({
    fontSize:'0.7rem', 
    paddingLeft:'10px',
    width: '100%',
    color: '#d32f2f',
    margin: '0 !important'
}));

export const RegisterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5'}
  }));

  