import React, {useState, useEffect} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';

const CustomToggleButton = styled(ToggleButton)({
    "&.Mui-selected": {
        "&.Mui-disabled": {
            backgroundColor: "#0000001f",
            color: "#00000042",
          },
      },
});

function CPToggleButtonGroup(props) {
    const {label, value, data, onChange, className, control, name, rules, readOnly, fullWidth, disabled} = props;
    //const [selectedValue, setSelectedValue] = useState(value || "");

    const handleChange = (event, newValue) => {
        //setSelectedValue(newValue);
        if(newValue !== null && onChange !== null) {
            onChange(newValue);
        }
    }

    return (
        <div className={className && "left-label-div"}>
            {className && 
            <label className={className === "required" ? Object.is(disabled,true)?"":"required2" : ""}>
                {label}
            </label>
            }
            {control && (
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({field}) => (
                        <ToggleButtonGroup
                            {...field}
                            exclusive
                            color="primary"
                            size="small"
                            aria-label={label}
                            fullWidth={fullWidth}
                            disabled={Object.is(disabled,true)?true:false}
                            >
                            {data?.map((item, index) => (
                                <CustomToggleButton
                                    key={index}
                                    value={item.detail_cd_id}
                                    onClick={() => {
                                        if(!readOnly){
                                            field.onChange(item.detail_cd_id);
                                            handleChange(item.detail_cd_id);
                                        }
                                    }}
                                >
                                    {item.cd_nm}
                                </CustomToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                    />
            )}
            {!control && (
                <ToggleButtonGroup 
                    name={name}
                    exclusive
                    color="primary"
                    size="small"
                    aria-label={label}
                    fullWidth={fullWidth}
                    disabled={Object.is(disabled,true)?true:false}
                    >
                    {data?.map((item, index) => (
                        <ToggleButton
                            key={index}
                            value={item.detail_cd_id}
                            onClick={() => {
                                if(!readOnly) handleChange(item.detail_cd_id);
                            }}
                        >
                            {item.cd_nm}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
        </div>
    );
}

export default CPToggleButtonGroup;