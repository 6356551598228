const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100dvh',
      textAlign: 'center',
      backgroundColor: '#f8f9fa',
      paddingBottom: '150px',
      color: '#333',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '2rem',
    },
    message: {
      fontSize: '1rem',
      margin: 0
    },
  };

function ErrorPage() {
    return (
        <div style={styles.container}>

            <h1 style={styles.title}>페이지를 찾을 수 없습니다.</h1>
            <p style={styles.message}>존재하지 않거나 만료된 페이지입니다.</p>
            <p style={styles.message}>올바른 URL을 입력하였는지 확인 후 다시 시도해 주세요.</p>
        </div>
    )
}

export default ErrorPage;