import React, { useEffect, useState, useRef } from 'react';
/**외부 */
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import DaumPostcode from 'react-daum-postcode';

/**customized mui */
import {IntroText, IntroBox, TitleBox, Title, TitleChip, ContentPaper, WarningTextBox, RegisterButton} from '../../styles/svcPrvAgr/SvcPrvAgrStyle';

/**mui */
import {Paper, FormControlLabel, Grid} from '@mui/material';
import {List,  ListItemButton, ListItemText, MenuItem} from '@mui/material';
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {Checkbox, Divider, Alert, Button, Stack, Typography, TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

/**mui icon */
import { ExpandLess, ExpandMore} from '@mui/icons-material';
import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';

/**내부 */
import * as crm4u from '../../services/apis/crm4u/svcApi';
import * as utils from '../../commons/utils';
import '../../styles/svcPrvAgr/SvcPrvAgr.css';
import '../../styles/base.css';
import * as confStore from '../../stores/confStore';
import { userState } from '../../stores/UserState';
import { alertState, loadingState } from '../../stores/ComponentState';

function SvcPrvAgrInfo() { 

    const userInfo = useRecoilValue(userState);
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    const svcPrvAgr = confStore.prvAgr[userInfo.dealer_cd].versions.find(version=> version.ver_no === '0.1');
    const sigCanvas = useRef(null);
    const { register,
            handleSubmit,
            setValue,
            getValues,
            control,
            formState: {errors, isSubmitted },
        } = useForm({
        defaultValue: {
            insertType : null,
            client_nm : null,
            phone_no1 : null,
            phone_no2 : null,
            phone_no3 : null,
            dealer_cd : null,
            seq : null,
            prv_agr_ver_no : null,
            hiddenpath : null,
            hiddenimg : null,
            all_agree_yn : null,
            agree_yn_01 : null,
            agree_yn_02 : null,
            agree_yn_03 : null,
            agree_yn_04 : null,
            agree_yn_05 : null,
            agree_yn_06 : null,
            agree_yn_07 : null,
            agree_yn_08 : null,
            agree_yn_09 : null,
        }
    }); 

    const thXsVal = 2;
    const tdXsVal = 10;

    const [zonecode, setZonecode] = useState('');
    const [address, setAddress] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    
    const themeObj = {
        bgColor: '#FFFFFF', 
        pageBgColor: '#FFFFFF', 
        postcodeTextColor: '#C05850',
        emphTextColor: '#222222',
      };
    
    const postCodeStyle = {
        width: '370px',
        height: '490px',
        padding: '10px',
    };

    const completeHandler = (data) => {
        const { address, zonecode } = data;
        setAddress(address);
        setZonecode(zonecode);
    };

    const closeHandler = (state) => {
        if(state === 'FORCE_CLOSE') {
            setIsOpen(false);
        } else if (state === 'COMPLETE_CLOSE') {
            setIsOpen(false);
        }
    };

    const toggleHandler = () => {
        setIsOpen((prevOpenState) => !prevOpenState);
    };

    useEffect(() => {
        console.log(isOpen); 
    }, [isOpen]);


    return (
        <div>
            <form onSubmit={handleSubmit()}>
                <TitleBox>
                    <Title gutterBottom>
                    {svcPrvAgr.title} {svcPrvAgr.ver_no}
                    </Title>
                </TitleBox>
                <IntroBox>
                    <IntroText> 
                        아래 사항을 기재해주세요.
                    </IntroText>
                </IntroBox>
                <Paper sx={{ p: 3 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ p: 2, height: '100px' }} alignItems="center">
                            <Grid container alignItems="center" sx={{height: '100%'}}>
                                <Grid item xs={thXsVal}>
                                    <Typography className="infoThText">
                                        성명
                                    </Typography>
                                </Grid>
                                <Grid item xs={tdXsVal}> 
                                    <TextField 
                                        size='small'
                                        fullWidth
                                        inputProps={{maxLength: 13}}
                                        InputLabelProps={{shrink: true}}
                                        label="필수값" // required
                                        className="required" // required
                                    />
                                    {/* {errors?.ew_client_nm && <WarningTextBox>{errors?.ew_client_nm?.message}</WarningTextBox>}                          */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ p: 2, height: '100px' }} alignItems="center">
                            <Grid container alignItems="center" sx={{height: '100%'}}>
                                <Grid item xs={thXsVal}>
                                    <Typography>
                                        휴대전화번호
                                    </Typography>
                                </Grid>
                                <Grid  item xs={tdXsVal}> 
                                    <TextField 
                                        size='small'
                                        fullWidth
                                        inputProps={{maxLength: 13}}
                                        InputLabelProps={{shrink: true}}
                                        label="필수값" // required
                                        className="required" // required
                                    />  
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ p: 2, height: '100px' }} alignItems="center">
                            <Grid container alignItems="center" sx={{height: '100%'}}>
                                <Grid item xs={thXsVal}>
                                    <Typography>
                                        이메일
                                    </Typography>
                                </Grid>
                                <Grid  item xs={tdXsVal}>  
                                    <TextField
                                        size='small'
                                        fullWidth
                                        inputProps={{maxLength: 13}}
                                        InputLabelProps={{shrink: true}}
                                        helperText="abc@kcc.co.kr 과 같은 형식으로 정확히 기재해주세요."
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ p: 2, height: '100px' }} alignItems="center">
                            <Grid container alignItems="center" sx={{height: '100%'}}>
                                <Grid item xs={thXsVal}>
                                    <Typography>
                                        주소
                                    </Typography>
                                </Grid>
                                <Grid item xs={tdXsVal}>
                                    <Stack>
                                        <Stack direction="row">
                                            <TextField
                                                size='small'
                                                inputProps={{maxLength: 13, readOnly: true}}
                                                InputLabelProps={{shrink: true}}
                                                label="우편번호"
                                                value={zonecode}
                                                />
                                            <IconButton sx={{pr: 0}}
                                                onClick={toggleHandler}>
                                                <SearchIcon />
                                            </IconButton>
                                            {isOpen && <div
                                                            className="popupDaumDiv"
                                                            >
                                                <DaumPostcode
                                                    className="popupDaum"
                                                    theme={themeObj}
                                                    style={postCodeStyle}
                                                    onComplete={completeHandler}
                                                    onClose={closeHandler}
                                                />
                                            </div>}
                                        </Stack>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            inputProps={{maxLength: 13, readOnly: true}}
                                            InputLabelProps={{shrink: true}}
                                            label="기본주소"
                                            value={address}
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            inputProps={{maxLength: 13}}
                                            InputLabelProps={{shrink: true}}
                                            label="상세주소"
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ p: 2, height: '100px' }}>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <RegisterButton type="button">대면등록</RegisterButton>
                                <RegisterButton type="button">비대면등록(SMS전송)</RegisterButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </div>
    )
}

export default SvcPrvAgrInfo;