import { atom } from 'recoil';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Description, DescriptionSharp } from '@mui/icons-material';

export const dealerList = ["kccauto", "demo"];

export const menuList = {
    DLRDEMO : [
        {
            id: 1,
            title: "보증연장상품",
            
            child: [
                {
                    id: 1,
                    title: "계약서작성",
                    route: "/contracts/select",
                    icon: <EditNoteOutlinedIcon />,
                    metaSeq: [100, 200]
                },
                {
                    id: 2,
                    title: "계약서목록",
                    route: "/ewList",
                    icon: <ListAltIcon/>
                },
                {
                    id: 3,
                    title: "임시보관함",
                    icon: <InsertDriveFileOutlinedIcon/>
                }
            ]
        },
        {
            id: 2,
            title: "커티시카 대차예약",
            child: [
                {
                    id: 1,
                    title: "계약서 작성",
                    route: "/crtsy/select",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "계약서 목록",
                    route: "/crtsy/list",
                    icon: <ListAltIcon />
                }
            ]
        },
        {
            id: 3,
            title: "서비스동의서",
            child: [
                {
                    id: 1,
                    title: "개인정보동의서 양식",
                    route: "/svcPrvAgr/form",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "개인정보동의서 작성",
                    route: "/svcPrvAgr/info",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 3,
                    title: "개인정보동의서 목록",
                    route: "/svcPrvAgr/list",
                    icon: <ListAltIcon />
                }
            ]
        }
    ],
    DLR00001 : [
        {
            id: 1,
            title: "보증연장상품",
            child: [
                {
                    id: 1,
                    title: "계약서작성",
                    route: "/contracts/select",
                    icon: <EditNoteOutlinedIcon />,
                    metaSeq: [100, 200]
                },
                {
                    id: 2,
                    title: "계약서목록",
                    route: "/ewList",
                    icon: <ListAltIcon/>
                },
                // {
                //     id: 3,
                //     title: "임시보관함",
                //     icon: <InsertDriveFileOutlinedIcon/>
                // }
            ]
        },
        {
            id: 2,
            title: "커티시카 대차예약",
            child: [
                {
                    id: 1,
                    title: "계약서 작성",
                    route: "/crtsy/select",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "계약서 목록",
                    route: "/crtsy/list",
                    icon: <ListAltIcon />
                }
            ]
        },
        // {
        //     id: 3,
        //     title: "서비스동의서",
        //     child: [
        //         {
        //             id: 1,
        //             title: "개인정보동의서 양식",
        //             route: "/svcPrvAgr/form",
        //             icon: <EditNoteOutlinedIcon />
        //         },
        //         {
        //             id: 2,
        //             title: "개인정보동의서 작성",
        //             route: "/svcPrvAgr/info",
        //             icon: <EditNoteOutlinedIcon />
        //         },
        //         {
        //             id: 3,
        //             title: "개인정보동의서 목록",
        //             route: "/svcPrvAgr/list",
        //             icon: <ListAltIcon />
        //         }
        //     ]
        // }
    ],
    DLR00040 : [
        {
            id: 1,
            title: "자동차매매계약서",
            child: [
                {
                    id: 1,
                    title: "계약서작성",
                    route: "/contracts/select",
                    icon: <EditNoteOutlinedIcon />
                },
                {
                    id: 2,
                    title: "계약서목록",
                    route: "/contracts/car",
                    icon: <ListAltIcon />
                }
            ]
        },
    ]
};

export const activeMenu = atom({
    key:"activeMenu",
    default: [{}]
});

export const crtsyReserveMenu = {
    DLRDEMO : [
        {
            id: '0',
            title : "대여 전자계약서 작성",
            description : '대여 전자계약서를 작성하는 경우',
            route : '/crtsy/write/reserve'
        }
        ,
        {
            id: '1',
            title: "반납 전자계약서 작성",
            description : '반납 전자계약서를 작성하는 경우',
            route : '/crtsy/write/return'
        }
    ]
    ,
    DLR00001 : [
        {
            id: '0',
            title : "대여 전자계약서 작성",
            description : '대여 전자계약서를 작성하는 경우',
            route : '/crtsy/write/reserve'
        }
        ,
        {
            id: '1',
            title: "반납 전자계약서 작성",
            description : '반납 전자계약서를 작성하는 경우',
            route : '/crtsy/write/return'
        }
    ]
}

export const svcPrvAgr = {
    DLRDEMO : [
        {
            ver_no : '0.1',
            title : '서비스 개인정보 수집/처리 동의서',
            to : '케이씨씨오토㈜ 및 메르세데스-벤츠 코리아㈜ 귀중',
            message : '케이씨씨오토㈜(이하 “갑(매도인)”)와 메르세데스-벤츠 코리아㈜는 본인(이하 “을(매수인)”)의 개인정보를 수집, 이용 및 제공할 때 ‘개인정보 보호법’에 따라 을(매수인)의 동의를 얻어야 합니다. 을(매수인)은 아래 기재된 각 내용을 확인하고 충분히 이해하였으며, 아래 내용에 따라 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 을(매수인)의 개인정보 및 차량 관련 정보(이하 “개인정보 등”)를 수집·이용, 제공 등 처리하는 것에 대하여 자발적으로 동의합니다.',
            child : [
                {
                    num : '1',
                    title : '개인정보등의 수집 및 이용에 대한 동의',
                    toggled : 'Y',
                    grouped : 'Y',
                    ans_yn : 'N',
                    child : [
                        {
                            num : '1',
                            title : '일반 개인정보의 수집 및 이용에 대한 동의',
                            toggled : 'Y',
                            grouped : 'Y',
                            ans_yn : 'Y',
                            ans_col_no : '1',
                            required : 'Y',
                            content : {
                                top_message : '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 수집하여 이용하는 것에 대하여 동의합니다.'
                            }
                        }
                    ]
                },
                {
                    num : '2',
                    title : '개인정보 등의 제3자 제공에 대한 동의',
                    toggled : 'Y',
                    grouped : 'Y',
                    ans_yn : 'N',
                    child : [
                        {
                            num : '1',
                            title : '일반 개인정보의 제3자 제공에 대한 동의',
                            toggled : 'Y',
                            grouped : 'Y',
                            ans_yn : 'N',
                        }
                    ]
                }
            ]
        }
    ],
    DLR00001 : [
        {
            ver_no : '0.1',
            title : '서비스 개인정보 수집/처리 동의서',
            to : '케이씨씨오토㈜ 및 메르세데스-벤츠 코리아㈜ 귀중',
            message : '케이씨씨오토㈜(이하 “갑(매도인)”)와 메르세데스-벤츠 코리아㈜는 본인(이하 “을(매수인)”)의 개인정보를 수집, 이용 및 제공할 때 ‘개인정보 보호법’에 따라 을(매수인)의 동의를 얻어야 합니다. 을(매수인)은 아래 기재된 각 내용을 확인하고 충분히 이해하였으며, 아래 내용에 따라 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 을(매수인)의 개인정보 및 차량 관련 정보(이하 “개인정보 등”)를 수집·이용, 제공 등 처리하는 것에 대하여 자발적으로 동의합니다.'
        }
    ]
};

export const prvAgr = {
    DLR00001: {
        versions: [
            {
                ver_no: '0.1',
                title: '서비스 개인정보 수집/처리 동의서',
                to: '케이씨씨오토㈜ 및 메르세데스-벤츠 코리아㈜ 귀중',
                message: '케이씨씨오토㈜(이하 “갑(매도인)”)와 메르세데스-벤츠 코리아㈜는 본인(이하 “을(매수인)”)의 개인정보를 수집, 이용 및 제공할 때 ‘개인정보 보호법’에 따라 을(매수인)의 동의를 얻어야 합니다. 을(매수인)은 아래 기재된 각 내용을 확인하고 충분히 이해하였으며, 아래 내용에 따라 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 을(매수인)의 개인정보 및 차량 관련 정보(이하 “개인정보 등”)를 수집·이용, 제공 등 처리하는 것에 대하여 자발적으로 동의합니다.',
                items: [
                    {
                        id: '1',
                        title: '개인정보등의 수집 및 이용에 대한 동의',
                        depth: '1',
                        required: 'YN',
                        checkbox: true,
                        toggle: true,
                        children: [
                            {
                                id: '1.1',
                                title: '일반 개인정보의 수집 및 이용에 대한 동의',
                                depth: '2',
                                parentId: '1',
                                saveColumnNum: '01',
                                required: true,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 수집하여 이용하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '3',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '구분'},
                                         {thYn: 'Y', colspan:1, rowspan:1, text: '개인정보'},
                                         {thYn: 'Y', colspan:1, rowspan:1, text: '차량정보'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집항목'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '성명(상호)(국∙영문), 생년월일, 성별, 사업자등록번호, 직업, 직장명, 직급, 주소(자택, 사업장 및 직장), 전화번호(자택/직장, 핸드폰 번호), 이메일.'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '차종(모델명), 차대번호, 차량번호, 연식, 최초등록일, 선택사양, 특별사양, 인도일자(출고일자), 인도장소(출고전시장 및 담당영업사원), 신차 또는 중고차 여부, 차량가격, 차량정비 및 수리이력, 차량정비 및 수리 관련 교신내역, claim 이력, 서비스 상품 보유 여부, 서비스 상품 종류 및 계약기간, 등록지,명의자,권리이전관계 등 자동차등록증에 기재된 정보.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집 및 이용목적'},
                                         {thYn: 'N', colspan:2, rowspan:1, text: '계약 관련 연락 및 문의/요청사항의 처리, 대금결제 및 추심활동, 차량등록대행, 설문조사, 품질향상을 위한 정보확인, Recall 관련 업무, 차량 점검 및 보증수리를 포함한 각종 차량수리 서비스, 긴급출동 서비스, 사고조사, 고객불만 처리, 도난방지 관련 부품 주문, 사고 조사 의뢰인(소유자/대리인)에 대한 사고 관련 사실 확인 및 의뢰 대상 차량 확인∙검사, 차량의 인도 및 수령, 품질 조회 및 확인, 구매 전∙후의 이미지 및 의견 확인, 차량 판매 및 등록 등 관련 정보 분석 및 통계작성 등.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '보유 및 이용기간'},
                                         {thYn: 'N', colspan:2, rowspan:1, text: '해당 정보를 제공받은 날로부터 10년(다만, 위 기간의 종료 후에도 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 일정한 기간 동안 개인정보를 보관함). 또한 을(매수인)의 삭제 요청 시 관계 법령에 따라 해당 정보를 보관하여야 하는 경우를 제외하고는 즉시 삭제되며, 어떠한 이유나 방법으로도 재생되거나 이용할 수 없도록 처리됨.'}]
                                    ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 차량 구매, 차량 점검 및 보증수리를 포함한 각종 사후지원 서비스, 긴급출동 서비스, Recall 관련 서비스 등을 포함하여, 상기 ‘수집∙이용목적’에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                                }
                            },
                            {
                                id: '1.2',
                                title: '일반 개인정보의 수집 및 이용에 대한 동의',
                                depth: '2',
                                parentId: '1',
                                saveColumnNum: '02',
                                required: false,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 수집하여 이용하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '2',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '구분'},
                                         {thYn: 'Y', colspan:1, rowspan:1, text: '개인정보'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집항목'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '가족관계, 결혼 여부, 개인적 관심분야 및 관심행사, 고객성향.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집 및 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '경품 제공, 혜택 안내 및 고객 맞춤형 서비스 제공.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '보유 및 이용기간'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '해당 정보를 제공받은 날로부터 10년(다만, 위 기간의 종료 후에도 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 일정한 기간 동안 개인정보를 보관함). 또한 을(매수인)의 삭제 요청 시 관계 법령에 따라 해당 정보를 보관하여야 하는 경우를 제외하고는 즉시 삭제되며, 어떠한 이유나 방법으로도 재생되거나 이용할 수 없도록 처리됨.'}]
                                    ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 상기 ‘수집∙이용목적’에 기재된 서비스 제공 및 관련 정보 전달 등이 제한될 수 있음을 알려드립니다.'
                                }
                            },
                            {
                                id: '1.3',
                                title: '고유식별정보의 수집 및 이용에 대한 동의',
                                depth: '2',
                                parentId: '1',
                                saveColumnNum: '03',
                                required: true,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 수집하여 이용하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '2',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '구분'},
                                         {thYn: 'Y', colspan:1, rowspan:1, text: '개인정보'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집항목'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '외국인등록번호(외국인에 한함), 여권번호(주민등록번호 및 외국인등록번호가 없는 경우에 한함)..'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집 및 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '대금결제 및 추심활동, 차량등록대행, Recall 관련 업무, 차량 점검 및 보증수리를 포함한 각종 차량수리 서비스, 긴급출동 서비스, 사고조사, 도난방지 관련 부품 주문, 사고 조사 의뢰인(소유자/대리인)에 대한 사고 관련 사실 확인 및 의뢰 대상 차량 확인∙검사, 차량의 인도 및 수령, 품질 조회 및 확인 등.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '보유 및 이용기간'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '해당 정보를 제공받은 날로부터 10년(다만, 위 기간의 종료 후에도 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 일정한 기간 동안 개인정보를 보관함). 또한 을(매수인)의 삭제 요청 시 관계 법령에 따라 해당 정보를 보관하여야 하는 경우를 제외하고는 즉시 삭제되며, 어떠한 이유나 방법으로도 재생되거나 이용할 수 없도록 처리됨.'}]
                                       ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 차량 구매, 차량 점검 및 보증수리를 포함한 각종 사후지원 서비스, 긴급출동 서비스, Recall 관련 서비스 등을 포함하여, 상기 ‘수집∙이용목적’에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                                }
                            },
                        ]
                    },
                    {
                        id: '2',
                        title: '개인정보 등의 제3자 제공에 대한 동의',
                        depth: '1',
                        required: false,
                        checkbox: true,
                        toggle: true,
                        children: [
                            {
                                id: '2.1',
                                title: '일반 개인정보의 제3자 제공에 대한 동의',
                                depth: '2',
                                parentId: '2',
                                saveColumnNum: '04',
                                required: false,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 제3자에게 제공하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '2',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '개인정보 등을 제공받는 자'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '- Daimler AG, Mercedes-Benz AG, 메르세데스-벤츠 코리아㈜의 계열사(다임러트럭코리아㈜ (02-6456-2500), 메르세데스-벤츠 파이낸셜 서비스 코리아㈜(02-1577-2320), 메르세데스-벤츠 모빌리티 코리아㈜(080-001-1886) - 메르세데스-벤츠 코리아㈜의 공식 딜러사(한성자동차㈜(1522-3421),더클래스효성㈜(1899-0808),㈜모터원(1899-7772),스타자동차㈜(1688-2369),중앙모터스㈜(053-624-2000),경남자동차판매㈜(1899-3617),㈜진모터스(063-226-8000),신성자동차㈜(062-226-0001),케이씨씨오토㈜(02-6355-0010),교학모터스㈜(02-2212-1886),한성모터스㈜(1566-0801),소비자원(043-880-5500) 케이씨씨오토㈜위임에 따라 개인정보 처리등의 업무를 위탁 받아 처리하는 케이씨씨정보통신㈜(02-6090-7800),플러스캐리어(1661-8643),㈜시스원(02-6090-6800),오토레고(02-814-0404),오복사(032-553-8817),서울디엠(02-807-7100),㈜모비엠(070-4607-1232),㈜조선일보사(02-724-5888),㈜케이티(1588-0114),㈜엠앤와이즈(02-865-4210),관세법인나래(070-4014-3983), ㈜스카이넥트웍스 (070-7726-8360), ㈜다음카카오(1577-3754),MCOM,㈜섹타나인(02-2040-1004) 갑(매도인)[www.kccauto.co.kr]이나 메르세데스-벤츠 코리아㈜ 홈페이지[www.mercedes-benz.co.kr]의 “개인정보처리방침”에 고지되어 있는 제3자.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '제공받는 자의 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '- 각종 서비스의 안내 및 제공, 통계작성, 대금결제 및 추심활동, 차량등록대행, 메르세데스카드 가입 안내, 고객서비스(각종 부가서비스 포함), 신상품이나 이벤트 정보안내 및 진행, 경품발송, 설문조사, 품질향상을 위한 정보확인, Recall 관련 업무, 차량점검 및 보증수리를 포함한 각종 차량수리, 사고조사, 고객불만 처리, 도난방지 관련 부품 주문, 사고조사 의뢰 시 고객 동의에 의거하여 사고조사가 진행되었다는 입증 자료 등 - 기타 각종 마케팅(로열티 프로그램 등) 및 홍보, 판촉활동 등.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '제공하는 개인정보 등의 항목'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '제1.(1)항 기재 수집항목과 동일함.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '제공받는 자의 보유 및 이용기간'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '제1.(1)항 기재 보유 및 이용기간과 동일함.'}]
                                       ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 차량 점검 및 보증수리를 포함한 각종 사후지원 서비스, Recall 관련 서비스, 메르세데스카드 가입 안내, 고객 부가 혜택 서비스 등을 포함하여, 상기 ‘제공받는 자의 이용목적’에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                                }
                            },
                            {
                                id: '2.2',
                                title: '고유식별정보의 제3자 제공에 대한 동의',
                                depth: '2',
                                parentId: '2',
                                saveColumnNum: '05',
                                required: false,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 제3자에게 제공하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '2',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '개인정보 등을 제공받는 자'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '- Daimler AG, Mercedes-Benz AG, 메르세데스-벤츠 코리아㈜의 계열사(다임러트럭코리아㈜ (02-6456-2500), 메르세데스-벤츠 파이낸셜 서비스 코리아㈜(02-1577-2320), 메르세데스-벤츠 모빌리티 코리아㈜(080-001-1886) - 메르세데스-벤츠 코리아㈜의 공식 딜러사{(한성자동차㈜(1522-3421),더클래스효성㈜(1899-0808),㈜모터원(1899-7772),스타자동차㈜(1688-2369),중앙모터스㈜(053-624-2000),경남자동차판매㈜(1899-3617),㈜진모터스(063-226-8000),신성자동차㈜(062-226-0001),케이씨씨오토㈜(02-6355-0010),교학모터스㈜(02-2212-1886), 한성모터스㈜(1566-0801), 소비자원(043-880-5500) 케이씨씨오토㈜위임에 따라 개인정보 처리등의 업무를 위탁 받아 처리하는 케이씨씨정보통신㈜(02-6090-7800),플러스캐리어(1661-8643),㈜시스원(02-6090-6800),오토레고(02-814-0404),오복사(032-553-8817),서울디엠(02-807-7100),㈜모비엠(070-4607-1232),㈜조선일보사(02-724-5888),㈜케이티(1588-0114),㈜엠앤와이즈(02-865-4210),관세법인나래(070-4014-3983), ㈜스카이넥트웍스 (070-7726-8360), ㈜다음카카오(1577-3754),MCOM,㈜섹타나인(02-2040-1004) 갑(매도인)[www.kccauto.co.kr]이나 메르세데스-벤츠 코리아㈜ 홈페이지[www.mercedes-benz.co.kr]의 “개인정보처리방침”에 고지되어 있는 제3자.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '제공받는 자의 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '대금결제 및 추심활동, 차량등록대행, Recall 관련 업무, 차량점검 및 보증수리를 포함한 각종 차량 수리 서비스, 사고조사, 도난방지 관련 부품 주문, 사고조사 의뢰 시 고객 동의에 의거하여 사고조사가 진행되었다는 입증 자료 등.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '수집 및 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '외국인등록번호(외국인에 한함), 여권번호(주민등록번호 및 외국인등록번호가 없는 경우에 한함).'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '제공받는 자의 보유 및 이용기간'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '제1.(1)항 기재 보유 및 이용기간과 동일함.'}]
                                    ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 차량 점검 및 보증수리를 포함한 각종 사후지원 서비스, Recall 관련 서비스, 메르세데스카드 가입 안내, 고객 부가 혜택 서비스 등을 포함하여, 상기 ‘제공받는 자의 이용목적’에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                                }
                            },
                            {
                                id: '2.3',
                                title: '일반 개인정보의 국외 이전에 대한 동의',
                                depth: '2',
                                parentId: '2',
                                saveColumnNum: '06',
                                required: false,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 개인정보 등을 국외 이전하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '2',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '개인정보 등을 이전받는 자'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '- Mercedes-Benz Group AG (+49 711 17 0), Mercedes-Benz AG (+49 711 17 0).'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '이전받는 자의 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '- 각종 서비스의 안내 및 제공, 통계작성, 대금결제 및 추심활동, 차량등록대행, 메르세데스카드 가입 안내, 고객서비스(각종 부가서비스 포함), 신상품이나 이벤트 정보안내 및 진행, 경품발송, 설문조사, 품질향상을 위한 정보확인, Recall 관련 업무, 차량점검 및 보증수리를 포함한 각종 차량수리, 사고조사, 고객불만 처리, 도난방지 관련 부품 주문, 사고조사 의뢰 시 고객 동의에 의거하여 사고조사가 진행되었다는 입증 자료 등 - 기타 각종 마케팅(로열티 프로그램 등) 및 홍보, 판촉활동 등.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '이전하는 개인정보 등의 항목'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '제1.(1)항 기재 수집항목과 동일함.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '이전받는 자의 보유 및 이용기간'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '제1.(1)항 기재 보유 및 이용기간과 동일함.'}]
                                       ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 차량 점검 및 보증수리를 포함한 각종 사후지원 서비스, Recall 관련 서비스, 메르세데스카드 가입 안내, 고객 부가 혜택 서비스 등을 포함하여, 상기 ‘이전 받는 자의 이용목적’에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                                }
                            },
                            {
                                id: '2.4',
                                title: '고유식별정보의 국외 이전에 대한 동의',
                                depth: '2',
                                parentId: '2',
                                saveColumnNum: '07',
                                required: false,
                                checkbox: true,
                                toggle: true,
                                content: {
                                    top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 고유식별정보를 수집하여 이용하는 것에 대하여 동의합니다.',
                                    rowCnt: '4',
                                    colCnt: '2',
                                    table: [
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '개인정보 등을 이전받는 자'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '- Mercedes-Benz Group AG (+49 711 17 0), Mercedes-Benz AG (+49 711 17 0).'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '이전받는 자의 이용목적'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '대금결제 및 추심활동, 차량등록대행, Recall 관련 업무, 차량점검 및 보증수리를 포함한 각종 차량 수리 서비스, 사고조사, 도난방지 관련 부품 주문, 사고조사 의뢰 시 고객 동의에 의거하여 사고조사가 진행되었다는 입증 자료 등.'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '이전하는 개인정보 등의 항목'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '외국인등록번호(외국인에 한함), 여권번호(주민등록번호 및 외국인등록번호가 없는 경우에 한함).'}],
                                        [{thYn: 'Y', colspan:1, rowspan:1, text: '이전받는 자의 보유 및 이용기간'},
                                         {thYn: 'N', colspan:1, rowspan:1, text: '제1.(1)항 기재 보유 및 이용기간과 동일함.'}]
                                       ],
                                    bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 차량 점검 및 보증수리를 포함한 각종 사후지원 서비스, Recall 관련 서비스, 메르세데스카드 가입 안내, 고객 부가 혜택 서비스 등을 포함하여, 상기 ‘이전 받는 자의 이용목적’에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                                }
                            }
                        ]
                    },
                    {
                        id: '3',
                        title: '영리목적 광고성 정보 전송 및 개인정보 등의 홍보 및 판촉 목적 이용에 대한 동의',
                        depth: '1',
                        saveColumnNum: '08',
                        required: false,
                        checkbox: true,
                        toggle: true,
                        content: {
                            top_message: '을(매수인)은 아래 표에 기재된 바와 같이 갑(매도인) 및 메르세데스-벤츠 코리아㈜가 본인의 고유식별정보를 수집하여 이용하는 것에 대하여 동의합니다.',
                            rowCnt: '4',
                            colCnt: '3',
                            table: [
                                [{thYn: 'Y', colspan:1, rowspan:1, text: '구분'},
                                 {thYn: 'Y', colspan:1, rowspan:1, text: '개인정보'},
                                 {thYn: 'Y', colspan:1, rowspan:1, text: '차량정보'}],
                                [{thYn: 'Y', colspan:1, rowspan:1, text: '수집항목'},
                                 {thYn: 'N', colspan:1, rowspan:1, text: '성명(상호)(국,영문), 생년월일, 성별, 사업자등록번호, 직업, 직장명, 직급, 주소(자택, 사업장 및 직장) 전화번호(자택/직장, 핸드폰 번호), 이메일, 가족관계, 결혼 여부, 개인적 관심분야 및 관심행사, 고객성향 등.'},
                                 {thYn: 'N', colspan:1, rowspan:1, text: '차종(모델명), 차대번호, 차량번호, 연식, 최초등록일, 선택사양, 특별사양, 인도일자(출고일자), 인도장소(출고전시장 및 담당영업사원), 신차 또는 중고차 여부, 차량가격, 차량정비 및 수리이력, claim 이력, 서비스 상품 보유 여부, 서비스 상품 종류 및 계약 기간, 자동차등록증 상 명의자, 권리이전관계 등.'}],
                                [{thYn: 'Y', colspan:1, rowspan:1, text: '수집 및 이용목적'},
                                 {thYn: 'N', colspan:2, rowspan:1, text: '- 메르세데스카드 가입안내, 고객서비스(각종 부가서비스 포함), 전화/이메일/SMS 등을 통한 신상품, 이벤트 정보 안내 - 신차 또는 신상품 소개, 이벤트 정보 안내, 기타 각종 마케팅활동 (로열티 프로그램 등) 및 홍보, 판촉활동.'}],
                                [{thYn: 'Y', colspan:1, rowspan:1, text: '보유 및 이용기간'},
                                 {thYn: 'N', colspan:2, rowspan:1, text: '제1.(1)항 기재 보유 및 이용기간과 동일함.'}]
                            ],
                            bottom_message: '귀하는 위 사항에 대하여 동의를 하지 않을 수 있으나, 동의를 거부하는 경우 상기 "수집∙이용목적"에 기재된 서비스의 이용 및 제공이 제한될 수 있음을 알려드립니다.'
                        }
                    },
                    {
                        id: '4',
                        title: '개인정보 처리위탁에 대한 안내',
                        depth: '1',
                        required: false,
                        checkbox: false,
                        toggle: true,
                        content: {
                            top_message: '갑(매도인) 및 메르세데스-벤츠 코리아㈜는 재화 또는 서비스에 대한 안내를 제공하기 위하여 아래와 같이 개인정보 처리업무를 위탁하고 있습니다.',
                            rowCnt: '2',
                            colCnt: '2',
                            table: [
                                [{thYn: 'Y', colspan:1, rowspan:1, text: '수탁자'},
                                 {thYn: 'N', colspan:1, rowspan:1, text: '㈜유베이스, ㈜칸타코리아, ㈜이알플러스, (주)코드레아, 엠앤와이즈(주), 스포츠웨이브, 블루인 마케팅 서비스, ㈜슈어엠, ㈜마크잇, ㈜모바일이앤앰에드, ㈜이가미드, Ipsos GmbH, 팀엑스㈜, ㈜컨슈머인사이트, ㈜에이치에스애드, ㈜피알게이트, ㈜볼륨유닛, ㈜웰콤퍼블리시스월드와이드, 닐슨컴퍼니코리아 유한회사, 모츠㈜, ㈜링커블, ㈜차지비, ㈜포스코ICT, CJ 대한통운, MCOM.'}],
                                [{thYn: 'Y', colspan:1, rowspan:1, text: '수탁자가 처리하는 업무의 내용 및 범위'},
                                 {thYn: 'N', colspan:1, rowspan:1, text: '- 신차 소개, 메르세데스카드 가입안내, 고객서비스(각종 부가서비스 포함), 이벤트 정보안내 및 진행, 경품 발송, 설문조사 - 기타 각종 마케팅활동(지원 프로그램 등) 및 홍보, 판촉활동 등 - 차량 등록 대행, 차량 배송 서비스'}]
                            ]
                        }
                    },
                    {
                        id: '5',
                        title: '긴급구조 (eCall) 위치정보 이용약관에 대한 동의',
                        depth: '1',
                        saveColumnNum: '09',
                        required: false,
                        checkbox: true,
                        toggle: true,
                        content: {
                            top_message: '을(매수인)은 아래와 같은 eCall 관련 위치정보의 수집 및 이용에 대해 동의합니다.',
                            rowCnt: '1',
                            colCnt: '1',
                            table: [
                                [
                                 {thYn: 'N', colspan:1, rowspan:1, text: '긴급구조(eCall) 위치정보 이용약관<br>메르세데스-벤츠 차량은 긴급구조(eCall) 목적으로 차량의 위치정보를 일정 시간 차량 내 통신모듈에 저장하고, 교통사고가 발생하거나 차량 내 eCall 버튼이 작동된 경우 자동으로 해당 시점의 차량의 위치정보, 차대번호 기타 차량정보가 긴급구조 고객컨택센터로 전송됩니다. 전송된 경우 제공받는 자, 제공일시 및 제공목적 등을 즉시 통보하며, 위치정보 수집∙이용∙제공사실 확인자료는 위치정보시스템에 자동으로 기록하며, 관련 자료는 6개월 이상 보관합니다. 만약 eCall 기능이나 위치정보의 수집을 원하지 않는 경우, 메르세데스-벤츠 공식 딜러사에 연락하여 언제든지 비활성화 조치가 가능합니다. 단, eCall 기능을 비활성화시키면, 차량 내 통신모듈의 기능도 함께 비활성화되어 Mercedes me connect를 비롯하여 원격통신을 이용한 모든 서비스의 이용이 불가능합니다. 중고차 매매 등의 사유로 차량 소유주가 변경되는 경우, 차량의 전 소유주(양도인)는 반드시 새로운 계약자나 소유주(양수인)에게 eCall 기능은 항시 작동되고 있고 이를 위한 위치정보가 수집 및 이용될 수 있으며 이를 비활성화 조치가 가능함을 알려주어야 합니다.<br><br>상호: 메르세데스-벤츠 코리아 주식회사<br>주소/전화번호: 서울특별시 중구 한강대로 416 (남대문로5가, 서울스퀘어), 9층/080-001-1886'}]
                            ]
                        }
                    }
                ]
            },
            {
                ver_no: '0.2',
                title: '서비스 개인정보 수집/처리 동의서',
                to: 'a회사 귀중',
                message: 'a회사 동의하세요.',
                items: [
                    // 여기에 버전 0.2의 동의 항목들을 추가
                ]
            }
        ]
    },
    DLR00020: {
        versions: [
            {
                ver_no: '0.1',
                title: '서비스 개인정보 수집/처리 동의서',
                to: 'b회사 귀중',
                message: 'b회사 동의합니까?',
                items: [
                    // 여기에 DLR00020의 동의 항목들을 추가
                ]
            }
        ]
    }
};
