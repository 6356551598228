import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'; 
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil';

import * as confStore from '../../../stores/confStore';
import useCurrentViewport from '../../../hooks/useCurrentViewport';

import { userState } from '../../../stores/UserState';
import { loadingState } from '../../../stores/ComponentState';
import { ReactComponent as FaceToFaceContract } from '../../../assets/images/contract/face-to-face-contract.svg';
import { ReactComponent as NonFaceToFaceContract } from '../../../assets/images/contract/non-face-to-face-contract.svg';

function CrtsySelectContract() {

    const navigate = useNavigate();
    const location = useLocation();
    const viewPort = useCurrentViewport();
    const userInfo = useRecoilValue(userState); //사용자정보
    // const [templates, setTemplates] = useState(location?.state?.templates??[]); //템플릿정보
    const templates = confStore.crtsyReserveMenu[userInfo.dealer_cd]; //템플릿정보
    // const svcPrvAgr = confStore.crtsyReserveMenu[userInfo.dealer_cd];
    
    const setLoadingInfo = useSetRecoilState(loadingState); //loading
    
    useEffect(() => {
        if(templates.length === 0) {
            setLoadingInfo({isOpen:true});
        }
    }, []);

    const navigateContract = async (template) => {
        navigate(template.route);
    }

    const Item = styled(Paper)(() => ({
        textAlign: 'center',
        maxHeight: 300,
        maxWidth: 300,
        height: '45dvw',
        width: '45dvw',
        fontWeight: 'bold',
        borderRadius: 15,
        margin: '0 auto',
        cursor: 'pointer',
        overflow: 'hidden',
      }));
     
    const ContractIcon = ( {id} ) => {
        switch(id) {
            case '0':
                return <FaceToFaceContract width={'18dvw'} height={'18dvw'} style={{marginTop: 30, maxWidth: 120, maxHeight: 120}} />
            case '1':
                return <NonFaceToFaceContract width={'18dvw'} height={'18dvw'} style={{marginTop: 30, maxWidth: 120, maxHeight: 120}} />
            default :
                return <FaceToFaceContract width={'18dvw'} height={'18dvw'} style={{marginTop: 30, maxWidth: 120, maxHeight: 120}} />;
        }
    }

    return (
        (templates.length !== 0) &&
        <>
            <Box 
                sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    flexDirection: 'column', 
                    flex: '1'
                    }}
            >
                <Box
                    sx={{
                        borderRadius: 2,
                        gap: viewPort.xs ? 2 : 5,
                        display: 'grid',
                        maxWidth: 1000,
                        margin: '0 auto',
                        gridTemplateColumns: { xs: '1fr', sm: templates.length === 1 ? '1fr' : '1fr  1fr'},
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    {templates.map((template, index) => (
                        <Item 
                            key={index} 
                            elevation={10} 
                            sx={{ 
                                    transition: 'box-shadow 0.3s ease',
                                    '&:hover': { backgroundColor: '#4c88ea', color: 'white', fill: 'white' },
                                    '&:active': { boxShadow: '0px 2px 2px rgba(0,0,0,0.2)', position: 'relative', top: '1px'}
                                }}
                            onClick={() => navigateContract(template)}
                        >
                            <ContractIcon id={template.id}/>
                            <Typography 
                                sx={{
                                    marginTop: '20px',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem'
                                }}>
                                    {template.title}
                            </Typography>
                            <Typography padding={2} sx={{wordBreak: "keep-all", fontSize: '0.8rem' }}> 
                                {viewPort.xs !== true && template.description} 
                            </Typography>
                        </Item>
                    ))}
                </Box>
            </Box>
        </>
    )
}

export default CrtsySelectContract;