import axios from 'axios';
import * as utils from '../../../commons/utils';
import dayjs from 'dayjs';

/** 발급한 SignOK Token 가져오기 */
function getToken(){
    let token = utils.getCookie("token");

    if(token) return 'Bearer ' + token;
            
    let loginPage = "kccauto"; // 차후 딜러 추가시 변경
            
    utils.openPopup({
        url: `/${loginPage}?type=simple`,
        target: '_blank',
        width: '700',
        height: '700',
        align: 'center'
    });
}

/** SignOK Token 발급 */
export async function getSignOkToken(apiId, apiSecretKey) {
    
    return new Promise(function(resolve, reject){
        const authString = apiId + ":" + apiSecretKey;
        const authEncode = window.btoa(authString); //authString.toString('base64');
        
        axios({
            method : "post",
            url : "/oauth/token",
            headers : {"Content-Type" : "application/x-www-form-urlencoded",
                       "Authorization" : "Basic "+authEncode},
            data : {"grant_type" : "password",
                      "username" : "api",
                      "password" : "nopassword"},
            
        }).then((res)=>{
            utils.setCookie('token', res.data.access_token, res.data.expires_in / 3600 / 24);
            resolve(res.data.access_token);
            
        }).catch((err)=>{
            reject("[전자계약서] Token 오류발생, 관리자에게 문의하세요");
        });
    });

}

export function makeEwSigner(requestData) {
    const today = dayjs(new Date());
    const signerId = requestData["signerId"];
    const ewDiv = requestData["ewDiv"]["val4"]; //EW상품 신차/보유차 구분
    
    const signer = [
        {
            "id": requestData["signerId"][0],
            "name": requestData["ewData"]["ew_client_nm"],
            "contact": utils.onlyNumber(requestData["ewData"]["ew_client_phone"]),
            "items":[
                {
                    "key": requestData["ewData"]["ew_model_cd"]??"EWC00010",
                    "checked":"true"
                },
                {
                    "key": "car_enroll_dt_year",
                    "value": requestData["ewData"]["car_enroll_dt"].format("YYYY")??""
                },
                {
                    "key": "car_enroll_dt_month",
                    "value": requestData["ewData"]["car_enroll_dt"].format("M")??""
                },
                {
                    "key": "iso_vin",
                    "value": requestData["ewData"]["iso_vin"]??""
                },
                {
                    "key": "car_enroll_dt_day",
                    "value": requestData["ewData"]["car_enroll_dt"].format("D")??""
                },
                {
                    "key": "signup_mileage",
                    "value": requestData["ewData"]["signup_mileage"]??""
                },
                {
                    "key": "product_price",
                    "value": requestData["ewData"]["sell_price"]??""
                },
                {
                    "key": "car_no",
                    "value": requestData["ewData"]["car_no"]??""
                },
                {
                    "key": "car_model_no",
                    "value": requestData["ewData"]["car_model_no"]??""
                },
                {
                    "key": "car_enroll_dt",
                    "value": requestData["ewData"]["car_enroll_dt"].format("YYYY-MM-DD")??""
                },
                {
                    "key": "ew_start_dt",
                    "value": requestData["ewData"]["ew_start_dt"].format("YYYY-MM-DD")??""
                },
                {
                    "key": "ew_end_dt",
                    "value": requestData["ewData"]["ew_end_dt"].format("YYYY-MM-DD")??""
                },
                {
                    "key": "ew_product_sales_dt",
                    "value": requestData["ewData"]["ew_product_sales_dt"].format("YYYY-MM-DD")??""
                },
                {
                    "key": "ew_client_nm",
                    "value": requestData["ewData"]["ew_client_nm"]??""
                },
                {
                    "key": "model_nm",
                    "value": requestData["ewData"]["model_nm"]??""
                },
                {
                    "key": "class_nm",
                    "value": requestData["ewData"]["class_nm"]??""
                },
                {
                    "key": "showroom_nm",
                    "value": requestData["ewData"]["showroom_nm"]??""
                },
                {
                    "key": "sales_emp_nm",
                    "value": requestData["ewData"]["sales_emp_nm"]??""
                },
                {
                    "key": "today_year",
                    "value": today.format("YYYY")??""
                },
                {
                    "key": "today_month",
                    "value": today.format("MM")??""
                },
                {
                    "key": "today_day",
                    "value": today.format("DD")??""
                },
                {
                    "key": "today_year_short",
                    "value": today.format("YY")??""
                },
                {
                    "key": "today",
                    "value" : today.format("YYYY-MM-DD")??""
                },
                {
                    "key": "sa_emp_nm",
                    "value" : requestData["ewData"]["sa_emp_nm"]??""
                },
                {
                    "key": "sales_emp_nm_scpe",
                    "value" : `${requestData["ewData"]["sc_emp_nm"]??""} ${requestData["ewData"]["pe_emp_nm"]??""}`
                },
                {
                    "key" : requestData["ewData"]["warranty_period_cd"]??"EWP00010",
                    "checked":"true"
                },
                {
                    "key" : ewDiv === "HAVE" ? "EW_DIV_HAVE" : "EW_DIV_NEW",
                    "checked" : "true"
                }
            ]
        }
    ];

    if(ewDiv === "HAVE") {
        signer[0]["items"] = [
            ...signer[0]["items"], 
            //[사전점검항목] '실행함' 체크
            {
                "key" : "preCheckListActive",
                "checked" : "true"
            }
        ];
    }

    //서명자 2명인 경우 추가
    if(signerId.length === 2) {
        signer.push({
            "id": signerId[1],
            "name": signer[0]["name"],
            "contact": signer[0]["contact"]
        });

        signer[0].name =  requestData["empNm"];
        signer[0].contact =  utils.onlyNumber(requestData["empPhoneNo"]);
    }

    return signer;
}

/** 전자계약서 문서 생성 (템플릿으로 서명요청) */
export async function createSignOkTemplate(requestData, signer, items) {
    const token = await getToken();

    return new Promise(function(resolve, reject){
        const data = {
                "template_id": requestData["templateId"],
                "title": requestData["templateTitle"],
                "auto_send" : false,
                "target_origin": process.env.REACT_APP_SIGNOK_TARGET_ORIGIN,
                "signer": signer
        }; 


        if(items) data.items = items;

        axios({
            method : "post",
            url : "/api/v2/document/template/create",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : token
            },
            data : data
            
        }).then((res)=>{
            if(res.data.code === "200"){
                resolve(res.data);
            }else{
                reject("[전자계약서] "+res.data.message+" 관리자에게 문의하세요");    
            }
        }).catch((res)=>{
            reject("[전자계약서] 템플릿 생성 오류, 관리자에게 문의하세요");
        });

    });
}

/** 전자계약서 템플릿 목록 조회 */
export async function getSignOkTemplateList() {
    const token = await getToken();

     return new Promise(function(resolve, reject){
        axios({
            method : "get",
            url : "/api/v2/template",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : token
            }
        }).then((res)=>{
            resolve(process.env.REACT_APP_SIGNOK_EW_TEMPLATE_ID);
        }).catch((err)=>{
            reject("[전자계약서] 템플릿 조회 실패, 관리자에게 문의하세요");
        });
     
    });   
}

/** 전자계약서 템플릿 조회 */
export async function getSignOkTemplate(templateId) {
    const token = await getToken();

    return new Promise(function(resolve, reject){
        axios({
            method : "get",
            url : "/api/v2/template/" + templateId,
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : token
            }
        }).then((res)=>{
            const data = {
                signerId : res['data']['result']['signer'].map((signer) => signer['id']),
                templateTitle : res['data']['result']['title']
            }
            resolve(data);
        }).catch((err)=>{
            reject("[전자계약서] 템플릿 조회 실패, 관리자에게 문의하세요");
        });
     
    });   
}

/** 전자계약서 문서 조회 */
export async function getSignOkDocument(documentId) {
    const token = await getToken();

    return new Promise(function(resolve, reject){
        axios({
            method : "get",
            url : "/api/v2/document/" + documentId,
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : token
            }
        }).then((res)=>{
            if(res.data.code === '7003') reject("[전자계약서] 문서 조회 실패, 관리자에게 문의하세요");

            resolve(res.data);
        }).catch((err)=>{
            reject("[전자계약서] 문서 조회 실패, 관리자에게 문의하세요");
        });
     
    });   
}

/** 전자계약서 문서 다운로드 */
export async function downloadSignOkDocument(documentId) {
    const token = await getToken();

    return new Promise(function(resolve, reject){
        axios({
            method : "get",
            url : "/api/v2/document/" + documentId + "/download",
            responseType: 'blob',
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : token
            }
        }).then((res)=>{
            const decodedFilename = decodeURIComponent(res.headers["content-disposition"]).split("filename=")[1].replace(/"/g, "").replace(";","");
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', decodedFilename); 
            document.body.appendChild(link);
            
            link.click();
            link.remove();
        }).catch((err)=>{
            console.log(err);
            reject("[전자계약서] 문서 다운로드 실패, 관리자에게 문의하세요");
        });
     
    });   
}