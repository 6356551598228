import { useState } from 'react';
import DaumPostCode from "react-daum-postcode";
import { Dialog, DialogTitle, DialogContent }from '@mui/material';


function CPDaumPostCodeModal(props) {



    const completeHandler = async (data) => {
        const { address, zonecode } = data;
        props.inputAddrInfo({ address, zonecode } );
        props.onAddrSearchModal(false);  
    }
    
    const closeHandler = (state) => {
    }

    return (
        <>
            <Dialog 
                open={true} 
                onClose={() => props.onAddrSearchModal(false)}
                fullWidth={true}
                maxWidth="lg"
                sx={{
                    '& .MuiDialog-paper': {
                        overflow: 'visible'  // 이 부분 추가
                    }
                }}>
                <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>주소 검색 </DialogTitle>
                <DialogContent 
                    dividers
                    sx={{
                        position: 'relative',
                        zIndex: 1000,
                        padding: 0
                    }}
                    >
                    <DaumPostCode
                        autoClose = {false}
                        onComplete={completeHandler}
                        onClose={closeHandler}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
};

export default CPDaumPostCodeModal;