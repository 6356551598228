import React from 'react'; 

import { Routes, Route, Navigate } from 'react-router-dom'

import Login from './pages/Login.js'
import Layout from './pages/Layout.js'
import DealerList from './pages/DealerList'
import Home from './pages/Home.js'
import EwForm from './pages/contract/ew/EwForm.js'
import EwElecForm from './pages/contract/ew/EwElecForm.js'
import SelectContract from './pages/contract/SelectContract.js';
import EwList from './pages/contract/ew/EwList.js';
import CarList from './pages/contract/car/CarList.js';
import CarForm from './pages/contract/car/CarForm.js';
import CarSignStateForm from './pages/contract/car/CarSignStateForm.js';
import CrtsyForm from './pages/contract/crtsy/CrtsyForm.js';
import CrtsyList from './pages/contract/crtsy/CrtsyList.js';
import CrtsySelectContract from './pages/contract/crtsy/CrtsySelectContract.js';
import SvcPrvAgrForm from './pages/svcPrvAgr/SvcPrvAgrForm.js';
import SvcPrvAgrInfo from './pages/svcPrvAgr/SvcPrvAgrInfo.js';
// import SvcPrvAgrList from './pages/svcPrvAgr/SvcPrvAgrList.js';
// import SvcPrvAgrMsg from './pages/svcPrvAgr/SvcPrvAgrMsg.js';
import SvcPrvAgrCheck from './pages/svcPrvAgr/SvcPrvAgrCheck.js';
import { PrivateRoute } from './routes/PrivateRoute.js';
import ErrorPage from 'pages/ErrorPage.js';
import CrtsyClientStep from 'pages/contract/crtsy/client/CrtsyClientStep.js';


function RouterList() {

  return (
    <div> 
      <Routes>
        <Route path="/crtsy/client/:seq" element={<CrtsyClientStep />}/>

        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route exact path="/home" element={<Home />}/>
            <Route exact path="/contracts/select" element={<SelectContract />}/>
            <Route exact path="/ewForm" element={<EwForm />}/>
            <Route exact path="/ewList" element={<EwList />}/>
            <Route exact path="/contracts/car" element={<CarList />}/>
            <Route exact path="/contracts/car/write" element={<CarForm />}/>
            <Route exact path="/contracts/car/CarSignStateForm" element={<CarSignStateForm />}/>
            <Route exact path="/crtsy/write/reserve" element={<CrtsyForm />}/>
            <Route exact path="/crtsy/write/return" element={<CrtsyForm />}/>
            <Route exact path="/crtsy/select" element={<CrtsySelectContract />}/>
            <Route exact path="/crtsy/list" element={<CrtsyList />}/>
            <Route exact path="/svcPrvAgr/form" element={<SvcPrvAgrForm />}/>
            <Route exact path="/svcPrvAgr/info" element={<SvcPrvAgrInfo />}/>
            {/* <Route exact path="/svcPrvAgr/list" element={<SvcPrvAgrList />}/> */}
          </Route>
          <Route exact path="/ewElecForm" element={<EwElecForm />}/>
          {/* <Route exact path="/svcPrvAgr/msg" element={<SvcPrvAgrMsg />}/> */}
          <Route exact path="/svcPrvAgr/check" element={<SvcPrvAgrCheck />}/>
        </Route>

        <Route exact path="/" element={<DealerList />}/>
        <Route exact path="/:domain" element={<Login />}/>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </div>
  );
}

export default RouterList;
