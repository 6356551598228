import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import * as svcApi from 'services/apis/crm4u/svcApi';
import * as utils from 'commons/utils'
import { useSetRecoilState } from "recoil";
import { alertState, loadingState } from "stores/ComponentState";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function CrtsyClientVerification({ nextStep }) {

    const [viewHeight, setViewHeight] = useState(window.innerHeight);

    const navigate = useNavigate();
    const params = useParams();
    const seq = params.seq;
    const setAlertInfo = useSetRecoilState(alertState);
    const setLoadingInfo = useSetRecoilState(loadingState);
    const [view, setView] = useState(false);
    const phoneRef = useRef();

    const updateHeight = () => {
        setViewHeight(window.innerHeight);
    };

    useEffect(() => {
        setLoadingInfo({isOpen:true});
        
        svcApi.svcApiCheckCourtesyEform({
            target: 'seq',
            courtesy_eform_seq: seq
        }).then((res) => {
            if(res?.eformInfo) {
                nextStep(res);
            } else {
                setView(true);
            }
        }).catch(() => {
            navigate("/error");
        }).finally(() => {
            setLoadingInfo({isOpen:false});
        });


        window.addEventListener('resize', updateHeight);
        updateHeight();

        return () => window.removeEventListener('resize', updateHeight);
    }, [])

    const validate = async () => {
        try {
            const phone = phoneRef.current.value;
            if(!phone) {
                alert('휴대전화 번호를 입력해주세요.');
                return;
            }
            if(!utils.regExp('phone').test(phone)) {
                alert('휴대전화 번호를 정확히 입력해주세요.');
                return;
            }

            setLoadingInfo({isOpen:true});

            const crtsyContractInfo = await svcApi.svcApiCheckCourtesyEform({
                target: 'phone', 
                courtesy_eform_seq: seq,
                client_phone: phoneRef.current.value
            });

            nextStep(crtsyContractInfo);
        } catch(err) {
            setAlertInfo({message :err, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    }

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            {view &&
            <Box
                position={'relative'}
                height={`${viewHeight}px`}
                padding={'0px 20px'}
                bgcolor={'#f9f9f9'}
            >
                <Box 
                    height={'100%'}
                    position={'relative'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    gap={1}
                    paddingBottom={25}
                >
                    <Box 
                        textAlign={'center'} 
                        mb={1}
                    >
                        <img src='/images/crtsy/lock.png' style={{width: '20vw', maxWidth: '150px'}} alt='lock'/>
                    </Box>
                    <Typography
                        variant={'h2'}
                        fontWeight={'bold'}
                        marginBottom={'10%'}
                        textAlign={'center'}
                    >
                        커티시카 계약 정보 확인 전 본인 확인
                    </Typography>
                    <Typography
                        variant={'body1'}   
                        textAlign={'center'}
                        marginBottom={1}
                    >
                        본인 확인을 위해 휴대폰 번호를 입력해 주세요.
                    </Typography>   
                    <TextField
                        inputRef={phoneRef}
                        inputProps={{
                            maxLength: 11
                        }}
                        sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                            '& .MuiInputBase-root':{
                                height:'50px'
                            }
                        }}
                        onChange={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                validate();
                            }
                        }}
                    />
                    <Typography variant='caption'>
                        고객님의 휴대폰 번호를 입력해 주세요 ('-' 없이 입력)
                    </Typography>
                    <Button 
                        sx={{
                            position: 'absolute',
                            bottom: 5,
                        }} 
                        fullWidth
                        variant="contained" 
                        size="large" 
                        type="submit"
                        onClick={validate}
                    >
                        확인
                    </Button>
                </Box>
            </Box>
            }
            </>
    )
}

export default CrtsyClientVerification;